<template>
  <section class="section">
    <div class="container is-fluid">
      <div class="columns">
        <div class="column">
          <ag-grid-vue
            :id="gridId"
            style="width: 100%; height: 700px;"
            class="ag-theme-alpine"
            :grid-options="gridOptions"
            :get-child-count="getChildCount"
            :pagination="true"
            :pagination-auto-page-size="true"
            :animate-rows="true"
            :side-bar="['columns']"
            :multi-sort-key="'ctrl'"
            :modules="modules"
            :get-context-menu-items="getContextMenuItems"
            @grid-ready="gridReady"
          />
        </div>
      </div>
    </div>
    <b-modal :active.sync="selectedShipmentModal.isActive" scroll="keep" has-modal-card @close="selectedShipmentModal.materials = []">
      <div class="modal-card" style="min-height: 630px">
        <header class="modal-card-head">
          <p class="modal-card-title">
            Materials
          </p>
        </header>
        <section class="modal-card-body">
          <b-field grouped>
            <b-field>
              <b-input v-model="searchMaterial" placeholder="Search..." type="search" icon="search"/>
            </b-field>
            <b-field>
              <b-button type="is-info" icon-left="file-csv" @click="downloadSelectShipmentMaterials">
                Export
              </b-button>
            </b-field>
          </b-field>
          <b-table
            :per-page="15"
            :data="filteredMaterials"
            :loading="selectedShipmentModal.isLoading"
            narrowed
            striped
            paginated
          >
            <template slot="header" slot-scope="props">
              <span>{{ props.column.label }}</span>
            </template>
            <template slot-scope="props">
              <b-table-column field="barcode" label="Barcode" sortable>
                {{ props.row.barcode }}
              </b-table-column>
              <b-table-column field="material" label="Material" sortable>
                {{ props.row.material }}
              </b-table-column>
              <b-table-column field="scannedAt" label="Scanned at" sortable>
                {{ new Date(props.row.scannedAt).toLocaleString() }}
              </b-table-column>
            </template>
            <template slot="bottom-left">
              <b>Total</b>: {{ filteredMaterials.length }}
            </template>
            <template slot="empty">
              <nothing-here/>
            </template>
          </b-table>
        </section>
        <footer class="modal-card-foot"/>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { saveAs } from 'file-saver'
import { AllModules } from '@ag-grid-enterprise/all-modules'
import { mapState } from 'vuex'
import GridManager from '@/components/common/grid-manager'
import sortAndFilterMixins from '@/mixins/agGridSortAndFilter'
import gql from 'graphql-tag'
import Resource from '@/services/v2/resource'
import Shipment from '@/services/v2/shipment'

export default {
  components: {
    GridManager
  },
  mixins: [sortAndFilterMixins],

  data: function () {
    return {
      gridId: 'customer-deliveries',
      gridProfiles: [],
      shipments: [],
      totalCount: 0,
      modules: AllModules,
      selectedShipmentModal: {
        isLoading: false,
        isActive: false,
        materials: []
      },
      selectedShipment: null,
      searchMaterial: '',
      gridOptions: {
        rowModelType: 'serverSide',
        enableRangeSelection: true,
        defaultColDef: {
          floatingFilter: true,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: false,
          resizable: true
        },
        columnDefs: [
          { headerName: 'Status',
            field: 'status',
            width: 120,
            sortable: true,

            cellRenderer: (data) => {
              if (data.value === 'C') { return 'Scheduled' } else if (data.value === 'T') { return 'Shipped' } else return null
            },
            cellClassRules: {
              'rag-green': function (params) { return params.value === 'T' },
              'rag-yellow': function (params) { return params.value === 'C' }
            }
          },
          { headerName: 'Shipping date',
            field: 'shippingDate',
            cellRenderer: (data) => {
              return data.value && this.$dayjs(data.value, 'YYYYMMDD').toDate().toLocaleDateString()
            },
            filter: 'agDateColumnFilter',
            filterParams: {
              comparator: (filterLocalDateAtMidnight, cellValue) => {
                if (this.$dayjs(cellValue).isSame(filterLocalDateAtMidnight)) {
                  return 0
                } else if (this.$dayjs(cellValue).isBefore(filterLocalDateAtMidnight)) {
                  return -1
                } else if (this.$dayjs(cellValue).isAfter(filterLocalDateAtMidnight)) {
                  return 1
                }
              }
            },
            sort: 'desc',
            width: 160,
            sortable: true
          },
          { headerName: 'Shipment number',
            field: 'shipmentNumber',
            filter: 'agTextColumnFilter',
            width: 150,
            sortable: true,
            filterParams: { suppressAndOrCondition: true } },
          { headerName: 'Delivery number',
            field: 'deliveryId',
            filter: 'agTextColumnFilter',
            width: 150,
            sortable: true,
            filterParams: { suppressAndOrCondition: true } },
          { headerName: 'Order number',
            field: 'orderNumber',
            filter: 'agTextColumnFilter',
            width: 150,
            sortable: true,
            filterParams: { suppressAndOrCondition: true } },
          { headerName: 'Order reference',
            field: 'orderReference',
            filter: 'agTextColumnFilter',
            width: 150,
            sortable: true,
            filterParams: { suppressAndOrCondition: true } },

          { headerName: 'Material',
            field: 'material',
            filter: 'agTextColumnFilter',
            width: 130,
            sortable: true,
            filterParams: { suppressAndOrCondition: true } },
          { headerName: 'Quantity',
            field: 'quantity',
            filter: 'agNumberColumnFilter',
            width: 130,
            sortable: true
          },
          { headerName: 'Forwarder',
            field: 'forwarderName',
            filter: 'agTextColumnFilter',
            width: 180,
            sortable: true,
            filterParams: { suppressAndOrCondition: true } },
          { headerName: 'Information',
            field: 'information',
            filter: 'agTextColumnFilter',
            width: 150,
            sortable: true,
            filterParams: { suppressAndOrCondition: true } }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      selectedCustomer: state => state.selectedCustomer
    }),
    filteredMaterials: function () {
      return this.selectedShipmentModal.materials.filter((row) => {
        return Object.keys(row).some((key) => {
          return String(row[key]).toLowerCase().indexOf(this.searchMaterial.toLowerCase()) > -1
        })
      })
    }
  },
  watch: {
    selectedCustomer (newValue, oldValue) {
      this.gridOptions.api.purgeServerSideCache([])
    }
  },
  methods: {
    createServerSideDatasource: function (params) {
      return {
        getRows: async (params) => {
          const groupSummary = this.getGroups(params.request)
          let columns = groupSummary.filter(e => e.isHere === true).map(e => e.column.toString())
          if (!columns.length) {
            columns = [...this.gridOptions.columnDefs.map(colDef => colDef.field), 'requirement', 'deliveryId']
          }

          const builder = this.$gql.query({
            operation: 'shipmentsByCustomerId',
            fields: ['totalCount', { edges: [{ node: [{ aggregate: [{ count: ['vakey'] }, ...this.getAggregate(params.request)] }, ...columns] }] }],
            variables: {
              skip: { type: 'Int', value: params.request.startRow },
              limit: { type: 'Int', value: params.request.endRow },
              sort: { type: '[sortInputShipment]', value: this.getSorts(params.request) },
              filter: { type: 'filterInputShipment', value: this.getFilters(params.request) },
              group: { type: 'Boolean', value: !!groupSummary.find(e => e.isHere === true) },
              customerId: { type: 'ID!', value: this.$store.state.selectedCustomer }
            }
          })

          try {
            const result = await this.$apollo.query({
              fetchPolicy: 'no-cache',
              query: gql`${builder.query}`,
              variables: builder.variables })

            const shipmentsResult = result.data.shipmentsByCustomerId
            this.totalCount = shipmentsResult.totalCount
            this.shipments = shipmentsResult.edges.map(e => e.node)
            params.successCallback(this.shipments, this.totalCount)
          } catch (e) {
            params.failCallback()
          }
        }
      }
    },
    gridReady: function (params) {
      params.api.setServerSideDatasource(this.createServerSideDatasource(params))
    },
    getChildCount: function (data) {
      if (data) {
        return data.aggregate.count.vakey
      }
    },
    getContextMenuItems (params) {
      this.selectedShipment = params.node.data
      let customMenu = []
      const genericMenu = [
        'copy',
        'copyWithHeaders',
        'separator',
        'export'
      ]
      if (this.selectedShipment.status === 'T') {
        customMenu.push(
          {
            name: 'Delivery note',
            action: () => {
              this.downloadDeliverynote()
            },
            icon: '<i class="far fa-fw fa-file-pdf"></i>'
          },
          {
            name: 'Attached materials',
            action: () => {
              this.openMaterialModal()
            },
            icon: '<i class="fas fa-fw fa-barcode" ></i>'
          })
      }

      const result = [...customMenu, ...genericMenu]
      return result
    },

    openMaterialModal: async function () {
      this.selectedShipmentModal.isActive = true
      this.selectedShipmentModal.isLoading = true
      this.selectedShipmentModal.materials = await Shipment.getMovements(this.selectedShipment.requirement + '/' + this.selectedShipment.deliveryId)
      this.selectedShipmentModal.isLoading = false
    },
    downloadDeliverynote: async function () {
      const data = await Resource.downloadPdf(this.selectedShipment.deliveryId)
      const fileURL = URL.createObjectURL(data)
      window.open(fileURL)
    },
    downloadSelectShipmentMaterials: async function () {
      const result = this.filteredMaterials.map(e => Object.values(e).join(';')).join('\n')
      const csvBlob = new Blob([result], { type: 'text/csv' })
      saveAs(csvBlob, this.selectedShipment.requirement + '-' + this.selectedShipment.deliveryNumber + '-export.csv'
      )
    }
  }
}
</script>
