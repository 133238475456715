<template>
  <section>
    <customer-map/>
  </section>
</template>

<script>
import customerMap from './customer-map'

export default {
  components: {
    customerMap
  }
}
</script>

<style lang="scss">
#pac-input {
  background-color: #fff;
  font-size: 15px;
  font-weight: 300;
  margin-right: 12px;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  width: 268px;
}

#pac-input:focus {
  border-color: #4d90fe;
}

#distance-block {
  width: 300px;
  margin-right: 12px;
  margin-top: 5px;
}
.controls {
  margin-top: 10px;
  border: 1px solid transparent;
  border-radius: 2px 0 0 2px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  height: 32px;
  outline: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}
#gps-map {
  // font: 400 11px Roboto, Arial, sans-serif;
  // text-decoration: none;
  img {
    max-width: none;
  }
  &.is-relative {
    position: relative;
  }
    &#pac-input {
      position: absolute;
      top: 370px;
      left: 10px;
      z-index: 100000;
    }
    &#distance-block {
      position: absolute;
      top: 70px;
      right: 0px;
      z-index: 100000;
    }
    &#item-info {
      font-size: 12px;
      position: absolute;
      bottom: 30px;
      left: 0px;
      z-index: 100000;
    }
    #legend {
      // position: absolute;
      // font-size: 13px;
      // bottom: 30px;
      // right: 0px;
      z-index: 100000;

      img {
        max-height: 30px;
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
        margin-right: 0.5rem;
      }
    }

}
</style>
