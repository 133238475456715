<template>
  <div class="d-flex-align-center d-flex-column custom-box">
    <span class="custom-subtitle">
      {{ !secondCard ? $t('consignee.pickup_management.last_declaration') : askingDeclaration ? $t('consignee.pickup_management.waiting_for_inv') : $t('consignee.pickup_management.question_want_to_declare') }}
    </span>
    <div class="d-flex-row d-flex-justify-center d-flex-space-around">
      <div v-if="!mail.spontaneous && !secondCard">
        <b-taglist attached>
          <b-tag size="is-medium" type="is-dark">
            {{ $t('consignee.pickup_management.received') }}
          </b-tag>
          <b-tag size="is-medium" type="is-info">
            {{ $dayjs(mail.firstSendingDate).local().format('DD/MM/YYYY HH:mm') }}
          </b-tag>
        </b-taglist>
      </div>
      <div v-if="!secondCard">
        <b-taglist attached>
          <b-tag size="is-medium" type="is-dark">
            {{ $t('consignee.pickup_management.declared') }}
          </b-tag>
          <b-tag size="is-medium" :type="mail.declarationId ? 'is-info' : 'is-danger'">
            {{ $dayjs(mail.declarationDate).local().format('DD/MM/YYYY HH:mm') }}
          </b-tag>
        </b-taglist>
      </div>
      <div v-else>
        <div v-if="askingDeclaration" class="has-text-centered">
          <b-icon icon="exclamation-triangle" type="is-warning" size="is-large"/>
        </div>

        <b-button type="is-outlined is-info" class="is-medium" @click="spontaneousDeclaration">
          {{ $t('consignee.pickup_management.make_declaration') }}
        </b-button>
      </div>
    </div>
    <b-button
      v-if="!secondCard"
      icon-left="barcode"
      class="is-small button"
      type="is-primary is-outlined"
      @click="$emit('see-declaration', mail)"
    >
      {{ $t('consignee.pickup_management.see_declaration') }}
    </b-button>
  </div>
</template>

<script>

export default {
  props: {
    mail: {
      type: Object,
      required: true
    },
    secondCard: {
      type: Boolean,
      default: false
    },
    askingDeclaration: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      mails: null
    }
  },
  methods: {
    spontaneousDeclaration () {
      this.$emit('spontaneousDeclaration')
    }
  }
}
</script>

<style scoped>

.custom-box {
  background-color: rgba(255, 255, 255, 0.90);
  border-radius: 6px;
  box-shadow: 2px 4px 2px rgba(0, 0, 0, 0.3);
  padding: 10px;
  color: black;
  margin: 1rem;
  max-width: 600px;
}

.custom-subtitle{
  color: black;
  font-size: 1.25rem;
  padding-bottom: 1rem;
  line-height: 1.25;
}

.custom-box > div {
    width: 100%;
}

.button {
    margin: 1rem;
}

</style>
