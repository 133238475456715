<template>
  <div :style="{height: 100 + 'vh'}">
    <header class="has-text-centered">
      <img src="@/assets/gps_logo.png" alt="GPS logo" style="height: 20vh;">
    </header>
    <b-loading v-model="isLoading" :is-full-page="false"/>
    <section class="container max-width">
      <div v-if="showError">
        <p class="error-message">
          😥 This token is invalid, please contact customer service
        </p>
      </div>

      <div v-if="showDeclarationUI" class="d-flex-column d-flex-align-center">
        <div class="has-text-centered mb-5 welcome">
          <p>Welcome</p>
          <p>{{ curEnduser.id }} - {{ curEnduser.name }}</p>
        </div>
        <b-field>
          <b-upload
            v-model="uploadedFile"
            accept=".txt, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            drag-drop
          >
            <section class="section">
              <div class="content has-text-centered">
                <p>
                  <b-icon icon="file" size="is-large"/>
                </p>
                <b-field class="file">
                  <b-upload v-model="uploadedFile" expanded>
                    <a class="button is-fullwidth">
                      <span>{{ uploadedFile.name || "Upload" }}</span>
                    </a>
                  </b-upload>
                </b-field>
                <p>
                  Drop your file here
                </p>
              </div>
            </section>
          </b-upload>
        </b-field>

        <b-button type="is-primary" :style="{marginBottom: 2 + 'rem', marginTop: 1 + 'rem'}" :disabled="isDisabled" @click="upload">
          Submit
        </b-button>

        <b-button type="is-info" icon-left="download" @click="getTemplate">
          Download template
        </b-button>
      </div>

      <div v-if="showFeedback" class="has-text-centered welcome">
        <p>Upload successfully</p>
        <p>Thank you!</p>
      </div>
    </section>
  </div>
</template>

<script>
import DistributorDeclaration from '@/services/v2/distributorDeclaration'
import { saveAs } from 'file-saver'

export default {
  data () {
    return {
      isLoading: false,
      curReminder: '',
      curEnduser: '',
      token: this.$route.query.token,
      showError: false,
      showDeclarationUI: false,
      showFeedback: false,
      uploadedFile: {},
      isDisabled: true
    }
  },
  watch: {
    uploadedFile () {
      this.isDisabled = false
    }
  },
  async mounted () {
    this.isLoading = true
    await this.loadData()
    this.isLoading = false
  },
  methods: {
    async loadData () {
      const reminder = await DistributorDeclaration.getReminder(this.token)
      if (!reminder || this.$dayjs(reminder.tokenExpiredAt).isSameOrBefore(this.$dayjs()) || reminder.declarationId) {
        this.showError = true
      } else {
        this.curReminder = reminder
        const enduser = await DistributorDeclaration.getDistributor(this.curReminder.distributorCode)
        this.curEnduser = enduser[0]
        this.showDeclarationUI = true
      }
    },
    async getTemplate () {
      try {
        const result = await DistributorDeclaration.getTemplate()
        saveAs(result, 'template_distributor.csv')
      } catch (e) {
        this.$buefy.toast.open({ message: e.message || e, type: 'is-danger', duration: 4000 })
      }
    },
    async upload () {
      this.isLoading = true
      try {
        await DistributorDeclaration.upload(this.curEnduser.id, this.curReminder.reminderId, this.uploadedFile)
        this.showDeclarationUI = false
        this.showFeedback = true
      } catch (error) {
        this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
      }
      this.isLoading = false
    }
  }
}
</script>

<style scoped>
.error-message {
  font-size: 25px;
  margin-top: 10rem;
  text-align: center;
}

.max-width {
  margin-top: 3rem;
  width: 60vw
}

.welcome {
 font-size: 20px;
 font-weight: 700;
}
</style>
