import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/enduserDeclaration'

const enduserDeclaration = axios.create({
  baseURL
})

interceptorsSetup(enduserDeclaration)

export default class EnduserDeclaration {
  static async get (token) {
    const { data: result } = await enduserDeclaration.get(`/${token}/mail/`)
    return result
  }

  static async getDeclaration (token) {
    const { data: result } = await enduserDeclaration.get(`/${token}/declaration/`)
    return result
  }

  static async getPdfReport (token) {
    const FileSaver = require('file-saver')
    FileSaver.saveAs(baseURL + `/${token}/report/`, 'report.pdf')
  }

  static async getCrateDifferencePdf () {
    const { data: result } = await enduserDeclaration.get(`/downloadCratesNote`, {
      responseType: 'blob'
    })
    return result
  }

  static async getMaterials (token, enduserCode) {
    const { data: result } = await enduserDeclaration.get(`/${token}/materials/${enduserCode}`)
    return result
  }

  static async getQuantities (token, declarationId) {
    const { data: result } = await enduserDeclaration.get(`/${token}/quantities/${declarationId}`)
    return result
  }

  static async post (token, declaration, declarationQuantities) {
    const { data: result } = await enduserDeclaration.post(`/${token}/declaration`, { declaration, declarationQuantities })
    return result
  }

  static async getMatLibelles (token) {
    const result = await enduserDeclaration.get(`/${token}/materials/`)
    return result.data
  }

  static async askNewMail (token) {
    const { data: result } = await enduserDeclaration.get(`/${token}/askNewMail`)
    return result
  }
}
