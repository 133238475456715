import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/plants'

const plants = axios.create({
  baseURL
})

interceptorsSetup(plants)

export default class Plant {
  static async getAll () {
    const result = await plants.get('/')
    return result.data
  }
}
