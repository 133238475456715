import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL + '/downloads'

const downloads = axios.create({
  baseURL
})

interceptorsSetup(downloads)

export async function getCustomerFiles (fileId) {
  const { data: file } = await downloads('/' + fileId, {
    responseType: 'blob'
  })
  return file
}
