import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/customers'

const customers = axios.create({
  baseURL
})

interceptorsSetup(customers)

export default class Customer {
  static async getEndusers ({ customerId, additionalData, fromDate, toDate, limit, offset }) {
    const result = await customers.get(`/${customerId}/endusers`, {
      params: {
        additionalData, limit, offset, fromDate, toDate
      }
    })
    return result.data
  }

  static async getProducers (customerId) {
    const result = await customers.get(`/${customerId}/producers`)
    return result.data
  }

  static async getGlobalStock ({ customerId }) {
    const result = await customers.get(`/${customerId}/globalStock`)
    return result.data
  }
  static async getGlobalCollection ({ customerId, fromDate, toDate }) {
    const result = await customers.get(`/${customerId}/globalCollection`, {
      params: {
        fromDate,
        toDate
      }
    })
    return result.data
  }
}
