var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"hero is-fullheight"},[_c('div',{staticClass:"hero-body"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-one-third is-offset-one-third"},[_c('div',{staticClass:"card",attrs:{"id":"login-card"}},[_c('div',{staticClass:"card-content"},[_c('img',{staticClass:"text-center",attrs:{"src":require("@/assets/gps_logo.png"),"alt":"Gps logo","width":"400px"}}),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{attrs:{"id":"login"},on:{"submit":function($event){$event.preventDefault();return _vm.onLogin()}}},[_c('ValidationProvider',{attrs:{"slim":"","rules":"required","name":"Username"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"rounded":"","name":"username","placeholder":"Username","type":"text","icon":"user","autofocus":""},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"slim":"","rules":"required","name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"rounded":"","name":"password","password-reveal":"","placeholder":"Password","type":"password","icon":"lock"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]}}],null,true)}),_c('b-button',{attrs:{"type":"is-info","rounded":"","loading":_vm.isLoading,"disabled":invalid,"expanded":"","native-type":"submit"}},[_vm._v(" Login ")]),_c('div',[_c('router-link',{staticClass:"is-size-7",attrs:{"to":"forget-password"}},[_vm._v(" Forgot your password ? ")])],1)],1)]}}])})],1)])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }