<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns">
          <div class="column is-one-third is-offset-one-third">
            <div id="login-card" class="card">
              <div class="card-content">
                <img src="@/assets/gps_logo.png" alt="A remplir" width="400px" class="text-center">
                <ValidationObserver v-slot="{ invalid }">
                  <form id="login" @submit.prevent="updatePassword()">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      slim
                      rules="required"
                      vid="password"
                      name="Password"
                    >
                      <b-field :message="errors" :type="{ 'is-danger': errors[0], 'is-success': valid }">
                        <b-input
                          v-model="password"
                          rounded
                          type="password"
                          placeholder="Password"
                          icon="lock"
                          password-reveal
                        />
                      </b-field>
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ errors, valid }" slim rules="required|confirmed:password" name="Password confirmation">
                      <b-field :message="errors" :type="{ 'is-danger': errors[0], 'is-success': valid }">
                        <b-input
                          v-model="confirmPassword"

                          rounded
                          type="password"
                          placeholder="Confirm password"
                          icon="lock"
                          password-reveal
                        />
                      </b-field>
                    </ValidationProvider>
                    <b-button
                      type="is-info"
                      rounded
                      :loading="isLoading"
                      :disabled="invalid"
                      expanded
                      native-type="submit"
                    >
                      Submit
                    </b-button>
                  </form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      password: '',
      confirmPassword: '',
      token: '',
      errors: false,
      isLoading: false
    }
  },
  mounted: async function () {
    this.token = this.$route.query.token
    try {
      await this.$http.get(process.env.VUE_APP_API_URL + '/auth/check-token/' + this.token)
    } catch (e) {
      this.$router.push('/login')
    }
  },
  methods: {
    async updatePassword () {
      await this.$http.post(process.env.VUE_APP_API_URL + '/auth/reset-password', {
        token: this.token,
        password: this.password
      })

      this.$buefy.toast.open({
        message: 'Your password has been updated, you can now login !',
        type: 'is-primary'
      })
      this.$router.push('/login')
    }
  }
}
</script>

<style>
</style>
