<template>
  <div>
    <div v-if="selectedCustomer && mails && mails.length > 0" class="d-flex-align-center d-flex-column">
      <h1 class="title mt-10">
        {{ selectedCustomer.name }}
      </h1>
      <div class="hero is-primary bar">
        <div class="mt-10 d-flex-justify-center container">
          <custom-box v-if="lastDeclaration" :mail="lastDeclaration" @see-declaration="seeDeclaration"/>
          <custom-box
            v-if="lastDeclaration"
            :second-card="true"
            :mail="lastMail"
            :asking-declaration="lastDeclaration !== lastMail"
            @see-declaration="seeDeclaration"
            @spontaneousDeclaration="spontaneousDeclaration"
          />
        </div>
      </div>
      <div class="d-flex-align-center d-flex-column d2">
        <!-- <b-button type="is-outlined is-info" class="is-medium" @click="spontaneousDeclaration">
          {{ $t('consignee.pickup_management.make_declaration') }}
        </b-button> -->
      </div>
      <div style="position: relative;">
        <b-loading :active.sync="collectLoading" :is-full-page="false"/>
        <div v-if="nextCollect && nextCollect.length > 0" class="next-collect-card has-background-primary">
          <span class="subtitle card-title">
            {{ $t('consignee.pickup_management.next_collect') }}
          </span>
          <div class="d-flex-row d-flex-justify-center d-flex-space-around card-body">
            <span>
              <span>
                <b-icon icon="calendar" type="is-dark"/>
              </span>
              <span class="subtitle is-dark">
                {{ $t('global.date') }}
              </span>
              <br>
              <span>
                {{ nextCollect[0].loadingDate ? $dayjs(nextCollect[0].loadingDate).format('DD/MM/YYYY') : '' }}
              </span>
            </span>
            <br>
            <span>
              <span>
                <b-icon icon="box" type="is-dark"/>
              </span>
              <span class="subtitle is-dark">
                {{ $t('consignee.pickup_management.materials') }}
              </span>
              <div v-for="col of nextCollect" :key="col.id">
                {{ col.mat === 'GPS52' ? col.mat.slice(0, -1) : col.mat }} <b-icon icon="arrow-right"/> {{ col.qty }}
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="hero is-danger bar d-flex-align-center">
        <span class="title">
          {{ $t('consignee.pickup_management.no_data_available') }}
        </span>
      </div>
    </div>
    <div v-if="declaration">
      <declaration :selected-mail-declaration="declaration" @close="closeDeclaration"/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PickupManagement from '@/services/v2/pickupManagement'
import customBox from './custom-box.vue'
import declaration from './declaration.vue'

export default {
  components: {
    customBox,
    declaration
  },
  data () {
    return {
      loading: false,
      mails: null,
      declaration: null,
      nextCollect: null,
      collectLoading: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      selectedCustomer: state => state.selectedCustomer
    }),
    lastMail: function () {
      if (this.mails) {
        // const mails = this.mails.filter(m => !m.manualDeclaration)
        if (this.mails.length > 0) {
          return this.mails[this.mails.length - 1]
        }
      }
      return null
    },
    lastDeclaration: function () {
      if (this.mails) {
        const declared = this.mails.filter(m => m.declarationId)
        if (declared && declared.length > 0) {
          return declared[declared.length - 1]
        }
      }
      return null
    }
  },
  watch: {
    selectedCustomer (newValue, oldValue) {
      this.getData()
    }
  },
  mounted: async function () {
    this.getData()
  },
  methods: {
    getData: async function () {
      try {
        this.collectLoading = true
        this.mails = await PickupManagement.getByEnduser(this.selectedCustomer)
        this.mails = this.mails.sort((a, b) => {
          return this.$dayjs(a.firstSendingDate).diff(this.$dayjs(b.firstSendingDate))
        })
        this.nextCollect = await PickupManagement.getNextConfirmedCollect(this.selectedCustomer)
        this.collectLoading = false
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message || error,
          type: 'is-danger'
        })
        this.collectLoading = false
      }
    },
    spontaneousDeclaration: async function () {
      const token = await PickupManagement.spontaneousDeclaration(this.selectedCustomer)
      window.open('https://partner.fr-gps.com/consignee-declaration?token=' + token, '_blank')
    },
    seeDeclaration: async function (mail) {
      const declaration = await PickupManagement.getDeclaration(mail.declarationId)
      declaration.declarationQuantities = await PickupManagement.getQuantities(mail.declarationId)
      this.declaration = declaration
    },
    closeDeclaration: function () {
      this.declaration = null
    }
  }
}
</script>

<style scoped>

.container {
  width: 100%
}

.container > div {
  width: 100%
}

.container > div > div{
  width: 100%
}

.bar {
  width:100%;
  padding: 50px
}

.d2 {
  margin-top: 2rem;
}

.next-collect-card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 450px;
  border-radius: 6px;
  /* padding: 20px; */
  color: white;
  font-weight: 500;
  margin-top: 100px;
  text-align: center;
}

.card-body {
  padding: 20px;
}

.card-title {
  border-bottom:6px dashed #6abf9e;
  background-color: #404852;
  color: white;
  padding: 10px;
}

</style>
