import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/crates'

const crates = axios.create({
  baseURL
})

interceptorsSetup(crates)

export default class Device {
  static async get (barcode) {
    const { data: result } = await crates.get(`/${barcode}`)
    return result
  }
}
