import * as types from './mutation-types'

// ACTIONS
const actions = {
  setCustomer ({ commit }, customer) {
    commit(types.SET_SELECTED_CUSTOMER, customer)
  },
  switchSideBar ({ commit }) {
    commit(types.SWITCH_SIDE_BAR)
  },
  hideSideBar ({ commit }) {
    commit(types.HIDE_SIDE_BAR)
  }
}

export default actions
