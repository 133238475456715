<template>
  <div class="modal-card" style="min-height: 750px; overflow-y: auto;">
    <header class="modal-card-head">
      <p
        class="modal-card-title"
      >
        {{ editableShipping ? $t(`${localeRoot}.edit_modal.edit`) : $t(`${localeRoot}.edit_modal.create`) }} {{ $t(`${localeRoot}.edit_modal.shipping`) }}
      </p>
    </header>
    <ValidationObserver v-slot="{ invalid }">
      <section class="modal-card-body">
        <ValidationProvider v-slot="{ errors }" slim :rules="{ alphaSpacesDash: true }">
          <b-field
            :label="$t(`${localeRoot}` + '.edit_modal.reference')"
          >
            <b-input
              v-model="newShipping.reference"
              name="reference"
              type="text"
            />
          </b-field>
          <span class="has-text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
        <b-field
          :label="$t(`${localeRoot}` + '.edit_modal.reference_complement')"
        >
          <b-input
            v-model="newShipping.referenceComplement"
            name="referenceComplement"
            type="text"
          />
        </b-field>
        <b-field :label="$t(`${localeRoot}` + '.edit_modal.consignee')">
          <multiselect
            v-model="newShipping.enduser"
            :options="endusers"
            class="z-9"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="true"
            :allow-empty="true"
            :preserve-search="true"
            placeholder="Pick consignee..."
            :custom-label="enduser => {return enduser.partnerReference + ' - ' + enduser.name + ' | ' + enduser.city + ' - ' + enduser.country}"
            track-by="name"
          />
        </b-field>

        <b-field :label="$t(`${localeRoot}` + '.edit_modal.scan_user')">
          <multiselect
            v-model="newShipping.scanUser"
            :options="scanUsers"
            class="z-9"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="true"
            :allow-empty="true"
            :preserve-search="true"
            placeholder="Pick user..."
            label="name"
            track-by="id"
          />
        </b-field>

        <b-field :label="$t(`${localeRoot}` + '.edit_modal.crates')"/>
        <b-field>
          <b-input
            v-model="crateSearch"
            :placeholder="$t(`${localeRoot}` + '.edit_modal.search')"
            type="search"
          />
        </b-field>
        <b-field>
          <div class="crateMovements">
            <span
              v-for="crateMovement in filteredCrates"
              :key="crateMovement.id"
              class="tag crates"
              :class="[{'is-link': !crateMovement.toDelete}, {'is-danger': crateMovement.toDelete}]"
              @click="setToDelete(crateMovement)"
            >{{ crateMovement.barcode }}</span>
          </div>
        </b-field>

        <b-field :label="$t(`${localeRoot}` + '.edit_modal.add_crate')">
          <b-input
            v-model="newCrateMovement"
            name="crates"
            type="text"
            @keydown.native.enter="addCrateMovement()"
          />
        </b-field>
        <div class="is-pulled-right">
          <p class="control">
            <a class="button is-small is-primary" :disabled="addingCrate" @click="addCrateMovement()">
              <template>{{ $t(`${localeRoot}.edit_modal.add`) }}</template>
            </a>
            <a
              v-if="crateMovements.find((crate) => {return crate.toDelete})"
              class="button is-small is-danger"
              @click="removeCrate()"
            >
              <template>{{ $t(`${localeRoot}.edit_modal.delete`) }}</template>
            </a>
            <a
              v-if="crateMovements.find((crate) => {return crate.toDelete}) && !splittingShipping"
              class="button is-small is-warning"
              @click="moveToNewShipping()"
            >
              <template>{{ $t(`${localeRoot}.edit_modal.move_new_shipping`) }}</template>
            </a>
          </p>
        </div>

        <b-field v-for="(el, i) in dynamicFields" :key="i" :label="el.displayName">
          <b-input
            v-if="el.type === 'text'"
            :value="getValue(el)"
            name="crates"
            type="text"
            @input="(params) => onInput(params, el)"
          />
          <b-select v-if="el.type === 'select'" :value="getValue(el)" @input="(params) => onInput(params, el)">
            <option v-for="(el2, i2) in el.selectFields" :key="i2" :value="el2.value">
              {{ el2.label }}
            </option>
          </b-select>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <button
          :class=" {'is-loading' : isSubmitting}"
          :disabled="invalid"
          type="submit"
          class="button is-info is-fullwidth"
          @click="submit"
        >
          {{ $t(`${localeRoot}.edit_modal.submit`) }}
        </button>
      </footer>
    </ValidationObserver>
  </div>
</template>

<script>
import Crates from '@/services/v2/crates'
import ScanUsers from '@/services/v2/scanUsers'
import { mapState } from 'vuex'
import gql from 'graphql-tag'

export default {
  props: {
    isSubmitting: {
      type: Boolean,
      required: true
    },
    editableShipping: {
      type: Object,
      default: null
    },
    endusers: {
      type: Array,
      default: null
    },
    dynamicFields: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      newShipping: {
        id: '',
        reference: '',
        referenceComplement: '',
        enduser: '',
        creation_date: '',
        scanUser: ''
      },
      crateMovements: [],
      newCrateMovement: '',
      crateSearch: '',
      filteredCrates: null,
      localeRoot: 'customer.shipping_management',
      splittingShipping: false,
      cratesToKeepFromOldShipping: [],
      scanUsers: [],
      addingCrate: false,
      dynamicFieldsValue: []
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      selectedCustomer: state => state.selectedCustomer
    })
  },
  watch: {
    crateSearch (newValue, oldValue) {
      this.filterCrates()
    }
  },
  mounted: async function () {
    this.initData()
    await this.getDynamicFieldsValue()
  },
  methods: {
    async getDynamicFieldsValue () {
      if (this.newShipping.id) {
        const builder = this.$gql.query({
          operation: 'dynamicFieldsValue',
          fields: [{
            dynamicFieldsValue: [
              'shippingId',
              'fieldId',
              'customerCode',
              'value'
            ]
          }],
          variables: {
            shippingId: this.newShipping.id
          }
        })

        const result = await this.$apollo.query({
          fetchPolicy: 'no-cache',
          query: gql`${builder.query}`,
          variables: builder.variables
        })

        this.dynamicFieldsValue = result.data.dynamicFieldsValue.dynamicFieldsValue
      }
    },
    setToDelete (crateMovement) {
      let crateToDelete = this.crateMovements.find(crate => {
        return crate.barcode === crateMovement.barcode
      })
      crateToDelete.toDelete = !crateToDelete.toDelete
      this.filterCrates()
    },
    filterCrates () {
      this.filteredCrates = this.crateMovements.filter(crate => {
        return crate.barcode.includes(this.crateSearch)
      })
    },
    async addCrateMovement () {
      this.addingCrate = true
      if (this.newCrateMovement) {
        let crateExist = await Crates.get(this.newCrateMovement)
        if (
          crateExist.length > 0 &&
          !this.crateMovements.find(crate => {
            return crate.barcode === this.newCrateMovement
          })
        ) {
          this.crateMovements.push({ barcode: this.newCrateMovement })
          this.filterCrates()
          this.newCrateMovement = ''
        } else {
          this.$buefy.toast.open({
            type: 'is-danger',
            message: 'crate already added or unknown',
            duration: 3000
          })
        }
      }
      this.addingCrate = false
    },
    async submit () {
      try {
        // const result = await this.$validator.validateAll()
        if (!this.newShipping.reference && !this.newShipping.enduser) {
          throw new Error('Please specify at least a reference or a consignee')
        }
        // if (result) {
        if (this.splittingShipping) {
          this.$emit(
            'moveToNewShipping',
            this.crateMovements,
            this.newShipping,
            this.editableShipping,
            this.cratesToKeepFromOldShipping
          )
        } else {
          this.$emit('submit', [this.newShipping, this.crateMovements])
        }

        this.submitDynamicFieldsValue()
        return
        // }
      } catch (error) {
        this.$buefy.toast.open({
          type: 'is-danger',
          message: error.message,
          duration: 3000
        })
      }
    },
    removeCrate () {
      for (let i = 0; i < this.crateMovements.length; i++) {
        if (this.crateMovements[i].toDelete) {
          this.crateMovements.splice(i, 1)
          i--
        }
      }
      this.filterCrates()
    },
    moveToNewShipping () {
      this.$buefy.dialog.confirm({
        title: this.$t(`${this.localeRoot}.move_shipping_modal.title`),
        message: this.$t(`${this.localeRoot}.move_shipping_modal.content`),
        confirmText: this.$t(`${this.localeRoot}.move_shipping_modal.confirm`),
        cancelText: this.$t(`${this.localeRoot}.move_shipping_modal.cancel`),
        type: 'is-warning',
        hasIcon: true,
        onConfirm: async () => {
          try {
            this.newShipping = {
              id: '',
              reference: '',
              enduser: '',
              creation_date: this.editableShipping.creation_date
            }
            this.cratesToKeepFromOldShipping = this.crateMovements.filter(
              crate => {
                return !crate.toDelete
              }
            )
            this.crateMovements = this.crateMovements.filter(crate => {
              return crate.toDelete
            })
            this.filterCrates()
            this.splittingShipping = true
          } catch (error) {
            this.$buefy.toast.open({
              message: error.message || error,
              type: 'is-danger'
            })
          }
        }
      })
    },
    initData () {
      this.filteredCrates = this.crateMovements
      if (this.editableShipping) {
        this.crateMovements = this.editableShipping.crateMovements
        this.newShipping.id = this.editableShipping.id
        this.newShipping.reference = this.editableShipping.reference
        this.newShipping.referenceComplement = this.editableShipping.referenceComplement
        this.newShipping.enduser = this.editableShipping.enduser
        this.newShipping.creation_date = this.editableShipping.creation_date
        this.newShipping.scanUserName = this.editableShipping.scanUserName
        this.newShipping.scanUserId = this.editableShipping.scanUserId
      } else {
        this.newShipping.creation_date = new Date(Date.now()).toString()
      }
      this.getScanUsers()
      this.filterCrates()
    },
    async getScanUsers () {
      this.scanUsers = await ScanUsers.getAll(this.selectedCustomer)
      if (this.newShipping.scanUserId) {
        this.newShipping.scanUser = this.scanUsers.find((user) => {
          return user.id === this.newShipping.scanUserId
        })
      }
    },

    onInput (params, params2) {
      const find = this.dynamicFieldsValue.find((el) => el.fieldId === params2.fieldId)
      if (find) {
        find.value = params
      } else {
        this.dynamicFieldsValue.push({
          shippingId: this.newShipping.id,
          fieldId: params2.fieldId,
          customerCode: params2.customerCode,
          value: params
        })
      }
    },

    async submitDynamicFieldsValue () {
      this.dynamicFieldsValue = this.dynamicFieldsValue.map(el => {
        delete el.__typename
        return el
      })
      await this.$apollo.mutate({
        mutation: gql`
          mutation ($dynamicFieldsValue: [DynamicFieldsValueInput]) {
            dynamicFieldsValueUpdate(dynamicFieldsValue: $dynamicFieldsValue)
        }`,
        variables: {
          dynamicFieldsValue: this.dynamicFieldsValue
        }
      })
    },

    getValue (el) {
      const find = this.dynamicFieldsValue.find(el2 => el2.fieldId === el.fieldId)
      if (find) {
        return find.value
      }
    }
  }
}
</script>

<style scoped>
.crateMovements > span {
  margin: 2px;
}

.crateMovements > span:hover {
  background-color: #ef476f;
  cursor: pointer;
}
</style>
