<template>
  <ValidationObserver v-slot="{ invalid }" class="modal-card" style="">
    <header class="modal-card-head">
      <p
        class="modal-card-title"
      >
        {{ $t(`${localeRoot}.title`) }}
      </p>
    </header>
    <section class="modal-card-body">
      <div class="columns">
        <div class="column">
          <ValidationProvider v-slot="{ errors }" slim :rules="{ required: true, alphaNum: true }">
            <b-field
              :label="$t(`${localeRoot}` + '.name')"
            >
              <b-input
                v-model="newEnduser.name"
                name="name"
                type="text"
              />
            </b-field>
            <span class="has-text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" slim :rules="{ required: true, alphaNum: true }">
            <b-field
              :label="$t(`${localeRoot}` + '.partner_reference')"
            >
              <b-input
                v-model="newEnduser.partnerReference"
                name="partnerReference"
                type="text"
              />
            </b-field>
            <span class="has-text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" slim :rules="{ required: true, alphaNum: true }">
            <b-field
              :label="$t(`${localeRoot}` + '.country')"
            >
              <b-input
                v-model="newEnduser.country"
                name="country"
                type="text"
              />
            </b-field>
            <span class="has-text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" slim :rules="{ required: true, alphaNum: true }">
            <b-field
              :label="$t(`${localeRoot}` + '.city')"
            >
              <b-input
                v-model="newEnduser.city"
                name="city"
                type="text"
              />
            </b-field>
            <span class="has-text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" slim :rules="{ required: true, alphaNum: true }">
            <b-field
              :label="$t(`${localeRoot}` + '.postal_code')"
            >
              <b-input
                v-model="newEnduser.postalCode"
                name="postalCode"
                type="text"
              />
            </b-field>
            <span class="has-text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" slim :rules="{alphaNum: true }">
            <b-field
              :label="$t(`${localeRoot}` + '.street2')"
            >
              <b-input
                v-model="newEnduser.street2"
                name="street2"
                type="text"
              />
            </b-field>
            <span class="has-text-danger">{{ errors[0] }}</span>
          </ValidationProvider>

          <ValidationProvider v-slot="{ errors }" slim :rules="{ required: true, alphaNum: true }">
            <b-field
              :label="$t(`${localeRoot}` + '.street')"
            >
              <b-input
                v-model="newEnduser.street"
                name="street"
                type="text"
              />
            </b-field>
            <span class="has-text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="column">
          <div class="card custom-card">
            <span class="card-header-title">
              {{ $t(`${localeRoot}` + '.contact') }}
            </span>
            <div class="card-content">
              <ValidationProvider v-slot="{ errors }" slim :rules="{ required: true, alphaNum: true }">
                <b-field
                  :label="$t(`${localeRoot}` + '.contact_name')"
                >
                  <b-input
                    v-model="newEnduser.contactName"
                    name="contactName"
                    icon="font"
                    type="text"
                  />
                </b-field>
                <span class="has-text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" slim :rules="{ required: true, numeric: true }">
                <b-field
                  :label="$t(`${localeRoot}` + '.contact_tel')"
                >
                  <b-input
                    v-model="newEnduser.contactTel"
                    name="contactTel"
                    icon="phone"
                    type="tel"
                  />
                </b-field>
                <span class="has-text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" slim :rules="{ required: true, email: true }">
                <b-field
                  :label="$t(`${localeRoot}` + '.contact_email')"
                >
                  <b-input
                    v-model="newEnduser.contactEmail"
                    name="contactEmail"
                    icon="envelope"
                    type="email"
                  />
                </b-field>
                <span class="has-text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button
        class="button is-fullwidth"
        @click="$parent.close()"
      >
        {{ $t(`${localeRoot}.cancel`) }}
      </button>
      <button
        :class=" {'is-loading' : isSubmitting}"
        type="submit"
        :disabled="invalid"
        class="button is-info is-fullwidth"
        @click="submitData"
      >
        {{ $t(`${localeRoot}.submit`) }}
      </button>
    </footer>
  </ValidationObserver>
</template>

<script>

export default {
  props: {
    isSubmitting: {
      type: Boolean,
      required: true
    },
    customerId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      localeRoot: 'customer.shipping_management.enduser_form',
      newEnduser: {
        name: '',
        country: '',
        city: '',
        postalCode: '',
        street2: '',
        street: '',
        contactName: '',
        contactEmail: '',
        contactTel: ''
      }
    }
  },
  methods: {
    submitData: async function () {
      try {
        await this.$emit('submit', { 'enduser': this.newEnduser, 'customerId': this.customerId })
        return
      } catch (error) {
        this.$buefy.toast.open({
          type: 'is-danger',
          message: error.message,
          duration: 3000
        })
      }
    }
  }
}
</script>

<style scoped>
</style>
