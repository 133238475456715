<template>
  <section class="section">
    <div class="container is-fluid">
      <div class="columns">
        <div class="column is-3">
          <div class="card">
            <div class="card-image">
              <figure class="image is-4by3">
                <img src="https://picsum.photos/400?random" alt="Placeholder image">
              </figure>
            </div>
            <div v-if="user" class="card-content">
              <div class="media">
                <div class="media-left">
                  <b-icon icon="user" size="is-small"/>
                </div>
                <div class="media-content">
                  <p class="title is-4">
                    {{ user.displayName }}
                  </p>
                </div>
              </div>

              <div class="content">
                <div>
                  <ul>
                    <li>
                      Username : {{ user.username }}
                    </li>
                    <li>
                      Mail : {{ user.email || 'N/A' }}
                    </li>
                    <li>
                      <b-taglist>
                        Customer(s) : <b-tag v-for="customer in user.group.customers" :key="customer.id">
                          {{ customer.name }}
                        </b-tag>
                      </b-taglist>
                    </li>
                  </ul>
                </div>

                <!-- Min step : {{ user.minStepDays ||'N/A' }} days<br>
                Max step : {{ user.maxStepDays ||'N/A' }} days<br>
                Lost crate days : {{ user.lostCrateDays ||'N/A' }} days<br>
                CycleTime : {{ user.cycleTime ||'N/A' }} days<br> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    })
  },
  mounted: async function () {
  },
  methods: {}
}
</script>

<style>

</style>
