<template>
  <ValidationObserver v-slot="{ invalid }">
    <form @submit.prevent="submit">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">
            Change password
          </p>
        </header>
        <section class="modal-card-body">
          <ValidationProvider v-slot="{ errors, valid }" slim rules="required" name="Current password">
            <b-field :message="errors" :type="{ 'is-danger': errors[0], 'is-success': valid }" label="Current password">
              <b-input v-model="currentPassword" type="password" icon="lock" password-reveal/>
            </b-field>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors, valid }"
            slim
            rules="required"
            vid="password"
            name="Password"
          >
            <b-field :message="errors" :type="{ 'is-danger': errors[0], 'is-success': valid }" label="Password">
              <b-input v-model="password" type="password" icon="lock" password-reveal/>
            </b-field>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors, valid }" slim rules="required|confirmed:password" name="Password Confirmation">
            <b-field :message="errors" :type="{ 'is-danger': errors[0], 'is-success': valid }" label="Confirm Password">
              <b-input v-model="confirmPassword" type="password" icon="lock" password-reveal/>
            </b-field>
          </ValidationProvider>
        </section>
        <footer class="modal-card-foot">
          <button :class="{ 'is-loading' : isSubmitting }" :disabled="invalid" type="submit" class="button">
            <b-icon icon="paper-plane"/>
            <span>Submit</span>
          </button>
        </footer>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>

export default {
  props: {
    isSubmitting: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      currentPassword: '',
      password: '',
      confirmPassword: ''
    }
  },
  methods: {
    async submit () {
      this.$emit('submit', { currentPassword: this.currentPassword, newPassword: this.password })
    }
  }
}
</script>

<style>
</style>
