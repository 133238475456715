import CollectionPage from '@/views/CustomerViews/Collections/collection-page'
import CollectionTotal from '@/views/CustomerViews/Collections/collection-summary'
import CollectionDetail from '@/views/CustomerViews/Collections/collection-movements'
import DownloadPage from '@/views/CustomerViews/Download/download-page'
import DeliveryPage from '@/views/Deliveries/'
import ShippingManagementPage from '@/views/CustomerViews/ShippingManagement/'
import shippingFileManagement from '@/views/CustomerViews/ShippingManagement/shipping-file-management/'
import CrateTrack from '@/views/CustomerViews/TrackCrates'

import ShippingPage from '@/views/CustomerViews/Shipping/shipping-page'
import ShippingSummary from '@/views/CustomerViews/Shipping/shipping-summary'
import ScanUsers from '@/views/CustomerViews/ScanUsers/index'

import StockPage from '@/views/CustomerViews/Stock/stock-page'
import StockDetail from '@/views/CustomerViews/Stock/stock-detail'
import StockTotal from '@/views/CustomerViews/Stock/stock-summary'

import DashboardPage from '@/views/CustomerViews/Dashboard'

export default [
  {
    path: '/stock',
    component: StockPage,
    children: [
      {
        path: 'detail',
        component: StockDetail,
        meta: { title: 'Stock Detail', authorize: ['Stock access'] }
      },
      {
        path: '',
        name: 'stock',
        meta: { title: 'Stock', authorize: ['Stock access'] },
        component: StockTotal
      }
    ]
  },
  {
    path: '/dashboard/:id',
    props: true,
    params: { id: 0 },
    component: DashboardPage,
    name: 'dashboard',
    meta: { title: 'Dashboard', authorize: ['Dashboard access'] }
  },
  {
    path: '/deliveries',
    component: DeliveryPage,
    name: 'deliveries',
    meta: { title: 'Deliveries', authorize: ['Delivery access'] }
  },
  {
    path: '/shipping',
    component: ShippingPage,
    children: [
      {
        path: '',
        meta: { title: 'Collections', authorize: ['Shipping access'] },
        component: ShippingSummary,
        name: 'shippings'
      }
    ]
  },
  {
    path: '/collections',
    component: CollectionPage,
    children: [
      {
        path: '',
        meta: { title: 'Collections', authorize: ['Collections access'] },
        component: CollectionTotal,
        name: 'collections'
      },
      {
        path: 'detail',
        meta: { title: 'Collections', authorize: ['Collections access'] },
        component: CollectionDetail,
        name: 'collections-detail',
        props: true
      }
    ]
  },
  {
    path: '/download',
    component: DownloadPage,
    name: 'download',
    meta: { title: 'Download', authorize: ['Download access'] }

  },
  {
    path: '/shippingManagement',
    component: ShippingManagementPage,
    name: 'customer-shipping',
    meta: { title: 'Shipping Management', authorize: ['Shipping manager user', 'Shipping manager admin'] }
  },
  {
    path: '/scan-users',
    component: ScanUsers,
    name: 'scanUsers',
    meta: { title: 'Scan users', authorize: ['Scan auth access'] }
  },
  {
    path: '/shippingFileManagement',
    component: shippingFileManagement,
    name: 'shippingFileManagement',
    meta: { title: 'Shipping file Management', authorize: ['Shipping manager admin'] }
  },
  {
    path: '/crateTrack',
    component: CrateTrack,
    name: 'crateTrack',
    meta: { title: 'Track Crates', authorize: ['Track access'] }
  }
]
