<template>
  <div>
    <div class="columns">
      <div class="column is-narrow">
        <p class="field">
          <router-link class="button is-grey" to="/collections">
            <b-icon size="is-small" icon="arrow-left"/>
            <span>Back</span>
          </router-link>
        </p>
      </div>
      <div class="column">
        <nav class="level">
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">
                {{ $t('global.name') }}
              </p>
              <p>{{ enduser.name || 'N/A' }}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">
                {{ $t('global.country') }}
              </p>
              <p>{{ enduser.country || 'N/A' }}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">
                {{ $t('global.city') }}
              </p>
              <p>{{ enduser.city || 'N/A' }}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">
                {{ $t('global.total') }}
              </p>
              <p>{{ total }}</p>
            </div>
          </div>
        </nav>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12">
        <gridManager
          :grid-id="gridId"
          :profiles="gridProfiles"
          @save="saveProfile"
          @create="createProfile"
          @update="updateProfile"
          @select="selectProfile"
          @delete="deleteProfile"
        />

        <ag-grid-vue
          :id="gridId"
          style="width: 100%; height: 750px;"
          class="ag-theme-balham"
          :grid-options="gridOptions"
          :get-child-count="getChildCount"
          :pagination="true"
          :pagination-auto-page-size="true"
          :side-bar="['columns']"
          row-selection="multiple"
          :multi-sort-key="'ctrl'"
          :get-context-menu-items="getContextMenuItems"
          :modules="modules"
          @grid-ready="onGridReady"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import sortAndFilterMixins from '@/mixins/agGridSortAndFilter'
import GridManager from '@/components/common/grid-manager'

import gql from 'graphql-tag'
import XLSX from 'xlsx'
import { AllModules } from '@ag-grid-enterprise/all-modules'

export default {
  name: 'collections-detail',
  components: {
    GridManager
  },
  mixins: [sortAndFilterMixins],
  data () {
    return {
      gridId: 'collection-detail',
      isLoading: true,
      modules: AllModules,
      gridOptions: {
        rowModelType: 'serverSide',
        columnDefs: [
          { headerName: this.$t('customer.collections.details.barcode'), field: 'barcode', filter: 'agTextColumnFilter', sortable: true, filterParams: { suppressAndOrCondition: true }, width: 100 },
          { headerName: 'Delivery date',
            field: 'deliveryDate',
            filter: 'agDateColumnFilter',
            sortable: true,
            filterParams: { suppressAndOrCondition: true },
            width: 140,
            cellRenderer: (data) => {
              return data.value && new Date(data.value).toLocaleDateString()
            }
          },
          { headerName: this.$t('customer.collections.details.shipping_date'),
            field: 'lastShipToDate',
            filter: 'agDateColumnFilter',
            sortable: true,
            filterParams: { suppressAndOrCondition: true },
            width: 140,
            cellRenderer: (data) => {
              return data.value && new Date(data.value).toLocaleDateString()
            }
          },
          { headerName: this.$t('customer.collections.details.collection_date'),
            field: 'collectionDate',
            filter: 'agDateColumnFilter',
            sortable: true,
            filterParams: { suppressAndOrCondition: true },
            width: 140,
            cellRenderer: (data) => {
              return data.value && new Date(data.value).toLocaleDateString()
            } },
          { headerName: this.$t('customer.collections.details.notification_date'),
            field: 'notificationDate',
            filter: 'agDateColumnFilter',
            sortable: true,
            filterParams: { suppressAndOrCondition: true },
            width: 140,
            cellRenderer: (data) => {
              return data.value && new Date(data.value).toLocaleDateString()
            } },

          { headerName: this.$t('customer.collections.details.hiringPeriod'),
            field: 'hiringPeriod',
            filter: 'agNumberColumnFilter',
            sortable: true,
            filterParams: { suppressAndOrCondition: true },
            width: 140
          },
          { headerName: this.$t('customer.collections.details.expectedArrivalDate'),
            field: 'expectedArrivalDate',
            filter: 'agDateColumnFilter',
            sortable: true,
            filterParams: { suppressAndOrCondition: true },
            width: 150,
            cellRenderer: (data) => {
              return data.value && new Date(data.value).toLocaleDateString()
            }
          },
          { headerName: this.$t('customer.collections.details.shipmentNumber'),
            field: 'shipmentNumber',
            filter: 'agTextColumnFilter',
            sortable: true,
            filterParams: { suppressAndOrCondition: true },
            width: 150
          },
          { headerName: this.$t('customer.collections.details.transitTime'),
            field: 'transitTime',
            filter: 'agTextColumnFilter',
            sortable: true,
            filterParams: { suppressAndOrCondition: true },
            width: 150
          },
          { headerName: this.$t('customer.collections.details.csRefcycleTime'),
            field: 'csRefcycleTime',
            filter: 'agNumberColumnFilter',
            sortable: true,
            filterParams: { suppressAndOrCondition: true },
            width: 140
          }

        ],
        defaultColDef: {
          floatingFilter: true,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: true,
          resizable: true
        }
      },
      enduser: {},
      total: 0,
      collections: []
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    })
  },
  mounted: async function () {
    const enduserId = this.$route.query.enduserId
    const query = gql`
        query enduser(
          $enduserId: ID!,
        ) {
          enduser(
            enduserId: $enduserId,
            ) {
           name
           country
           city
          }
        }`
    const result = await this.$apollo.provider.defaultClient.query({
      query: query,
      variables: {
        enduserId: enduserId
      }
    })
    this.enduser = result.data.enduser
  },
  methods: {
    getContextMenuItems (params) {
      const result = [
        'copy',
        'copyWithHeaders',
        'separator',
        {
          name: 'Export',
          icon: '<span class="ag-icon ag-icon-save" unselectable="on"></span>',
          subMenu: [
            {
              name: 'CSV Export',
              action: () => {
                this.csvDownload()
              }
            },
            {
              name: 'Excel Export (.xlsx)',
              action: () => {
                this.xlsxDownload()
              }
            }
          ]
        }
      ]
      return result
    },
    onGridReady: async function (params) {
      const { fromDate, toDate, enduserId } = this.$route.query

      const instance = this.gridOptions.api.getFilterInstance('collectionDate')
      instance.setModel({
        type: 'inRange',
        dateFrom: fromDate,
        dateTo: toDate
      })
      this.gridOptions.api.onFilterChanged()

      const dataSource = {
        getRows: async (params) => {
          const groupSummary = this.getGroups(params.request)
          let columns = groupSummary.filter(e => e.isHere === true).map(e => e.column.toString())
          if (!columns.length) {
            columns = this.gridOptions.columnDefs.map(colDef => colDef.field)
          }

          const builder = this.$gql.query({
            operation: 'collections',
            fields: ['totalCount', { edges: [{ node: [...columns] }] }],
            variables: {
              skip: { type: 'Int', value: params.request.startRow },
              limit: { type: 'Int', value: params.request.endRow },
              sort: { type: '[sortInputCollection]', value: this.getSorts(params.request) },
              filter: { type: 'filterInputCollection', value: this.getFilters(params.request) },
              group: { type: 'Boolean', value: !!groupSummary.find(e => e.isHere === true) },
              customerId: { type: 'ID!', value: this.$store.state.selectedCustomer },
              enduserId: { type: 'ID!', value: enduserId }
            }
          })

          const result = await this.$apollo.query({
            fetchPolicy: 'no-cache',
            query: gql`${builder.query}`,
            variables: builder.variables })
          const collectionsResult = result.data.collections
          this.total = collectionsResult.totalCount
          this.collections = collectionsResult.edges.map(e => e.node)
          params.successCallback(this.collections, this.total)
        }
      }
      params.api.setServerSideDatasource(dataSource)
    },
    getChildCount: function (data) {
      if (data) {
        return data.aggregate.count.barcode
      }
    },
    workbook: async function () {
      const { enduserId } = this.$route.query

      const request = this.gridOptions.api.getModel().cacheParams

      const builder = this.$gql.query({
        operation: 'collections',
        fields: ['totalCount', { edges: [{ node: [...this.gridOptions.columnDefs.map(colDef => colDef.field)] }] }],
        variables: {
          sort: { type: '[sortInputCollection]', value: this.getSorts(request) },
          filter: { type: 'filterInputCollection', value: this.getFilters(request) },
          customerId: { type: 'ID!', value: this.$store.state.selectedCustomer },
          enduserId: { type: 'ID!', value: enduserId }
        }
      })

      const result = await this.$apollo.query({
        fetchPolicy: 'no-cache',
        query: gql`${builder.query}`,
        variables: builder.variables })

      const columnsDefs = this.gridOptions.columnApi.getAllDisplayedColumns().map(col => col.colDef)
      const csvFields = columnsDefs.map(e => e.field)
      const csvColumns = columnsDefs.map(e => e.headerName)

      const csvContent = result.data.collections.edges.map(e => {
        return csvFields.map(field => e.node[field])
      }
      )
      const data = [csvColumns, ...csvContent]
      const worksheet = XLSX.utils.aoa_to_sheet(data)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'SheetJS')
      return workbook
    },
    csvDownload: async function () {
      XLSX.writeFile(await this.workbook(), 'export.csv')
    },
    xlsxDownload: async function () {
      XLSX.writeFile(await this.workbook(), 'export.xlsx')
    }
  }
}
</script>
