<template>
  <section class="section">
    <b-loading :active.sync="loading" is-full-page/>
    <div>
      <h1 class="title">
        {{ $t('customer.scan_users.scan_users') }}
      </h1>
      <div class="columns">
        <div class="column">
          <a class="button is-link is-outlined" @click="addUser()"><b-icon icon="plus"/> <span>{{ $t('global.add') }} </span></a>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-table
            :loading="loading"
            :narrowed="true"
            :striped="true"
            :paginated="true"
            :data="users"
          >
            <template slot="header" slot-scope="props">
              <b-tooltip
                v-if="!!props.column.meta"
                :active="!!props.column.meta"
                :label="props.column.meta"
                dashed
              >
                <template>{{ props.column.label }}</template>
              </b-tooltip>
              <span v-else>{{ props.column.label }}</span>
            </template>
            <template slot-scope="props">
              <b-table-column field="name" :label="$t('global.name')" sortable>
                <template>{{ props.row.name }}</template>
              </b-table-column>
              <b-table-column field="code" :label="$t('customer.scan_users.code')" sortable>
                <template>{{ props.row.code }}</template>
              </b-table-column>
              <b-table-column :label="$t('global.actions')">
                <span class="field is-grouped">
                  <p class="control">
                    <a class="button is-small is-info is-outlined" @click="showEditForm(props.row)">
                      <template>{{ $t('global.edit') }}</template>
                    </a>
                  </p>
                  <p class="control">
                    <a class="button is-small is-danger is-outlined" @click="deleteUser(props.row)">
                      <template>{{ $t('global.delete') }}</template>
                    </a>
                  </p>
                </span>
              </b-table-column>
            </template>
            <template slot="empty">
              <nothing-here/>
            </template>
          </b-table>
        </div>
        <div class="column">
          <b-table
            :loading="loading"
            :narrowed="true"
            :striped="true"
            :paginated="true"
            :data="filteredDevices"
          >
            <template slot="header" slot-scope="props">
              <b-tooltip
                v-if="!!props.column.meta"
                :active="!!props.column.meta"
                :label="props.column.meta"
                dashed
              >
                <template>{{ props.column.label }}</template>
              </b-tooltip>
              <span v-else>{{ props.column.label }}</span>
            </template>
            <template slot-scope="props">
              <b-table-column field="uuid" label="Uuid" sortable>
                <template>{{ props.row.uuid }}</template>
              </b-table-column>
              <b-table-column field="serial" :label="$t('customer.scan_users.serial_number')" sortable>
                <template>{{ props.row.serialNumber }}</template>
              </b-table-column>
              <b-table-column field="lastSyncAt" :label="$t('customer.scan_users.last_sync_at')" sortable>
                <span class="tag is-primary">
                  {{ new Date(props.row.lastSyncAt).toLocaleString() }}
                </span>
              </b-table-column>
              <b-table-column :label="$t('customer.scan_users.auth_activated')">
                <span class="field is-grouped">
                  <b-switch
                    v-model="props.row.authActivated"
                    type="is-info"
                    :true-value="1"
                    :false-value="0"
                    @input="switchChange(props.row)"
                  />
                </span>
              </b-table-column>
              <b-table-column :label="$t('customer.scan_users.auth_required')">
                <span class="field is-grouped">
                  <b-switch
                    v-model="props.row.authRequired"
                    type="is-info"
                    :true-value="1"
                    :false-value="0"
                    @input="switchChange(props.row)"
                  />
                </span>
              </b-table-column>
            </template>
            <template slot="empty">
              <nothing-here/>
            </template>
          </b-table>
          <span>
            <b-icon icon="exclamation-circle" type="is-warning"/>
            <i> {{ $t('customer.scan_users.warning_message1') }}</i>
            <br>
            <i>{{ $t('customer.scan_users.warning_message2') }}</i>
          </span>
        </div>
      </div>
    </div>
    <b-modal
      :active.sync="isEditing"
      scroll="keep"
      has-modal-card
    >
      <userForm
        :user="userToEdit"
        :is-submitting="isSubmitting"
        @submitUpdate="submitUpdateUser"
        @submitCreate="submitCreateUser"
      />
    </b-modal>
  </section>
</template>

<script>

import ScanUsers from '@/services/v2/scanUsers'
import { mapState } from 'vuex'
import userForm from './user-form'

export default {
  components: {
    userForm
  },
  data () {
    return {
      loading: false,
      users: [],
      isEditing: false,
      userToEdit: null,
      isSubmitting: false,
      devices: [],
      search: ''
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      selectedCustomer: state => state.selectedCustomer
    }),
    filteredDevices: function () {
      return this.devices
        .filter((row) => {
          return Object.keys(row).some((key) => {
            return String(row[key]).toLowerCase().indexOf(this.search.toLowerCase()) > -1
          })
        })
    }
  },
  watch: {
    selectedCustomer (newValue, oldValue) {
      this.loadData()
    }
  },
  mounted: async function () {
    this.loading = true
    try {
      this.loadData()
      this.loading = false
    } catch (error) {
      this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
      this.loading = false
    }
  },
  methods: {
    loadData: async function () {
      try {
        this.users = await ScanUsers.getAll(this.selectedCustomer)
        this.devices = await ScanUsers.getDevices(this.selectedCustomer)
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message || error,
          type: 'is-danger'
        })
      }
    },
    submitCreateUser: async function (user) {
      try {
        this.isSubmitting = true
        user.customerId = this.selectedCustomer
        await ScanUsers.create(user)
        this.loadData()
        this.isEditing = false
        this.$buefy.toast.open({
          message: this.$t('customer.scan_users.created'),
          type: 'is-primary'
        })
        this.isSubmitting = false
      } catch (error) {
        this.isSubmitting = false

        this.$buefy.toast.open({
          message: error.message || error,
          type: 'is-danger'
        })
      }
    },
    showEditForm: async function (row) {
      this.userToEdit = row
      this.isEditing = true
    },
    submitUpdateUser: async function (user) {
      try {
        this.isSubmitting = true
        user.customerId = this.selectedCustomer
        await ScanUsers.update(user.id, user)
        this.loadData()
        this.isEditing = false
        this.$buefy.toast.open({
          message: this.$t('customer.scan_users.edited'),
          type: 'is-primary'
        })
        this.isSubmitting = false
      } catch (error) {
        this.isSubmitting = false

        this.$buefy.toast.open({
          message: error.message || error,
          type: 'is-danger'
        })
      }
    },
    deleteUser: async function (user) {
      this.$buefy.dialog.confirm({
        title: this.$t('customer.scan_users.delete_user'),
        message: this.$t('customer.scan_users.delete_message'),
        confirmText: this.$t('global.yes'),
        cancelText: this.$t('global.no'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await ScanUsers.delete(user.id)
            await this.loadData()
          } catch (error) {
            this.$buefy.toast.open({
              message: error.message || error,
              type: 'is-danger'
            })
          }
        }
      })
    },
    addUser: async function () {
      this.isEditing = true
    },
    switchChange: async function (data) {
      try {
        if (data.authRequired && !data.authActivated) {
          data.authActivated = true
        }
        await ScanUsers.editDeviceAuth({ deviceUuid: data.uuid, authActivated: data.authActivated, authRequired: data.authRequired })
        this.loadData()
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message || error,
          type: 'is-danger'
        })
      }
    }
  }
}
</script>

<style>
</style>
