<template>
  <section style="height: 90vh;">
    <div class="my-5 ml-3">
      <b-button icon-left="arrow-left" @click="close"/>
      <ul class="point-list my-5">
        <b-collapse
          v-for="(collapse, index) of groupedPointsBySi"
          :key="collapse[0].si"
          class="card"
          animation="slide"
          :open="isOpen === index"
          @open="isOpen = index"
        >
          <template #trigger="props">
            <div
              class="card-header"
              role="button"
              :aria-controls="'contentIdForA11y5-' + index"
              :aria-expanded="props.open"
            >
              <p class="card-header-title">
                {{ collapse[0].si }} - {{ collapse[0].customerName }}
              </p>
              <a class="card-header-icon">
                <b-icon
                  :icon="props.open ? 'caret-down' : 'caret-up'"
                />
              </a>
            </div>
          </template>
          <div class="card-content">
            <div class="content">
              <li v-for="(p, i) in collapse.slice(0, -1)" :key="i">
                <div class="is-flex is-flex-direction-row is-align-items-stretch">
                  <div class="is-flex is-flex-direction-column is-justify-content-space-between is-size-7 mr-2">
                    <div class="mt-2">
                      <b-tooltip :label="`${new Date(p.timestamp).toLocaleString()}` " position="is-right">
                        {{ $dayjs(p.timestamp).local().format('MM/DD') }}
                      </b-tooltip>
                      <div v-if="p.endTimestamp" class="mt-1">
                        <b-tooltip :label="`${new Date(p.endTimestamp).toLocaleString()}` " position="is-right">
                          {{ $dayjs(p.endTimestamp).local().format('MM/DD') }}
                        </b-tooltip>
                      </div>
                    </div>

                    <div class="mb-5">
                      {{ panelPoints[i+1] && panelPoints[i+1].duration ? `${panelPoints[i+1].duration}d` : null }}
                      {{ i === panelPoints.length -1 ? `${calDuration(p.endTimestamp || p.timestamp, Date.now())}d` : null }}
                    </div>
                  </div>

                  <div class="is-flex is-flex-direction-column is-align-items-center ">
                    <b-button :icon-left="getIcon(p)" :style="{ width: '50px', height: '50px', borderRadius: '50%', backgroundColor: getIconColor(p), color: 'white'}" @click="focusOnPoint(p)"/>
                    <div v-if="i !== panelPoints.length -1" class="solid-line"/>
                    <div v-else :class="`${lastStatus.lineType}-line`"/>
                  </div>
                  <div class="ml-5">
                    <p>
                      <strong> {{ p.status }} </strong>
                    </p>
                    <p class="is-size-6">
                      {{ p.name || 'Unknown name' }}
                    </p>
                    <p> {{ p.country || 'Unknown' }} </p>
                    <br>
                  </div>
                </div>
              </li>

              <div class="is-flex is-flex-direction-row is-align-items-center ">
                <button
                  icon-left="check"
                  :style="{ width: '50px', height: '50px', borderRadius: '50%', border:'1px dotted #D3D3D3', backgroundColor: collapse[collapse.length - 1].status === 'Finished' ? '#6abf9e' : 'transparent'}"
                >
                  <b-icon
                    :icon="collapse[collapse.length - 1].status === 'Finished' ? 'check' : 'truck'"
                    :style="{color: collapse[collapse.length - 1].status === 'Finished' ? 'white' : '#D3D3D3'}"
                  />
                </button>

                <div class="ml-5" :style="{color: collapse[collapse.length - 1].status === 'Finished' ? 'black' : '#D3D3D3', fontWeight: '700'}">
                  {{ collapse[collapse.length - 1].status }}
                </div>

                <br>
              </div>
            </div>
          </div>
        </b-collapse>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    tracePoints: {
      default: () => [],
      type: Array
    }
  },
  data () {
    return {
      isOpen: 0,
      panelPoints: [],
      lastStatus: {},
      pointsBySi: [],
      groupedPointsBySi: []
    }
  },
  mounted () {
    this.pointsBySi = this.groupData(this.tracePoints)
    this.groupedPointsBySi = this.pointsBySi.map(p => this.processData(p.barcodes))

    // this.panelPoints = this.processData(this.tracePoints)
  },
  methods: {
    processData (data) {
      const nonNullData = data.filter(d => d.code)
      const filtedData = this.filterData(nonNullData)
      const keyPoints = []
      let previousTimestamp = null

      filtedData.forEach(p => {
        if (p.locationMethod === 'sd' && p.type === 'PLANT') {
          keyPoints.push({
            endTimestamp: p.endTimestamp || null,
            city: p.city || 'unknown',
            country: p.country || 'unknown',
            status: 'Sent out from GPS plant',
            ...p
          })
          previousTimestamp = p.endTimestamp || p.timestamp
        } else if (p.type === 'PORT') {
          const duration = previousTimestamp ? this.calDuration(previousTimestamp, p.timestamp) : null
          keyPoints.push({
            endTimestamp: p.endTimestamp || null,
            city: p.city || 'unknown',
            country: p.country || 'unknown',
            status: 'At port',
            duration,
            ...p
          })
          previousTimestamp = p.endTimestamp || p.timestamp
        } else if (p.type === 'NATURAL PRODUCER') {
          const duration = previousTimestamp ? this.calDuration(previousTimestamp, p.timestamp) : null
          keyPoints.push({
            endTimestamp: p.endTimestamp || null,
            city: p.city || 'unknown',
            country: p.country || 'unknown',
            status: 'At natural rubber producer',
            duration,
            ...p
          })
          previousTimestamp = p.endTimestamp || p.timestamp
        } else if (p.type === 'ENDUSER') {
          const duration = previousTimestamp ? this.calDuration(previousTimestamp, p.timestamp) : null
          keyPoints.push({
            endTimestamp: p.endTimestamp || null,
            city: p.city || 'unknown',
            country: p.country || 'unknown',
            status: 'At Enduser',
            duration,
            ...p
          })
          previousTimestamp = p.endTimestamp || p.timestamp
        } else if (p.type === 'CUSTOMER') {
          const duration = previousTimestamp ? this.calDuration(previousTimestamp, p.timestamp) : null
          keyPoints.push({
            endTimestamp: p.endTimestamp || null,
            city: p.city || 'unknown',
            country: p.country || 'unknown',
            status: 'At Customer',
            duration,
            ...p
          })
          previousTimestamp = p.endTimestamp || p.timestamp
        } else if (p.type === 'DISTRIBUTOR') {
          const duration = previousTimestamp ? this.calDuration(previousTimestamp, p.timestamp) : null
          keyPoints.push({
            endTimestamp: p.endTimestamp || null,
            city: p.city || 'unknown',
            country: p.country || 'unknown',
            status: 'At Distributor',
            duration,
            ...p
          })
          previousTimestamp = p.endTimestamp || p.timestamp
        } else if (p.type === 'CUSTOMER WAREHOUSE') {
          const duration = previousTimestamp ? this.calDuration(previousTimestamp, p.timestamp) : null
          keyPoints.push({
            endTimestamp: p.endTimestamp || null,
            city: p.city || 'unknown',
            country: p.country || 'unknown',
            status: 'At Cusomter warehouse',
            duration,
            ...p
          })
          previousTimestamp = p.endTimestamp || p.timestamp
        } else if (p.locationMethod === 'sc' && p.type === 'PLANT') {
          const duration = previousTimestamp ? this.calDuration(previousTimestamp, p.timestamp) : null
          keyPoints.push({
            endTimestamp: p.endTimestamp || null,
            city: p.city || 'unknown',
            country: p.country || 'unknown',
            status: 'Collected',
            duration,
            ...p
          })
          previousTimestamp = p.endTimestamp || p.timestamp
        } else if (p.type === 'PLANT') {
          const duration = previousTimestamp ? this.calDuration(previousTimestamp, p.timestamp) : null
          keyPoints.push({
            endTimestamp: p.endTimestamp || null,
            city: p.city || 'unknown',
            country: p.country || 'unknown',
            status: 'At GPS plant',
            duration,
            ...p
          })
          previousTimestamp = p.endTimestamp || p.timestamp
        }
      })

      if (keyPoints.length > 0 && keyPoints[keyPoints.length - 1].status !== 'Collected') {
        keyPoints.push({
          status: 'In Transportation',
          type: 'TRANSPORT',
          lineType: 'dashed'
        })
      } else {
        keyPoints.push({
          status: 'Finished',
          type: 'FINISHED',
          lineType: 'solid'
        })
      }

      return keyPoints
    },
    calDuration (start, end) {
      const startTime = this.$dayjs(start)
      return this.$dayjs(end).diff(startTime, 'day')
    },
    filterData (data) {
      const processedData = []
      let curPoint = null

      data.forEach(d => {
        if (!curPoint) {
          curPoint = { ...d }
        } else if (d.type === curPoint.type && d.code === curPoint.code) {
          curPoint.endTimestamp = d.timestamp
        } else {
          processedData.push({ ...curPoint })
          curPoint = { ...d }
        }
      })

      if (curPoint) {
        processedData.push({ ...curPoint })
      }
      return processedData
    },
    groupData (data) {
      const groupedData = data.reduce((acc, cur) => {
        if (cur.si === '') {
          return acc
        }
        const si = cur.si
        if (!acc[si]) {
          acc[si] = { si, barcodes: [] }
        }
        acc[si].barcodes.push({ ...cur })
        return acc
      }, {})
      return Object.values(groupedData)
    },
    getIcon (data) {
      switch (data.type) {
        case 'PLANT':
          return 'warehouse'
        case 'PORT':
          return 'ship'
        case 'NATURAL PRODUCER':
          return 'tree'
        case 'ENDUSER':
          return 'star'
        case 'CUSTOMER':
          return 'user'
        case 'DISTRIBUTOR':
          return 'expand'
        case 'CUSTOMER WAREHOUSE':
          return 'home'
      }
    },
    getIconColor (data) {
      switch (data.type) {
        case 'PLANT':
          return '#6abf9e'
        case 'PORT':
          return '#0063d2'
        case 'NATURAL PRODUCER':
          return '#9d87de'
        case 'ENDUSER':
          return '#d57553'
        case 'CUSTOMER':
          return '#1ccdf0'
        case 'DISTRIBUTOR':
          return '#babc96'
        case 'CUSTOMER WAREHOUSE':
          return '#677798'
      }
    },
    close () {
      this.$emit('onClose')
    },
    focusOnPoint (p) {
      this.$emit('onFocusPoint', p)
    }
  }
}
</script>

<style>
.point-list {
    height: 75vh;
    list-style-type: none; /* Removes default list styling */
    max-height: 90vh; /* Adjust height as needed */
    overflow-y: auto; /* Makes the list scrollable */
}

.solid-line {
  border-left: 2px solid rgb(190, 190, 190); /* Solid line by default */
  height: 30px; /* Adjust this as needed */
  margin: 20px 0; /* Some spacing */
}

.dashed-line {
  border-left: 2px dashed rgb(190, 190, 190);
  height: 30px; /* Adjust this as needed */
  margin: 20px 0; /* Dashed line */
}
</style>
