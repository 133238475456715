import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/scanUsers'

const scanUsers = axios.create({
  baseURL
})

interceptorsSetup(scanUsers)

export default class ScanUsers {
  static async getAll (customerId) {
    const { data: result } = await scanUsers.get(`/${customerId}`)
    return result
  }

  static async create (user) {
    const { data: result } = await scanUsers.post(`/`, user)
    return result
  }

  static async update (userId, user) {
    const { data: result } = await scanUsers.put(`/${userId}`, user)
    return result
  }

  static async delete (userId) {
    const { data: result } = await scanUsers.delete(`/${userId}`)
    return result
  }

  static async getDevices (customerId) {
    const { data: result } = await scanUsers.get(`/devices/${customerId}`)
    return result
  }

  static async editDeviceAuth ({ deviceUuid, authActivated, authRequired }) {
    const { data: result } = await scanUsers.put(`/devices/${deviceUuid}`, {
      authActivated: authActivated,
      authRequired: authRequired
    })
    return result
  }
}
