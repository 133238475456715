<template>
  <div class="section">
    <div class="container is-fluid">
      <div class="columns">
        <b-button
          icon-left="arrow-left"
          @click="$router.go(-1)"
        >
          {{ $t(`customer.shipping_file_manager.back`) }}
        </b-button>
      </div>

      <div class="columns">
        <div class="column is-12">
          <b-table
            :per-page="10"
            :narrowed="true"
            :striped="true"
            :paginated="true"
            :loading="isLoading"
            :data="files"
            :hoverable="true"
          >
            <template slot="header" slot-scope="props">
              <b-tooltip
                v-if="!!props.column.meta"
                :active="!!props.column.meta"
                :label="props.column.meta"
                type="is-dark"
                dashed
              >
                <template>{{ props.column.label }}</template>
              </b-tooltip>
              <span v-else>{{ props.column.label }}</span>
            </template>
            <b-table-column
              v-slot="props"
              field="fileName"
              :label="$t(`customer.shipping_file_manager.file_name`)"
              sortable
            >
              {{ props.row.originalName }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="uploadDate"
              :label="$t(`customer.shipping_file_manager.upload_date`)"
              sortable
            >
              {{
                $dayjs(props.row.uploadDate).format('YYYY-MM-DD')
              }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="status"
              :label="$t(`customer.shipping_file_manager.status`)"
              sortable
            >
              {{
                props.row.status
              }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="actions"
              :label="$t('customer.shipping_file_manager.actions')"
            >
              <template>
                <span class="field is-grouped">
                  <p class="control">
                    <a
                      class="button is-small is-danger is-outlined"
                      @click="deleteFile(props.row.id)"
                    >
                      <template>{{ $t(`customer.shipping_file_manager.delete`) }}</template>
                    </a>
                  </p>
                  <p class="control">
                    <a
                      class="button is-small is-info"
                      @click="downloadFile(props.row.id, props.row.originalName)"
                    >
                      <template>{{ $t(`customer.shipping_file_manager.download`) }}</template>
                    </a>
                  </p>
                </span>
              </template>
            </b-table-column>
            <template slot="empty">
              <nothing-here/>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShippingManagement from '@/services/v2/shippingManagement'
import { saveAs } from 'file-saver'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      files: [],
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      selectedCustomer: state => state.selectedCustomer
    })
  },
  watch: {
    selectedCustomer (newValue, oldValue) {
      this.filteredShippings = []
      this.initData()
    }
  },
  mounted: function () {
    this.initData()
  },
  methods: {
    async initData () {
      this.isLoading = true
      this.files = await ShippingManagement.getCustomerFiles(this.selectedCustomer, 1)
      this.isLoading = false
    },
    async deleteFile (id) {
      this.$buefy.dialog.confirm({
        title: this.$t('customer.shipping_file_manager.delete_modal_title'),
        message: this.$t('customer.shipping_file_manager.delete_modal_message'),
        confirmText: this.$t('customer.shipping_file_manager.confirm'),
        cancelText: this.$t('customer.shipping_file_manager.cancel'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await ShippingManagement.deleteCustomerFile(id)
            this.$buefy.toast.open({
              message: this.$t(`customer.shipping_file_manager.success`),
              type: 'is-primary'
            })
            await this.initData()
          } catch (error) {
            this.$buefy.toast.open({
              message: error.message || error,
              type: 'is-danger'
            })
          }
        }
      })
    },
    async downloadFile (id, fileName) {
      try {
        const file = await ShippingManagement.downloadCustomerFile(id)
        saveAs(file, fileName)
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message || error,
          type: 'is-danger'
        })
      }
    }
  }
}
</script>
