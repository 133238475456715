<template>
  <section class="section">
    <div class="container is-fluid">
      <b-loading v-model="isLoading" :is-full-page="false"/>
      <h1 class="title">
        {{ $t('consignee.collections.collections') }}
      </h1>
      <b-table :data="data">
        <b-table-column
          v-slot="props"
          field="COLLECT_DATE"
          :label="$t('consignee.collections.estimated_pickup_date')"
        >
          {{ props.row.COLLECT_DATE }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="COLLECT_ID"
          :label="$t('consignee.collections.collect_id')"
        >
          {{ props.row.COLLECT_ID }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="SHIPMENT_NR"
          :label="$t('consignee.collections.shipment_reference')"
        >
          {{ props.row.SHIPMENT_NR }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="FORWARDER_NAME"
          :label="$t('consignee.collections.forwarder_name')"
        >
          {{ props.row.FORWARDER_NAME }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="MATNR"
          :label="$t('consignee.collections.materials')"
        >
          {{ props.row.MATNR }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="QTY"
          :label="$t('consignee.collections.quantity')"
          numeric
        >
          {{ props.row.QTY.slice(0, props.row.QTY.indexOf('.')) }}
        </b-table-column>
      </b-table>
    </div>
  </section>
</template>

<script>
import Enduser from '@/services/v2/enduser'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      data: [],
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      selectedCustomer: state => state.selectedCustomer
    })
  },
  watch: {
    selectedCustomer (newValue, oldValue) {
      this.getData()
    }
  },
  mounted: async function () {
    this.getData()
  },
  methods: {
    async getData () {
      this.isLoading = true
      this.data = await Enduser.getCollections(this.selectedCustomer)
      this.isLoading = false
    }
  }
}
</script>
