<template>
  <div>
    <b-loading v-model="isLoading" :is-full-page="false"/>
    <h1 v-if="stockScan.length !== 0" class="title">
      {{ stockScan[0].CUSTOMER_NAME }}
    </h1>
    <h1 v-if="stockScan.length !== 0" class="subtitle">
      {{ stockScan[0].CUSTOMER_COUNTRY }}
      <img style="max-height: 15px;" :src="`https://flagcdn.com/w20/${stockScan[0].CUSTOMER_COUNTRY.toLowerCase()}.png`" alt="english flag">
      {{ stockScan[0].CUSTOMER_CITY }}
      {{ stockScan[0].CUSTOMER_ADDRESS }}
    </h1>
    <b-table
      :data="stockScan"
      :per-page="15"
      paginated
      default-sort="BARCODE"
      default-sort-direction="asc"
    >
      <b-table-column
        v-slot="props"
        field="BARCODE"
        :label="$t('consignee.stock.details.barcodes')"
        searchable
      >
        {{ props.row.BARCODE }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="ON_SITE_ELAPSED_DAYS"
        sortable
        :label="$t('consignee.stock.details.storage_time')"
      >
        {{ props.row.ON_SITE_ELAPSED_DAYS }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="ON_SITE_TIMESTAMP"
        :label="$t('consignee.stock.details.arrival_on_site')"
        searchable
      >
        {{ props.row.ON_SITE_TIMESTAMP }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import Enduser from '@/services/v2/enduser'
import { mapState } from 'vuex'

export default {
  name: 'stock-detail',
  data: function () {
    return {
      stockScan: [],
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      selectedCustomer: state => state.selectedCustomer
    })
  },
  watch: {
    selectedCustomer (newValue, oldValue) {
      this.$router.push({
        path: '/enduser/stock'
      })
    }
  },
  mounted: async function () {
    this.getData()
  },
  methods: {
    async getData () {
      this.isLoading = true
      this.stockScan = await Enduser.stockScanDetail(this.selectedCustomer, this.$route.query.customerId)
      this.isLoading = false
    }
  }
}
</script>
