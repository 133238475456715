<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns">
          <div class="column is-one-third is-offset-one-third">
            <div id="login-card" class="card">
              <div class="card-content has-text-centered">
                <h1 class="title is-1 has-text-info">
                  Forgot Password
                </h1>
                <h2 class="subtitle is-6 has-text-grey">
                  Please enter your email address or username. You will receive a link to create a new password via email.
                </h2>

                <!-- <img src="@/assets/gps_logo.png" alt="A remplir" width="400px" class="text-center"> -->
                <ValidationObserver v-slot="{ invalid }">
                  <form id="login" @submit.prevent="sendMail()">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      slim
                      rules="required"
                      name="Password"
                    >
                      <b-field :type="{ 'is-danger': errors[0], 'is-success': valid }">
                        <b-input
                          ref="email"
                          v-model="creditential"
                          rounded
                          name="username"
                          placeholder="Username or E-mail"
                          type="text"
                          icon="user"
                        />
                      </b-field>
                    </ValidationProvider>
                    <b-button
                      type="is-info"
                      rounded
                      :loading="isLoading"
                      :disabled="invalid"
                      expanded
                      native-type="submit"
                    >
                      Send Request
                    </b-button>
                  </form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      creditential: '',
      isLoading: false
    }
  },
  mounted () {
    this.$refs.email.focus()
  },
  methods: {
    async sendMail () {
      await this.$http.post(process.env.VUE_APP_API_URL + '/auth/forgot-password', {
        username: this.creditential
      })
      this.$buefy.toast.open({
        message: 'An email containing the instructions to reset your password has been sent.',
        type: 'is-primary',
        duration: 8000
      })
      this.$router.push('/login')
    }
  }
}
</script>

<style>

</style>
