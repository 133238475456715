import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/shippingManagement'

const shippingManagement = axios.create({
  baseURL
})

interceptorsSetup(shippingManagement)

export default class ShippingManagement {
  static async getShippings (customerId) {
    const { data: result } = await shippingManagement.get(`/shippings/${customerId}`)
    return result
  }

  static async getCrateMovements (shippingId) {
    const { data: result } = await shippingManagement.get(`/crateMovements/${shippingId}`)
    return result
  }

  static async getCrateMovementsByCustomerId (customerId) {
    const { data: result } = await shippingManagement.get(`/crateMovements/byCustomerId/${customerId}`)
    return result
  }

  static async getCrates () {
    const { data: result } = await shippingManagement.get(`/crates`)
    return result
  }

  static async updateShipping (shipping, crateMovements) {
    let data = {
      'shipping': shipping,
      'crateMovements': crateMovements
    }
    const result = await shippingManagement.put(`/shippings`, data)
    return result.data
  }

  static async uploadEndusers (customerId, category, file) {
    let formData = new FormData()
    formData.append('file', file)
    formData.append('customerId', customerId)
    formData.append('fileCategoryId', category)
    const result = await shippingManagement.post(`/upload/endusers`, formData, { headers: {
      'Content-Type': 'multipart/form-data'
    } })
    return result.data
  }

  static async createShipping (customerId, data) {
    data.customerId = customerId
    const result = await shippingManagement.post(`/shippings`, data)
    return result.data
  }

  static async manualValidate (data) {
    const result = await shippingManagement.post(`/shippings/validation`, data)
    return result.data
  }

  static async deleteShipping (shippingId) {
    const { data: result } = await shippingManagement.delete(`/shippings/${shippingId}`)
    return result
  }

  static async createPendingEnduser ({ customerId, enduser, endusers }) {
    const result = await shippingManagement.post(`/pending_enduser`, { 'customerId': customerId, 'enduser': enduser, 'endusers': endusers })
    return result.data
  }

  static async getPendingEnduser (customerId) {
    const result = await shippingManagement.get(`/pending_enduser/${customerId}`)
    return result.data
  }

  static async getCustomerFiles (customerId, category) {
    const result = await shippingManagement.get(`/customer_file/get/${customerId}/${category}`)
    return result.data
  }

  static async deleteCustomerFile (id) {
    const result = await shippingManagement.delete(`/customer_file/${id}`)
    return result.data
  }

  static async downloadCustomerFile (fileId) {
    const { data: file } = await shippingManagement.get('/customer_file/download/' + fileId, {
      responseType: 'blob'
    })
    return file
  }

  static async updateShippingsEnduser (shippings) {
    const result = await shippingManagement.post(`/shippings/update_enduser`, shippings)
    return result.data
  }

  static async downloadTemplate () {
    const result = await shippingManagement.get('/download/import_consignees_template', {
      responseType: 'blob'
    })
    return result.data
  }
}
