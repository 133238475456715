<template>
  <div v-if="isLoading">
    <b-loading :active.sync="isLoading" is-full-page/>
  </div>
  <div v-else class="container">
    <div class="languageSwitcher">
      <language-switcher/>
    </div>
    <div v-if="!errorMessage" class="container is-fluid">
      <div class="columns hero is-primary is-centered">
        <div class="column section">
          <h1 class="title is-2 is-primary has-text-centered">
            {{ $t('consignee.consignee_declaration.welcome') }}
          </h1>
          <p class="has-text-centered">
            {{ declaration_mail.enduserName }}
          </p>
          <p class="has-text-centered">
            {{ `${declaration_mail.address || ''} ${declaration_mail.city || ''} ${declaration_mail.country || ''} ${declaration_mail.postalCode || ''}` }}
          </p>
        </div>
      </div>
      <div class="has-text-centered mt-5">
        <b-button type="is-info" @click="downloadPDF">
          {{ $t('consignee.consignee_declaration.crates_identification') }}
        </b-button>
      </div>
      <div v-if="!submited" class="container">
        <ValidationObserver v-slot="{ invalid }">
          <div class="columns is-multiline  section is-centered">
            <div class="column is-full">
              <h2 class="has-text-centered title is-4">
                {{ $t('consignee.consignee_declaration.title') }}
              </h2>
              <p class="has-text-centered">
                {{ $t('consignee.consignee_declaration.sub-title') }}
              </p>
            </div>
            <div v-for="dc of declarationQuantities" :key="dc.material" class="column">
              <div class="card has-text-centered">
                <div class="card-header is-flex is-flex-direction-row is-justify-content-space-around is-align-items-center">
                  <span class="card-header-title">
                    {{ dc.material }}
                  </span>

                  <span
                    v-if="dc.material === 'GPS52' || dc.material === 'GPS55'"
                    class="pr-2"
                  >

                    <b-tooltip position="is-bottom" multilined>
                      <b-button
                        icon-left="info"
                        type="is-primary"
                        size="is-small"
                        rounded
                        @click="showModal(dc.material)"
                      />

                      <template v-slot:content>
                        <b-image
                          :src="getImgSrc(dc.material)"
                          :alt="`${dc.material} note`"
                        />
                        <strong>Click to see more</strong>
                      </template>
                    </b-tooltip>

                  </span>
                </div>
                <span v-if="matLibelles.find(row => {return row.MATNR === dc.material})">{{ ' (' + matLibelles.find(row => {return row.MATNR === dc.material}).MAKTX + ')' }}</span>
                <div class="card-content">
                  <ValidationProvider v-slot="{errors}" slim :rules="{required: true, maxInput: true, minInput: true}">
                    <b-input
                      v-model="dc.quantity"
                      min="0"
                      :name="'quantity' + dc.material"
                      type="number"
                    />
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <b-modal :active.sync="isModalVisible" @close="hideModal">
              <p class="image">
                <img :src="getImgSrc(currentMaterial)">
              </p>
            </b-modal>

            <div class="column is-full">
              <div class="card has-text-centered">
                <div class="card-header">
                  <span class="card-header-title">
                    {{ $t('consignee.consignee_declaration.comment') }}
                  </span>
                </div>
                <div class="card-content">
                  <textarea v-model="declaration.comments" placeholder="" class="textarea"/>
                </div>
              </div>
            </div>
            <div class="column is-full">
              <div class="card has-text-centered">
                <div class="card-header">
                  <span class="card-header-title">
                    {{ $t('consignee.consignee_declaration.identity') }}
                  </span>
                </div>
                <div class="card-content">
                  <ValidationProvider v-slot="{errors}" slim :rules="{required: true}">
                    <b-input v-model="declaration.identity" placeholder="" :name="'identity'"/>
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="column has-text-centered is-full">
              <button
                :disabled="invalid"
                class="button is-primary"
                @click="submit"
              >
                {{ $t('consignee.consignee_declaration.submit') }}
              </button>
            </div>
          </div>
        </ValidationObserver>
      </div>
      <div v-else class="container">
        <div class="section columns has-text-centered">
          <div class="column">
            <h1 class="has-text-primary title is-4">
              {{ $t('consignee.consignee_declaration.declaration_sent.title') }}
            </h1>
            <span>
              {{ $t('consignee.consignee_declaration.declaration_sent.content1') }}
            </span>
            <div class="section">
              <button
                :loading="downloadingPdf"
                class="button is-info"
                @click="getPdfReport()"
              >
                {{ $t('consignee.consignee_declaration.declaration_sent.download_declaration') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="!askedNewMail">
        <div class="container is-fluid">
          <div class="columns hero is-danger is-centered">
            <div class="section has-text-centered">
              <h1 v-if="declaration_mail && declaration_mail.declarationId" class="title is-3">
                {{ $t('consignee.consignee_declaration.token_used') }}
              </h1>
              <h1 v-else class="title is-3">
                {{ $t('consignee.consignee_declaration.something_went_wrong') }}
              </h1>
            </div>
          </div>
          <br>
          <div class="section has-text-centered">
            <span v-if="!(declaration_mail && declaration_mail.declarationId)">
              {{ errorMessage }}
            </span>
            <br>
            <div v-if="invalidToken">
              <span v-if="declaration_mail && declaration_mail.declarationId">
                {{ this.$t('consignee.consignee_declaration.token_used_message') }}
              </span>
              <span v-else>
                {{ this.$t('consignee.consignee_declaration.ask_new_mail_message') }}
              </span>
              <br>
              <br>
              <button
                class="button is-info"
                @click="askNewMail"
              >
                {{ this.$t('consignee.consignee_declaration.ask_new_mail_button') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="container is-fluid">
          <div class="columns hero is-primary is-centered">
            <div class="section has-text-centered">
              <h1 class="title is-3">
                {{ $t('global.success') }}
              </h1>
            </div>
          </div>
          <br>
          <div class="section has-text-centered">
            {{ this.$t('consignee.consignee_declaration.new_email_sent') }}
            <br>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EnduserDeclaration from '@/services/v2/enduserDeclaration'
import LanguageSwitcher from '../../components/common/languageSwitcher.vue'
import languageHelper from '../../mixins/misc'
import { saveAs } from 'file-saver'

export default {
  components: {
    LanguageSwitcher
  },
  data () {
    return {
      isModalVisible: false,
      currentMaterial: '',
      declaration_mail: null,
      declaration: Object,
      submited: false,
      token: this.$route.query.token,
      materials: [],
      declarationQuantities: [],
      isLoading: true,
      errorMessage: '',
      downloadingPdf: false,
      matLibelles: null,
      invalidToken: false,
      askedNewMail: false
    }
  },
  computed: {
  },
  mounted: async function () {
    try {
      this.isLoading = true
      this.declaration_mail = await EnduserDeclaration.get(this.token)
      this.$i18n.locale = languageHelper.methods.getLangShortName(this.declaration_mail.lang) || 'en'
      if (this.declaration_mail.declarationId) {
        // means that a declaration already exist for this token
        throw Error('Invalid token')
      }
      this.materials = await EnduserDeclaration.getMaterials(this.token, this.declaration_mail.enduserCode)
      const dqTemp = []
      this.materials.map((m) => {
        if (m.MATERIAL === 'GPS52') {
          dqTemp.push({
            'material': 'GPS52',
            'quantity': 0
          },
          {
            'material': 'GPS55',
            'quantity': 0
          })
        } else {
          dqTemp.push(
            {
              'material': m.MATERIAL,
              'quantity': 0
            }
          )
        }
      })
      this.declarationQuantities = dqTemp.reduce((acc, cur) => {
        const x = acc.find(item => item.material === cur.material)
        if (!x) {
          return [...acc, cur]
        } else {
          return acc
        }
      }, [])
      this.declaration = {
        'id': this.declaration_mail.declarationId,
        'enduserCode': this.declaration_mail.enduserCode,
        'enduserName': this.declaration_mail.enduserName,
        'declarationMailId': this.declaration_mail.id,
        'comments': '',
        'identity': ''

      }
      this.matLibelles = await EnduserDeclaration.getMatLibelles(this.token)
      this.isLoading = false
    } catch (error) {
      if (error.message === 'Invalid token') {
        this.invalidToken = true
        this.errorMessage = this.$t('consignee.consignee_declaration.invalid_token_message')
      } else {
        this.errorMessage = error.message
      }
      this.isLoading = false
    }
  },
  methods: {
    sendDeclaration: async function () {
      try {
        await EnduserDeclaration.post(this.token, this.declaration, this.declarationQuantities)
        this.submited = true
      } catch (error) {
        this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
      }
    },
    submit: async function () {
      try {
        let total = 0.0
        this.declarationQuantities.forEach(function (dc) {
          dc.quantity = parseInt(dc.quantity, 10)
          total += parseInt(dc.quantity)
        })
        this.$buefy.dialog.confirm({
          title: this.$t('consignee.consignee_declaration.modal.title'),
          message: this.$t('consignee.consignee_declaration.modal.content1') + ` <strong>${total}</strong> ` + this.$t('consignee.consignee_declaration.modal.content2'),
          confirmText: this.$t('consignee.consignee_declaration.modal.yes'),
          cancelText: this.$t('consignee.consignee_declaration.modal.no'),
          type: 'is-info',
          hasIcon: true,
          onConfirm: async () => {
            this.sendDeclaration()
          }
        })
      } catch (error) {
        this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
      }
    },
    getPdfReport: async function () {
      try {
        this.downloadingPdf = true
        await EnduserDeclaration.getPdfReport(this.token)
      } catch (error) {
        this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
      }
      this.downloadingPdf = false
    },
    askNewMail: async function () {
      try {
        await EnduserDeclaration.askNewMail(this.token)
        this.askedNewMail = true
      } catch (error) {
        this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
      }
    },
    showModal (material) {
      this.currentMaterial = material
      this.isModalVisible = true
    },
    hideModal () {
      this.isModalVisible = false
      this.currentMaterial = ''
    },
    getImgSrc (material) {
      if (material) {
        return require(`@/assets/doc/${material}-note.png`)
      } else {
        return ''
      }
    },
    async downloadPDF () {
      const result = await EnduserDeclaration.getCrateDifferencePdf()
      const blob = new Blob([result], { type: 'application/pdf' })
      saveAs(blob, 'GPS_crates_note.pdf')
    }
  }
}
</script>

<style>
/* .body {
    max-width: 100%;
    overflow-x: hidden;
} */

.languageSwitcher {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 20px;
}

</style>
