import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/distributorDeclaration'

const distributorDeclaration = axios.create({
  baseURL
})

interceptorsSetup(distributorDeclaration)

export default class DistributorDeclaration {
  static async getDistributor (id) {
    const { data: result } = await distributorDeclaration.get(`/distributor/${id}`)
    return result
  }

  static async getReminder (token) {
    const { data: result } = await distributorDeclaration.get(`/reminder/${token}`)
    return result
  }

  static async getTemplate () {
    const { data: result } = await distributorDeclaration.get('/download-template', {
      responseType: 'blob'
    })
    return result
  }

  static async upload (distributorCode, reminderId, file) {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('reminderId', reminderId)
    const { data: result } = await distributorDeclaration.post(`/upload-file/${distributorCode}`, formData, { headers: {
      'Content-Type': 'multipart/form-data'
    } })
    return result
  }
}
