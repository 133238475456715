<template>
  <div class="section">
    <img src="@/assets/gps_logo.png" alt="Main logo" height="400">
  </div>
</template>

<script>

export default {
  name: 'home',
  data () {
    return {}
  },
  methods: {
  }
}
</script>

<style>

</style>
