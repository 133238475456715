import store from '@/store'

export default function setup (axiosInstance) {
  axiosInstance.interceptors.request.use(function (config) {
    let baseRoute = config.baseURL.split('/')
    baseRoute = baseRoute[baseRoute.length - 1]

    // Do something before request is sent
    if (store.state.auth.token && baseRoute !== 'enduserDeclaration' && baseRoute !== 'DistributorDeclaration') {
      config.headers['Authorization'] =
      'Bearer ' + store.state.auth.token
    }
    return config
  }, function (error) {
    // Do something with request error
    return Promise.reject(error)
  })

  axiosInstance.interceptors.response.use(undefined, function (err) {
    if (err.response && err.response.status === 401) {
      // if you ever get an unauthorized, logout the user
      window.location = '/login'
    } else if (err.response && err.response.status === 404) {
      return Promise.reject(new Error('Resource not found.'))
    } else {
      if (err && err.response && err.response.data) {
        return Promise.reject(new Error(err.response.data.message))
      }
      return Promise.reject(new Error(err))
    }
  })
}
