import MapPage from '@/views/Map/'
import IotMap from '@/views/IotMap'
import HomePage from '@/views/Home/home-page'

import LoginPage from '@/views/Auth/login-page'

import Main from '@/views/Layout'

import ProfilePage from '@/views/Profile/profile-page'
import SettingsPage from '@/views/Settings/settings-page'
import ResetPassword from '@/views/Auth/reset-password'
import ForgetPassword from '@/views/Auth/forget-password'

import PageNotFound from '@/views/NotFound/not-found-page'

import EnduserDeclaration from '@/views/enduserDeclaration/enduserDeclaration'
import DistributorDeclaration from '@/views/distributorDeclaration/distributorDeclaration'

import customerRoutes from './customer-routes'
import enduserRoutes from './enduser-routes'

export const routes = [
  // basic routes
  {
    path: '/login',
    component: LoginPage,
    name: 'login',
    meta: { title: 'Login' }
  },
  {
    path: '/consignee-declaration',
    component: EnduserDeclaration,
    meta: { title: 'Consignee declaration' }
  },
  {
    path: '/distributor-declaration',
    component: DistributorDeclaration,
    meta: { title: 'Distributor declaration' }
  },
  { path: '*',
    component: PageNotFound,
    meta: { title: 'Oups...' }
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    meta: { title: 'Password Update' }
  },
  {
    path: '/forget-password',
    component: ForgetPassword,
    meta: { title: 'ForgetPassword' }
  },
  // shared auth routes
  {
    path: '/',
    component: Main,
    meta: { },
    children: [
      {
        path: '',
        component: HomePage,
        name: 'home',
        meta: { title: 'Home', authorize: [] }
      },
      {
        path: '/maps',
        component: MapPage,
        name: 'maps',
        meta: { title: 'Maps', authorize: ['Map access'] }
      },
      {
        path: '/iotMap',
        component: IotMap,
        name: 'iotMap',
        meta: { title: 'IoT Map', authorize: ['IotMap access'] }
      },
      {
        path: '/profile',
        component: ProfilePage,
        name: 'profile',
        meta: { title: 'Profile', authorize: [] }
      },
      {
        path: '/settings',
        component: SettingsPage,
        name: 'settings',
        meta: { title: 'Settings', authorize: [] }
      },
      ...customerRoutes,
      ...enduserRoutes
    ]
  }

]
