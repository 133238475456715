<template>
  <div class="modal-card" style="width: auto; min-height: 80vh">
    <header class="modal-card-head">
      <p class="modal-card-title">
        All references
      </p>
    </header>
    <section class="modal-card-body" style="background-color: white;">
      <div class="mb-5">
        <multiselect
          v-model="selectedSi"
          placeholder="Select a reference..."
          :options="siData"
          :custom-label="siWithCustomerId"
          track-by="si"
          label="si"
          @input="selectSI"
        />
      </div>

      <div v-if="selectedData">
        <div class="mb-3 ml-2">
          Relevant crates in progress : {{ selectedData.length }}
        </div>
        <b-table
          :data="selectedData"
          paginated
          :per-page="perPage"
          mobile-cards
          hoverable
          :row-class="(row, index) => 'is-clickable'"
          @click="clickRow"
        >
          <b-table-column
            field="barcode"
            label="Barcode"
            searchable
          >
            <template
              #searchable="props"
            >
              <b-input
                v-model="props.filters[props.column.field]"
                placeholder="Search barcode..."
                size="is-small"
              />
            </template>
            <template v-slot="props">
              {{ props.row.barcode }}
            </template>
          </b-table-column>
          <b-table-column
            v-slot="props"
            field="lastSeenBattery"
            label="Battery"
            centered
            sortable
          >
            <span><b-icon
                    v-if="props.row.lastSeenBattery"
                    class="mr-1"
                    pack="fas"
                    :icon="props.row.lastSeenBattery > 90 ? 'battery-full' :
                      (props.row.lastSeenBattery > 70 ? 'battery-three-quarters':
                        (props.row.lastSeenBattery > 50 ? 'battery-half':
                          (props.row.lastSeenBattery > 20 ? 'battery-quarter' :'battery-empty'))) "
                    size="is-small"
                    :type="props.row.lastSeenBattery === 0? 'is-danger': ''"
                  />
              {{ props.row.lastSeenBattery ? props.row.lastSeenBattery + '%' : 'Unknown' }}</span>
          </b-table-column>
          <b-table-column v-slot="props" field="timestamp" label="Timestamp" sortable>
            {{ new Date(props.row.timestamp).toLocaleString() }}
          </b-table-column>
          <b-table-column v-slot="props" label="Status">
            <span><b-icon
                    class="mr-1"
                    pack="fas"
                    icon="circle"
                    size="is-small"
                    :type="props.row.locationMethod === 'sc' ? 'is-primary' : 'is-info'"
                  />
              {{ getStatus(props.row) }}</span>
          </b-table-column>
        </b-table>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    siData: {
      default: () => [],
      type: Array
    }
  },
  data () {
    return {
      selectedSi: null,
      selectedData: null,
      perPage: 10
    }
  },
  mounted () {
  },
  methods: {
    selectSI () {
      if (!this.selectedSi) return
      this.selectedData = this.selectedSi.barcodes
    },
    siWithCustomerId ({ si, barcodes }) {
      if (si === 'Unassigned') {
        return si
      } else {
        return `${barcodes[0].customerName} - ${si}`
      }
    },
    getStatus (data) {
      if (data.type === 'PLANT') {
        switch (data.locationMethod) {
          case 'sd':
            return 'Sent out from GPS plant'
          case 'sc':
            return 'Collected'
          default:
            return 'At GPS plant'
        }
      } else if (data.type === 'NATURAL PRODUCER') {
        return 'At producer'
      } else if (data.type === 'PORT') {
        return 'At port'
      } else if (data.type === 'ENDUSER') {
        return 'At Enduser'
      } else if (data.type === 'CUSTOMER') {
        return 'At Customer'
      } else if (data.type === 'DISTRIBUTOR') {
        return 'At Distributor'
      } else if (data.type === 'CUSTOMER WAREHOUSE') {
        return 'At Customer warehouse'
      } else {
        return 'In transportation'
      }
    },
    clickRow (row) {
      this.$emit('onSelectRow', row)
    }
  }
}
</script>

<style>
.is-clickable{
  cursor: pointer;
}
</style>
