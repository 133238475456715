import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/endusers'

const endusers = axios.create({
  baseURL
})

interceptorsSetup(endusers)

export default class Enduser {
  static async getAll () {
    const result = await endusers.get('/')
    return result.data
  }

  static async findOne (enduserId) {
    const result = await endusers.get('/' + enduserId)
    return result.data
  }

  static async stock (enduserId) {
    const result = await endusers.get('/stock/' + enduserId)
    return result.data
  }
  static async stockScan (enduserId) {
    const result = await endusers.get('/stock/scan/' + enduserId)
    return result.data
  }
  static async stockScanDetail (enduserId, customerId) {
    const result = await endusers.get('/stock/scan/' + enduserId + '/detail/' + customerId)
    return result.data
  }
  static async getCollections (enduserId) {
    const result = await endusers.get('/collections/' + enduserId)
    return result.data
  }

  static async byConsigneeType (customerId) {
    const result = await endusers.get(`/consigneeType/${customerId}`)
    return result.data
  }
}
