const markerUrl = 'https://cdn.mapmarker.io/api/v1/pin?size=50&icon=fa-star&background='

const markerTypes = new Map()
markerTypes.set('enduser', {
  type: 'enduser',
  color: '90A4AE',
  label: 'Enduser'
})

markerTypes.forEach(element => {
  element.markerIcon = markerUrl + element.color
})

export {
  markerUrl,
  markerTypes
}
