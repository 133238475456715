<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-mobile is-centered">
          <div class="column column is-half is-narrow">
            <h1 class="title is-1">
              404 <b-icon icon="exclamation" size="is-small"/>
            </h1>
            <h2 class="subtitle is-3">
              Not found...
            </h2>
            <div class="content">
              <p>The site configured at this address does not contain the requested file. <code>{{ $route.path }}</code>.</p>
              <p>You may have typed the address incorrectly or you may have used an outdated link.<br> <b>You will be redirected to the main page.</b></p>
              <template>{{ now }}</template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      now: 10
    }
  },
  mounted: function () {
    let timer = window.setInterval(() => {
      this.now -= 1
      if (this.now === 0) {
        clearInterval(timer)
        this.$router.push('/')
      }
    }, 1000)
  }
}
</script>

<style>
</style>
