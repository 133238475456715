import 'whatwg-fetch'

import Vue from 'vue'
import store from './store'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import Buefy, { NotificationProgrammatic as Notification } from 'buefy'

import './vee-validate'

import dayjs from 'dayjs'
// import dayjsPluginUTC from 'dayjs-plugin-utc'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import VueGtag from 'vue-gtag'

import axios from 'axios'
import lodash from 'lodash'
import i18n from './i18n'
import VueCookies from 'vue-cookies'

import { version } from '../package.json'

import VueApollo from 'vue-apollo'

import * as gql from 'gql-query-builder'

// STYLE IMPORT
import 'leaflet/dist/leaflet.css'
import 'leaflet-geosearch/assets/css/leaflet.css'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import '@fortawesome/fontawesome-free/css/fontawesome.css'
import './assets/sass/main.scss'
import './assets/main.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css'
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css'
import '@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css'

import { LicenseManager } from '@ag-grid-enterprise/core'

import { ApplicationInsights } from '@microsoft/applicationinsights-web'

import apolloClient from './plugins/apolloClient'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

import VueTour from 'vue-tour'
import 'vue-tour/dist/vue-tour.css'

// dayjs.extend(dayjsPluginUTC)
dayjs.extend(isSameOrBefore)
dayjs.extend(utc)
dayjs.extend(timezone)

Vue.prototype.appVersion = version
Vue.prototype.$dayjs = dayjs
Vue.prototype.$http = axios
Vue.prototype.$lodash = lodash
Vue.prototype.$gql = gql

Vue.config.productionTip = false

const appInsights = new ApplicationInsights({ config: {
  instrumentationKey: process.env.VUE_APP_INSIGHTS,
  enableDebug: process.env.NODE_ENV === 'development',
  enableAutoRouteTracking: true
} })

if (process.env.NODE_ENV === 'production') {
  appInsights.loadAppInsights()

  Vue.use(VueGtag, {
    config: { id: 'UA-107355013-2' }
  })
  Vue.$gtag.set('userId', store.state.auth.user?.displayName)
}

Vue.use(VueCookies)
Vue.use(Buefy, {
  defaultIconPack: 'fas'
})

Vue.use(VueTour)

const globalErrorHandler = (err) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error(err)
  }

  appInsights.trackException({
    error: err
  })

  Notification.open({
    duration: 5000,
    message: 'Oups, something went wrong 😕',
    position: 'is-bottom-right',
    type: 'is-danger',
    hasIcon: true
  })
}

Vue.config.errorHandler = (err) => {
  globalErrorHandler(err)
}

window.onerror = function (message, source, lineno, colno, err) {
  globalErrorHandler(err)
}

LicenseManager.setLicenseKey('Groupe_GPS_SAS_MultiApp_1Devs17_October_2020__MTYwMjg4OTIwMDAwMA==3ae6a63c55c98c7010fe66f4278a44d0')

axios.defaults.headers.common.User = store.state.auth.user?.displayName

Vue.use(VueApollo)

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})

new Vue({
  router,
  store,
  apolloProvider,
  i18n,
  render: h => h(App)
}).$mount('#app')
