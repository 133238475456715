<template>
  <div>
    <template>
      <div v-for="[key, item] of markerTypes" :key="'legend-' + item.type">
        <img :src="item.markerIcon">
        <span>{{ item.label }}</span>
      </div>
    </template>
  </div>
</template>

<script>
import { markerTypes } from './mTypes'

export default {
  name: 'map-legend',
  data: function () {
    return {
      markerTypes: Array.from(markerTypes)
    }
  }
}
</script>
