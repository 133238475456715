<template>
  <div>
    <b-loading v-model="isLoading" :is-full-page="false"/>
    <h1 class="title">
      {{ $t('consignee.stock.summary.estimated_stock_in_volume') }}
    </h1>
    <b-table :data="stock">
      <b-table-column
        v-slot="props"
        field="CUSTOMER_NAME"
        :label="$t('consignee.stock.summary.customer')"
      >
        {{ props.row.CUSTOMER_NAME }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="CUSTOMER_COUNTRY"
        :label="$t('consignee.stock.summary.country')"
      >
        <img style="max-height: 15px;" :src="`https://flagcdn.com/w20/${props.row.CUSTOMER_COUNTRY.toLowerCase()}.png`" alt="english flag"> {{ props.row.CUSTOMER_COUNTRY }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="CUSTOMER_CITY"
        :label="$t('consignee.stock.summary.city')"
      >
        {{ props.row.CUSTOMER_CITY }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="CUSTOMER_ADDRESS"
        :label="$t('consignee.stock.summary.address')"
      >
        {{ props.row.CUSTOMER_NAME }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="IN_STOCK"
        :label="$t('consignee.stock.summary.stock')"
        numeric
      >
        {{ props.row.IN_STOCK }}
      </b-table-column>
    </b-table>
    <h1 class="title second">
      {{ $t('consignee.stock.summary.estimated_stock_scanned') }}
    </h1>

    <b-table :data="stockScan">
      <b-table-column
        v-slot="props"
        field="CUSTOMER_NAME"
        :label="$t('consignee.stock.summary.customer')"
      >
        {{ props.row.CUSTOMER_NAME }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="CUSTOMER_COUNTRY"
        :label="$t('consignee.stock.summary.country')"
      >
        <img style="max-height: 15px;" :src="`https://flagcdn.com/w20/${props.row.CUSTOMER_COUNTRY.toLowerCase()}.png`" alt="english flag"> {{ props.row.CUSTOMER_COUNTRY }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="CUSTOMER_CITY"
        :label="$t('consignee.stock.summary.city')"
      >
        {{ props.row.CUSTOMER_CITY }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="CUSTOMER_ADDRESS"
        :label="$t('consignee.stock.summary.address')"
      >
        {{ props.row.CUSTOMER_ADDRESS }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="COUNT_BARCODES"
        :label="$t('consignee.stock.summary.barcodes')"
      >
        {{ props.row.COUNT_BARCODES }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="AVG_ON_SITE_ELAPSED_DAYS"
        :label="$t('consignee.stock.summary.average_on_site_days')"
      >
        {{ props.row.AVG_ON_SITE_ELAPSED_DAYS.slice(0, props.row.AVG_ON_SITE_ELAPSED_DAYS.indexOf('.')) }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="CUSTOMER"
        :label="$t('consignee.stock.summary.detail')"
      >
        <b-button
          size="is-small"
          type="is-link"
          icon-left="barcode"

          @click="goToDetail(props.row.CUSTOMER)"
        />
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import Enduser from '@/services/v2/enduser'
import { mapState } from 'vuex'

export default {
  name: 'stock',
  components: {

  },
  data () {
    return {
      stock: [],
      stockScan: [],
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      selectedCustomer: state => state.selectedCustomer
    })
  },
  watch: {
    selectedCustomer (newValue, oldValue) {
      this.getData()
    }
  },
  mounted: async function () {
    this.getData()
  },
  methods: {
    async getData () {
      this.isLoading = true
      this.stock = await Enduser.stock(this.selectedCustomer)
      this.stockScan = await Enduser.stockScan(this.selectedCustomer)
      this.isLoading = false
    },
    goToDetail (customerId) {
      this.$router.push({
        path: 'stock/detail',
        query: {
          customerId: customerId
        }
      })
    }
  }
}
</script>

<style scoped>
.second {
  margin-top: 30px;
}
</style>
