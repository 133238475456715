<template>
  <b-field>
    <p class="control">
      <b-button icon-left="save" :disabled="profiles.length === 0" @click="saveProfile">
        {{ $t('global.save_grid') }}
      </b-button>
    </p>
    <p class="control">
      <span class="button is-static">{{ selectedProfile ? selectedProfile.profileName: 'None' }}</span>
    </p>
    <p class="control">
      <b-dropdown ref="gridDropdown">
        <b-button slot="trigger" icon-left="chevron-down"/>
        <b-dropdown-item aria-role="menu-item" :focusable="false" custom paddingless>
          <div v-show="!isEditing" class="modal-card" style="width:300px;">
            <header class="modal-card-head">
              COLUMNS VIEWS
            </header>
            <section class="modal-card-body is-paddingless">
              <b-menu>
                <ul v-if="profiles.length" class="menu-list">
                  <li v-for="profile in profiles" :key="profile.profileNamef">
                    <a :class="{'is-active': activeProfile && profile.profileName === activeProfile.profileName}" @click.self="setActive(profile)">
                      {{ profile.profileName }}
                      <b-icon class="is-pulled-right" icon="cog" @click.native.prevent="setEditing(profile)"/>
                    </a>
                  </li>
                </ul>
                <p v-else>
                  No profiles yet
                </p>
              </b-menu>
            </section>
            <footer class="modal-card-foot">
              <div class="buttons">
                <b-button icon-left="plus" @click="newProfile">
                  New Profile
                </b-button>
                <b-button type="is-primary" icon-left="check" @click="validate">
                  Validate
                </b-button>
              </div>
            </footer>
          </div>
          <div v-show="isEditing" class="modal-card" style="width:300px;">
            <header class="modal-card-head">
              <b-field>
                <b-input v-model="editingProfile.newProfileName"/>
                <p class="control">
                  <b-button type="is-primary" icon-left="save" @click="updateProfile"/>
                </p>
                <p class="control">
                  <b-button type="is-danger" icon-left="trash" @click="deleteProfile"/>
                </p>
              </b-field>
            </header>
            <section class="modal-card-body is-paddingless"/>
            <footer class="modal-card-foot">
              <b-button icon-left="check" type="is-primary" @click="isEditing=false">
                Done
              </b-button>
            </footer>
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </p>
  </b-field>
</template>
<script>

export default {
  props: {
    profiles: { type: Array, required: true }
  },
  data: function () {
    return {
      isEditing: false,
      editingProfile: {},
      activeProfile: null
    }
  },

  computed: {
    selectedProfile: function () {
      return this.profiles.find(e => e.isSelected)
    }
  },
  watch: {
    profiles: function (newVal, oldVal) { // watch it
      if (oldVal.length === 0) {
        this.activeProfile = newVal.find(e => e.isSelected)
      }
    }
  },
  methods: {
    setActive: function (profile) {
      this.activeProfile = profile
    },
    setEditing: function (profile) {
      this.isEditing = true
      this.editingProfile = { ...profile }
      this.editingProfile.newProfileName = profile.profileName
    },
    validate: async function () {
      this.$refs.gridDropdown.toggle()
      await this.$emit('select', this.activeProfile)
    },
    saveProfile: async function () {
      await this.$emit('save', this.selectedProfile)
    },
    newProfile: async function () {
      if (this.profiles.length >= 5) return
      const newProfile = { profileName: 'New profile ' + (this.profiles.length + 1) }
      await this.$emit('create', newProfile)
    },
    deleteProfile: async function () {
      if (this.profiles.length === 1) return

      await this.$emit('delete', this.editingProfile)

      this.isEditing = false
    },
    updateProfile: async function () {
      this.editingProfile.oldProfileName = this.editingProfile.profileName
      this.editingProfile.profileName = this.editingProfile.newProfileName
      delete this.editingProfile.newProfileName
      await this.$emit('update', this.editingProfile)

      this.isEditing = false
    }
  }
}
</script>
