// export const SET_DEPOTS = 'SET_DEPOTS'
// export const SET_REGIONS = 'SET_REGIONS'
// export const SET_COUNTRIES = 'SET_COUNTRIES'
export const SET_CUSTOMERS = 'SET_CUSTOMERS'
// export const SET_EDI_CUSTOMERS = 'SET_EDI_CUSTOMERS'
export const SET_END_USERS = 'SET_END_USERS'
// export const SET_CATEGORIES = 'SET_CATEGORIES'

export const SWITCH_SIDE_BAR = 'SWITCH_SIDE_BAR'
export const HIDE_SIDE_BAR = 'HIDE_SIDE_BAR'
export const SET_SELECTED_CUSTOMER = 'SET_SELECTED_CUSTOMER'

// // Stocks...
// export const SET_STOCK = 'SET_STOCK'

// // Collections
// export const SET_COLLECTIONS = 'SET_COLLECTIONS'

// // Shipings
// export const SET_SHIPPINGS = 'SET_SHIPPINGS'

// // Users
// export const UPDATE_USER = 'UPDATE_USER'
// export const SET_USERS = 'SET_USERS'
// export const ADD_USER = 'ADD_USER'
// export const REMOVE_USER = 'REMOVE_USER'

// // Groups
// export const SET_GROUPS = 'SET_GROUPS'

// export const FILTER_MARKERS = 'FILTER_MARKERS'
// export const SET_FILTER = 'SET_FILTER'

// export const CUSTOMER_FILE_SETTINGS_REQUEST = 'CUSTOMER_FILE_SETTINGS_REQUEST'
// export const CUSTOMER_FILE_SETTINGS_SUCCESS = 'CUSTOMER_FILE_SETTINGS_SUCCESS'
// export const CUSTOMER_FILE_SETTINGS_FAILURE = 'CUSTOMER_FILE_SETTINGS_FAILURE'

// export const TABLES_MOVEMENT_FIELDS_REQUEST = 'TABLES_MOVEMENT_FIELDS_REQUEST'
// export const TABLES_MOVEMENT_FIELDS_SUCCESS = 'TABLES_MOVEMENT_FIELDS_SUCCESS'
// export const TABLES_MOVEMENT_FIELDS_FAILURE = 'TABLES_MOVEMENT_FIELDS_FAILURE'

// export const TABLES_MOVEMENT_TABLES_REQUEST = 'TABLES_MOVEMENT_TABLES_REQUEST'
// export const TABLES_MOVEMENT_TABLES_SUCCESS = 'TABLES_MOVEMENT_TABLES_SUCCESS'
// export const TABLES_MOVEMENT_TABLES_FAILURE = 'TABLES_MOVEMENT_TABLES_FAILURE'

// export const CUSTOMER_EXPORT_RECAP_REQUEST = 'CUSTOMER_EXPORT_RECAP_REQUEST'
// export const CUSTOMER_EXPORT_RECAP_SUCCESS = 'CUSTOMER_EXPORT_RECAP_SUCCESS'
// export const CUSTOMER_EXPORT_RECAP_FAILURE = 'CUSTOMER_EXPORT_RECAP_FAILURE'

// export const CUSTOMER_EXPORT_DETAILS_REQUEST = 'CUSTOMER_EXPORT_DETAILS_REQUEST'
// export const CUSTOMER_EXPORT_DETAILS_SUCCESS = 'CUSTOMER_EXPORT_DETAILS_SUCCESS'
// export const CUSTOMER_EXPORT_DETAILS_FAILURE = 'CUSTOMER_EXPORT_DETAILS_FAILURE'

// export const SHIPPING_INSTRUCTIONS_REQUEST = 'SHIPPING_INSTRUCTIONS_REQUEST'
// export const SHIPPING_INSTRUCTIONS_SUCCESS = 'SHIPPING_INSTRUCTIONS_SUCCESS'
// export const SHIPPING_INSTRUCTIONS_FAILURE = 'SHIPPING_INSTRUCTIONS_FAILURE'

// export const EDIT_SHIPPING_INSTRUCTIONS_REQUEST = 'EDIT_SHIPPING_INSTRUCTIONS_REQUEST'
// export const EDIT_SHIPPING_INSTRUCTIONS_SUCCESS = 'EDIT_SHIPPING_INSTRUCTIONS_SUCCESS'
// export const EDIT_SHIPPING_INSTRUCTIONS_FAILURE = 'EDIT_SHIPPING_INSTRUCTIONS_FAILURE'

// export const CUSTOMER_EDIT_EXPORT_DETAILS = 'CUSTOMER_EDIT_EXPORT_DETAILS'
