import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL + '/files'

const files = axios.create({
  baseURL
})

interceptorsSetup(files)

export async function getCategories () {
  let { data: categories } = await files('/categories')
  return categories
}
