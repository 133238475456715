var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"hero is-fullheight"},[_c('div',{staticClass:"hero-body"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-one-third is-offset-one-third"},[_c('div',{staticClass:"card",attrs:{"id":"login-card"}},[_c('div',{staticClass:"card-content"},[_c('img',{staticClass:"text-center",attrs:{"src":require("@/assets/gps_logo.png"),"alt":"A remplir","width":"400px"}}),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{attrs:{"id":"login"},on:{"submit":function($event){$event.preventDefault();return _vm.updatePassword()}}},[_c('ValidationProvider',{attrs:{"slim":"","rules":"required","vid":"password","name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"message":errors,"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"rounded":"","type":"password","placeholder":"Password","icon":"lock","password-reveal":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"slim":"","rules":"required|confirmed:password","name":"Password confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"message":errors,"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"rounded":"","type":"password","placeholder":"Confirm password","icon":"lock","password-reveal":""},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1)]}}],null,true)}),_c('b-button',{attrs:{"type":"is-info","rounded":"","loading":_vm.isLoading,"disabled":invalid,"expanded":"","native-type":"submit"}},[_vm._v(" Submit ")])],1)]}}])})],1)])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }