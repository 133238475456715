<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-narrow">
        <div class="box">
          <form id="form-collection-filter">
            <b-field>
              <b-datepicker
                v-model="fromDate"
                name="from-date"
                placeholder="Click to select..."
                icon="calendar-alt"
                :max-date="toDate"
              />
            </b-field>
            <b-field>
              <b-datepicker
                v-model="toDate"
                name="to-date"
                placeholder="Click to select..."
                icon="calendar-alt"
                :min-date="fromDate"
              />
            </b-field>
            <div class="field">
              <div class="control">
                <a :class="{'is-loading': isSubmitting}" class="button is-fullwidth is-outlined" @click="fetchEndusers">
                  <b-icon size="is-small" icon="search"/>
                  <span>{{ $t('customer.collections.search') }}</span>
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="columns is-multiline">
      <div class="column is-12">
        <b-field grouped>
          <gridManager
            :grid-id="gridId"
            :profiles="gridProfiles"
            @save="saveProfile"
            @create="createProfile"
            @update="updateProfile"
            @select="selectProfile"
            @delete="deleteProfile"
          />
          <div class="field">
            <b-button icon-left="globe" type="is-primary" :loading="isLoadingGlobalExport" @click="globalExport">
              Global export
            </b-button>
          </div>
        </b-field>
        <ag-grid-vue
          :id="gridId"
          style="width: 100%; height: 700px;"
          class="ag-theme-alpine"
          row-selection="multiple"
          :grid-options="gridOptions"
          :pagination="true"
          :pagination-auto-page-size="true"
          :side-bar="['columns']"
          :row-data="endusers ? endusers.filter(e => e.collections && e.collections.quantity) : null"
          :multi-sort-key="'ctrl'"
          :get-context-menu-items="getContextMenuItems"
          :modules="modules"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import sortAndFilterMixins from '@/mixins/agGridSortAndFilter'
import GridManager from '@/components/common/grid-manager'

import gql from 'graphql-tag'
import { AllModules } from '@ag-grid-enterprise/all-modules'
import Customer from '@/services/v2/customer'
import { saveAs } from 'file-saver'

export default {
  components: {
    GridManager
  },
  mixins: [sortAndFilterMixins],

  data () {
    return {
      gridId: 'enduser-collection',
      endusers: null,
      modules: AllModules,
      gridOptions: {
        enableBrowserTooltips: true,
        columnDefs: [
          { headerName: this.$t('global.name'), field: 'name', filter: 'agTextColumnFilter', sortable: true, width: 280, sort: 'asc' },
          { headerName: this.$t('global.country'), field: 'country', cellRenderer: this.flagRender, filter: 'agTextColumnFilter', sortable: true, width: 120 },
          { headerName: this.$t('global.city'), field: 'city', filter: 'agTextColumnFilter', sortable: true, width: 180 },
          { headerName: this.$t('global.category'), field: 'regionCategory', filter: 'agTextColumnFilter', sortable: true, width: 100 },
          { headerTooltip: this.$t('tooltips.shipto'), headerName: this.$t('global.ship_to_code'), field: 'aggregate.concat.partnerReference', filter: 'agTextColumnFilter', sortable: true, width: 180 },
          { headerName: this.$t('global.expectedTransitTime'), field: 'expectedTransitTime', filter: 'agNumberColumnFilter', sortable: true, width: 100 },
          { headerTooltip: this.$t('tooltips.hiring_period'), headerName: this.$t('customer.stock.summary.grid.hiring_period'), field: 'hiringPeriod', filter: 'agNumberColumnFilter', width: 100 },
          { headerTooltip: this.$t('tooltips.usage_days'), headerName: this.$t('global.usageDays'), field: 'usageDays', filter: 'agNumberColumnFilter', sortable: true, width: 100 },
          { headerTooltip: this.$t('tooltips.enduser_collected_quantity'), headerName: this.$t('customer.collections.grid.collected_crates'), field: 'collections.quantity', filter: 'agNumberColumnFilter', sortable: true, width: 130 },
          { headerTooltip: this.$t('tooltips.contractual_cycle_time'), headerName: this.$t('customer.collections.grid.agreed_cycle_time'), field: 'contractualRentalDays', filter: 'agNumberColumnFilter', sortable: true, width: 100 },
          { headerTooltip: this.$t('tooltips.average_cycle_time'),
            headerName: this.$t('customer.collections.grid.today_cycle_time'),
            field: 'collections.averageRentalDays',
            filter: 'agNumberColumnFilter',
            sortable: true,
            width: 100,
            cellClassRules: {
              // apply green to 2008
              'rag-green': function (params) { if (params.data) return params.value <= params.node.data.contractualRentalDays },
              // apply amber 2004
              'rag-orange': function (params) { if (params.data) return params.value > params.node.data.contractualRentalDays && params.value <= params.node.data.contractualRentalDays + 250 },
              // apply red to 2000
              'rag-red': function (params) { if (params.data) return params.value > params.node.data.contractualRentalDays + 250 }
            } },
          { headerTooltip: this.$t('tooltips.storage_plant'), headerName: this.$t('customer.collections.grid.days_at_plant'), field: 'collections.averageDaysAtCustomerPlant', filter: 'agNumberColumnFilter', sortable: true, width: 100 },
          { headerTooltip: this.$t('tooltips.transit_time'), headerName: this.$t('customer.collections.grid.transit_time'), field: 'collections.averageTransitDays', filter: 'agNumberColumnFilter', sortable: true, width: 100 },
          { headerTooltip: this.$t('tooltips.storage_enduser'), headerName: this.$t('customer.collections.grid.days_at_enduser'), field: 'collections.averageDaysAtEnduser', filter: 'agNumberColumnFilter', sortable: true, width: 100 }
        ],
        defaultColDef: {
          floatingFilter: true,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: true,
          resizable: true
        }
      },
      fromDate: new Date(),
      toDate: new Date(),
      isSubmitting: false,
      isLoadingGlobalExport: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      selectedCustomer: state => state.selectedCustomer
    }),
    fromDateISO: function () {
      return this.$dayjs(this.fromDate).format('YYYY-MM-DD')
    },
    toDateISO: function () {
      return this.$dayjs(this.toDate).format('YYYY-MM-DD')
    }
  },
  watch: {
    selectedCustomer (newValue, oldValue) {
      this.fetchEndusers()
    }
  },
  mounted: async function () {
    this.toDate = this.$dayjs().toDate()
    this.fromDate = this.$dayjs(this.toDate).subtract(1, 'month').date(1).toDate()

    this.fetchEndusers()
  },
  methods: {
    globalExport: async function () {
      this.isLoadingGlobalExport = true
      const result = await Customer.getGlobalCollection({
        customerId: this.$store.state.selectedCustomer,
        fromDate: this.fromDateISO,
        toDate: this.toDateISO })
      const formatedResult = result.map(e => {
        const enduser = this.endusers.find(enduser => enduser.id === e.lastShipTo)

        return {
          enduserName: enduser.name.split('.,').join(' '),
          enduserCode: enduser.id,
          enduserCountry: enduser.country,
          enduserCity: enduser.city.split(',').join(' '),
          barcode: e.barcode,
          enduserCollectionsQuantity: enduser.collections.quantity,
          deliveryDate: e.deliveryDate,
          shippingDate: e.lastShipToDate,
          expectedArrivalDate: e.expectedArrivalDate,
          notificationDate: e.notificationDate,
          collectionDate: e.collectionDate,
          // collectionId: e.collectionId,
          // shipmentNumber: e.shipmentNumber,
          enduserCycleTime: e.csRefcycleTime,
          hiringPeriod: e.hiringPeriod,
          rentalDays: e.rentalDays,
          enduserDeliveryNumber: e.csRefDeliveryNumber,
          enduserUsageDays: enduser.usageDays,
          expectedTransitTime: enduser.expectedTransitTime,
          enduserMaxDaysLose: enduser.maxDaysLose,
          enduserCollectionsAverageDaysAtPlant: enduser.collections.averageDaysAtCustomerPlant,
          enduserCollectionsAverageDaysAtEnduser: enduser.collections.averageDaysAtEnduser,
          enduserCollectionsAverageRentalDays: enduser.collections.averageRentalDays,
          enduserCollectionsAverageTransitDays: enduser.collections.averageTransitDays,
          enduserCollectionsDeemedLost: enduser.collections.deemedLost,
          enduserCollectionsExceedContractualDays: enduser.collections.exceededContractualDays
        }
      })

      if (formatedResult.length) {
        const columns = Object.keys(formatedResult[0]).join(';')
        const values = formatedResult.map(e => Object.values(e).join(';'))
        const final = [columns, ...values]

        let blob = new Blob([final.join('\n')], { type: 'text/csv' })
        saveAs(blob, 'collectionGE.csv')
      }
      this.isLoadingGlobalExport = false
    },
    flagRender: function (params) {
      if (params.data) {
        const flag = `<img src="https://flagcdn.com/w20/${params.data.countryCode.toLowerCase()}.png" style="margin-left: 2px;margin-bottom: -4px;margin-right: 4px;">`
        return flag + ' ' + params.value
      } else {
        return params.value
      }
    },
    getContextMenuItems (params) {
      let selectedEnduser = params.node.data

      let customMenu = []
      const genericMenu = [
        'copy',
        'copyWithHeaders',
        'separator',
        'export'
      ]
      if (selectedEnduser) {
        customMenu.push(
          {
            name: this.$t('global.detail'),
            action: () => {
              this.$router.push({
                path: 'collections/detail',
                query: {
                  enduserId: selectedEnduser.id,
                  fromDate: this.fromDateISO,
                  toDate: this.toDateISO

                }
              })
            },
            icon: '<span class="ag-icon ag-icon-data" unselectable="on"></span>'
          }
        )
      }

      return [...customMenu, ...genericMenu]
    },

    fetchEndusers: async function () {
      this.isSubmitting = true
      const { fromDateISO, toDateISO } = this
      const query = gql`
        query endusersByCustomerId ($customerId: ID!, $group: Boolean, $fromDate: Date, $toDate: Date) {
          endusersByCustomerId (customerId: $customerId, group: $group) {
            totalCount
            edges {
              node {
                aggregate {
                  concat {
                    partnerReference
                  }
                }
                id
                name
                country
                countryCode
                city
                postalCode
                address
                regionCategory
                fromCustomerId
                contractualRentalDays
                maxDaysLose
                usageDays
                expectedTransitTime
                hiringPeriod
                collections (fromDate: $fromDate, toDate: $toDate){
                  quantity
                  averageRentalDays
                  averageDaysAtCustomerPlant
                  averageDaysAtEnduser
                  averageTransitDays
                  exceededContractualDays
                }
              }
            }
          }
        }
        `
      const result = await this.$apollo.query({
        fetchPolicy: 'no-cache',
        query: query,
        variables: {
          customerId: this.$store.state.selectedCustomer,
          fromDate: fromDateISO,
          toDate: toDateISO,
          group: true
        }
      })
      const endusersResult = result.data.endusersByCustomerId
      this.endusers = endusersResult.edges.map(e => e.node)
      this.isSubmitting = false
    }
  }
}
</script>
