<template>
  <section class="section">
    <b-loading :active.sync="isLoading" is-full-page/>

    <div class="container is-fluid">
      <div class="columns">
        <ul>
          <li v-for="category in categories" :key="category.id">
            <h1 class="subtitle">
              {{ category.name }}
            </h1>
            <div class="box">
              <div v-if="!$lodash.isEmpty(getFilesForCategory(category.id))" class="field is-grouped">
                <div v-for="file in getFilesForCategory(category.id)" :key="file.id" class="control">
                  <b-tooltip
                    :label=" new Date(file.uploadDate).toLocaleDateString('fr-FR') + ', ' + file.description + ', ' + file.displayName"
                    multilined
                    type="is-dark"
                    size="is-large"
                  >
                    <div class="file is-small is-boxed is-centered is-primary has-name">
                      <label class="file-label">
                        <span class="file-cta" @click="downloadFile(file.id, file.displayName)">
                          <span class="file-icon">
                            <i :class="file.displayName | coolFont" class="fa"/>
                          </span>
                          <span class="file-label">
                            Download
                          </span>
                        </span>
                        <span class="file-name">
                          <template>{{ file.displayName }}</template>
                          <!-- Uploaded: {{new Date(file.uploadDate).toLocaleDateString('fr-FR')}} -->
                        </span>
                      </label>
                    </div>
                  </b-tooltip>
                </div>
              </div>
              <div v-else>
                <p>There is no file here</p>
              </div>
            </div>
            <hr>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import { getCategories } from '@/services/v1/file'
import { saveAs } from 'file-saver'

// import { getFiles } from '@/services/v1/customer'
import { getCustomerFiles } from '@/services/v1/download'
export default {
  filters: {
    coolFont: function (value) {
      const extension = value.split('.').pop()
      let icon
      switch (extension.toLowerCase()) {
        case 'jpg':
        case 'png':
        case 'gif':
          icon = 'fa-file-image-o'
          break
        case 'xlsx':
          icon = 'fa-file-excel-o'
          break
        case 'pdf':
          icon = 'fa-file-pdf-o'
          break
        case 'txt':
        case 'docx':
          icon = 'fa-file-text-o'
          break
        default:
          icon = 'fa-file'
      }
      return icon
    }
  },
  data: function () {
    return {
      isLoading: true,
      files: [],
      categories: []
    }
  },
  mounted: async function () {
    this.categories = await getCategories()
    // this.files = await getFiles()

    this.isLoading = false
  },
  methods: {
    getFilesForCategory (categoryId) {
      return this.files.filter(e => e.fileType === categoryId)
    },
    async downloadFile (fileId, fileName) {
      const file = await getCustomerFiles(fileId)
      saveAs(file, fileName)
    }
  }
}
</script>

<style>

</style>
