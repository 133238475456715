import Auth from '@/services/v1/auth'

import * as types from './mutation-types'

const actions = {
  async login ({ commit }, obj) {
    const result = await Auth.login(obj.username, obj.password)
    const token = result.token

    Auth.setToken(token)
    Auth.setUser(result.user)

    commit(types.LOGIN_USER, token)
    commit(types.SET_USER, result.user)
  },
  async logout ({ commit }) {
    Auth.removeToken()
    Auth.removeUser()
    commit(types.LOGOUT_USER)
  }
}
export default actions
