import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/enduserDeclarationAdmin'

const pickupManagement = axios.create({
  baseURL
})

interceptorsSetup(pickupManagement)

export default class PickupManagement {
  static async spontaneousDeclaration (enduserCode) {
    const { data: result } = await pickupManagement.get(`/declaration/spontaneous/${enduserCode}`)
    return result
  }

  static async getByEnduser (enduserCode) {
    const { data: result } = await pickupManagement.get(`/mail/byEnduser/${enduserCode}`)
    return result
  }

  static async getQuantities (id) {
    const { data: result } = await pickupManagement.get(`/declaration/${id}/quantities`)
    return result
  }

  static async getEndusersQuantities (endusers) {
    const { data: result } = await pickupManagement.post(`/declaration/quantities`, endusers)
    return result
  }

  static async getDeclaration (id) {
    const { data: result } = await pickupManagement.get(`/declaration/${id}`)
    return result
  }

  static async getReminders (mailId) {
    const { data: result } = await pickupManagement.get(`/mail/${mailId}/reminders`)
    return result
  }

  static async getMaterials (enduserCode) {
    const { data: result } = await pickupManagement.get(`/materials/`, { params: { enduserCode: enduserCode } })
    return result
  }

  static async getPdfReport (token) {
    var FileSaver = require('file-saver')
    FileSaver.saveAs(baseURL + `/report/${token}/`, 'report.pdf')
  }

  static async getInventories (filters) {
    const { data: result } = await pickupManagement.get(`/inventory/`, { params: filters })
    return result
  }

  static async getNextConfirmedCollect (enduserCode) {
    const { data: result } = await pickupManagement.get(`/nextConfirmedCollect/${enduserCode}`)
    return result
  }
}
