<template>
  <nav class="panel">
    <p class="panel-heading">
      <b-icon icon="arrow-left" size="is-small"/> <span class="is-pulled-right clear-filters" @click="clearFilters">{{ $t('global.clear') }}</span>
    </p>
    <div class="panel-block">
      {{ filteredMarkers.length }} {{ $t('customer.map.markers') }}
    </div>
    <div class="panel-block">
      <div class="control">
        <label class="label">{{ $t('customer.map.types') }}</label>
        <b-field v-for="type in types" :key="type">
          <b-checkbox v-model="typeCheckboxGroup" :native-value="type">
            {{ type }}
          </b-checkbox>
        </b-field>
      </div>
    </div>
    <div class="panel-block">
      <div class="control">
        <b-field label="Name">
          <b-autocomplete
            v-model="name"
            :placeholder="$t('customer.map.name_placeholder')"
            :data="filteredMarkers"
            icon="search"
            field="name"
            @select="option => selectedMarker = option"
          >
            <template slot="empty">
              {{ $t('customer.map.no_result') }}
            </template>
          </b-autocomplete>
        </b-field>
      </div>
    </div>
    <!-- <div class="panel-block">
      <div class="control">
        <b-field label="Stock">
          <b-slider v-model="value" size="is-medium"/>
        </b-field>
      </div>
    </div> -->
  </nav>
</template>

<script>
export default {
  props: {
    markers: { type: Array, required: true }
  },
  data: function () {
    return {
      // value: 70,
      name: '',
      typeCheckboxGroup: [],
      selectedMarker: null
    }
  },
  computed: {
    categories: function () {
      return [...new Set(this.markers.map(e => e.regionCategory).filter(e => e).sort())]
    },
    types: function () {
      return [...new Set(this.markers.map(e => e.type).filter(e => e).sort())]
    },
    // regions: function () {
    //   return [...new Set(this.markers.map(e => e.region).filter(e => e).sort())]
    // },
    filteredMarkers () {
      let { name, markers, typeCheckboxGroup, selectedMarker } = this
      markers = markers.filter(row => {
        let match = typeCheckboxGroup.includes(row.type)
        match &= name.length > 2 ? row.name.toLowerCase().indexOf(name.toLowerCase()) >= 0 : true
        match &= selectedMarker ? (row.name === selectedMarker.name) : true
        return match
      })
      this.$emit('filter-changed', markers)

      return markers
    }
  },
  watch: {
    types: function (val) {
      this.typeCheckboxGroup = val
    }
  },
  methods: {
    clearFilters () {
      this.name = ''
      this.typeCheckboxGroup = this.types
    }
  }
}
</script>

<style>
.clear-filters {
  cursor: pointer;
}
</style>
