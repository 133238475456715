<template>
  <b-navbar>
    <template slot="start">
      <b-navbar-item tag="div" @click="$store.dispatch('switchSideBar')">
        <b-icon icon="bars" type="is-info" size="is-small"/>
      </b-navbar-item>

      <b-navbar-item tag="div">
        <div class="extranet-title">
          {{ $t(`navbar.gps_web_portal`) }} - {{ user ? user.group.name : 'no user' }}
        </div>
      </b-navbar-item>

      <b-navbar-item v-if="user.username !== 'demo'" tag="div">
        <b-select v-model="selectedCustomer" icon="portrait">
          <option
            v-for="partner in custsEnds"
            :key="partner.id"
            :value="partner.id"
          >
            {{ partner.id }} - {{ partner.name }}
          </option>
        </b-select>
      </b-navbar-item>
    </template>

    <template slot="end">
      <b-navbar-dropdown hoverable>
        <template slot="label">
          <b-icon icon="user-circle" type="is-link" size="is-small"/>
          <span>
            {{ user ? user.displayName : 'no user' }}
          </span>
        </template>
        <b-navbar-item tag="router-link" :to="{name: 'profile' }">
          <b-icon icon="id-card" pack="far" size="is-small"/>
          <span>
            Profile
          </span>
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{name: 'settings' }">
          <b-icon icon="cog" size="is-small"/>
          <span>
            Settings
          </span>
        </b-navbar-item>
      </b-navbar-dropdown>
      <language-switcher/>

      <b-navbar-item @click="handleLogout()">
        <b-icon icon="power-off" size="is-small" type="is-danger"/>
        <span>
          {{ $t(`navbar.logout`) }}
        </span>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import LanguageSwitcher from '../common/languageSwitcher.vue'

export default {
  components: {
    LanguageSwitcher
  },
  data () {
    return {
      mobileMenuVisible: false,
      custsEnds: null
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    ...mapState({
      user: state => state.auth.user
    }),
    selectedCustomer: {
      get () {
        return this.$store.state.selectedCustomer
      },
      set (value) {
        if (value) {
          this.$store.dispatch('setCustomer', value)
        }
      }
    }
  },
  mounted: function () {
    if (this.user?.group?.endusers?.length || this.user?.group?.customers?.length) {
      this.custsEnds = [...this.user.group.endusers, ...this.user.group.customers]
      this.custsEnds.sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })
      this.$store.dispatch('setCustomer', this.custsEnds[0].id)
    }
  },
  methods: {
    ...mapActions(['logout']),
    handleLogout () {
      this.logout()
      window.location = '/login'
    }
  }
}
</script>
<style lang="scss">

.navbar {
  border-bottom: 1px solid rgba(0,0,0,.0625);
  padding: 0.4rem 1rem;

  .extranet-title {
    margin-left: 2rem;
    font-size: 1.2rem;
  }
}

</style>
