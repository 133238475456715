
<template>
  <div class="navbar-item has-dropdown is-hoverable">
    <div class="navbar-link">
      <img :src="`https://flagcdn.com/w20/${getLocalFlagString()}.png`" alt="flag">
      <span class="ml-2">{{ $i18n.locale.toUpperCase() }}</span>
    </div>

    <div class="navbar-dropdown">
      <div class="navbar-item hoverable" @click.prevent="setLocale('en')">
        <img style="min-width: 28px; min-height: 28px;" src="https://flagcdn.com/w20/us.png" alt="english flag">
        <span>English</span>
      </div>
      <div class="navbar-item" @click.prevent="setLocale('fr')">
        <img style="min-width: 28px; min-height: 28px;" src="https://flagcdn.com/w20/fr.png" alt="french flag">
        <span>Français</span>
      </div>
      <div class="navbar-item" @click.prevent="setLocale('ru')">
        <img style="min-width: 28px; min-height: 28px;" src="https://flagcdn.com/w20/ru.png" alt="Russian flag">
        <span>Pусский</span>
      </div>
      <div class="navbar-item" @click.prevent="setLocale('zh')">
        <img style="min-width: 28px; min-height: 28px;" src="https://flagcdn.com/w20/cn.png" alt="chinese flag">
        <span>普通话</span>
      </div>
      <div class="navbar-item" @click.prevent="setLocale('kr')">
        <img style="min-width: 28px; min-height: 28px;" src="https://flagcdn.com/w20/kr.png" alt="Korean flag">
        <span>한국어</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import mixinMethods from '@/mixins/misc'

export default {
  mixins: [clickaway, mixinMethods],
  data () {
    return {
      isVisible: false,
      focusedIndex: 0
    }
  },
  methods: {
    setLocale (locale) {
      this.$i18n.locale = locale
      localStorage.setItem('lang', locale)
    }
  }
}
</script>

<style scoped>
.navbar-item > span {
  margin-left: 5px;
}

.navbar-item {
  cursor: pointer;
  margin-right: 20px;
}
</style>
