<template>
  <div class="section">
    <div v-if="!showEndusers" class="container is-fluid">
      <div class="columns is-multiline">
        <div class="column is-narrow">
          <div class="card custom-card">
            <header class="card-header">
              <p class="card-header-title has-text-grey">
                {{ $t(`${localeRoot}.review_card.title`) }}
              </p>
            </header>
            <div class="card-content">
              <div class="content">
                <dl>
                  <dt>
                    <template>{{ $t(`${localeRoot}.review_card.content`) + " " }}</template>
                    <strong>{{ shippingsWithoutConsignee }}</strong>
                  </dt>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="buttons">
            <b-button
              type="is-link"
              icon-left="plus"
              size="is-small"
              outlined
              @click="showEditForm(null)"
            >
              {{ $t(`${localeRoot}.date_table.new_shipping`) }}
            </b-button>
            <b-button
              type="is-primary"
              icon-left="user-friends"
              size="is-small"
              outlined
              @click="showEndusers = true"
            >
              {{ $t(`${localeRoot}.date_table.my_endusers`) }}
            </b-button>
            <b-button
              type="is-info"
              icon-left="file-import"
              size="is-small"
              outlined
              @click="importingShipping = true"
            >
              {{ $t(`${localeRoot}.date_table.import_shipping`) }}
            </b-button>

            <b-button
              tag="router-link"
              to="/shippingFileManagement"
              type="is-info"
              icon-left="file"
              size="is-small"
              outlined
            >
              {{ $t(`${localeRoot}.date_table.shipping_file_management`) }}
            </b-button>
          </div>
        </div>
        <div class="right-align">
          <div class="buttons">
            <div style="position: relative;">
              <b-button
                type="is-success"
                icon-left="magic"
                size="is-small"
                @click="autoAssigning = true"
              >
                {{ $t(`${localeRoot}.date_table.auto_assign`) }}
              </b-button>
              <span class="notif">{{ autoAssignObject.length }}</span>
            </div>
            <b-button
              type="is-link"
              icon-left="sync"
              size="is-small"
              @click="getData(); filteredShippings = []"
            >
              {{ $t(`${localeRoot}.date_table.refresh`) }}
            </b-button>
          </div>
        </div>
      </div>
      <ag-grid-vue
        style="width: 100%; height: 1000px;"
        class="ag-theme-alpine"
        :grid-options="gridOptions"
        :pagination="true"
        :pagination-auto-page-size="true"
        :modules="modules"
        :cache-overflow-size="0"
      />
      <b-modal :active.sync="autoAssigning" scroll="keep" has-modal-card :on-cancel="getData">
        <autoAssign
          :auto-assign-object="autoAssignObject"
        />
      </b-modal>
      <b-modal :active.sync="isCreating" scroll="keep" has-modal-card :on-cancel="getData">
        <ShippingForm
          :endusers="endusers"
          :editable-shipping="null"
          :is-submitting="isSubmitting"
          :dynamic-fields="dynamicFields"
          @submit="submitCreateShipping"
          @moveToNewShipping="moveToNewShipping"
        />
      </b-modal>
      <b-modal :active.sync="isEditing" scroll="keep" has-modal-card :on-cancel="getData">
        <ShippingForm
          :endusers="endusers"
          :editable-shipping="editableShipping"
          :dynamic-fields="dynamicFields"
          :is-submitting="isSubmitting"
          @submit="submitUpdateShipping"
          @moveToNewShipping="moveToNewShipping"
        />
      </b-modal>
      <b-modal :active.sync="importingShipping" scroll="keep" has-modal-card :on-cancel="() => {file = null}">
        <div>
          <div class="modal-card" style="min-height: 300px">
            <header class="modal-card-head">
              <p
                class="modal-card-title"
              >
                {{ $t(`${localeRoot}.import_modal.title`) }}
              </p>
            </header>
            <section class="modal-card-body">
              <div class="center">
                <b-field class="file">
                  <b-upload v-model="file">
                    <a class="button is-primary">
                      <b-icon icon="upload"/>
                      <span>{{ $t(`${localeRoot}.import_modal.import`) }}</span>
                    </a>
                  </b-upload>
                  <span v-if="file" class="file-name">
                    {{ file.name }}
                  </span>
                </b-field>
              </div>
            </section>
            <footer class="modal-card-foot">
              <button
                class="button is-fullwidth"
                @click="importingShipping = false"
              >
                {{ $t(`${localeRoot}.import_modal.close`) }}
              </button>
              <button
                :class=" {'is-loading' : isSubmitting}"
                type="submit"
                class="button is-info is-fullwidth"
                @click="submitImportShipping"
              >
                {{ $t(`${localeRoot}.import_modal.submit`) }}
              </button>
            </footer>
          </div>
        </div>
      </b-modal>
      <b-modal :active.sync="settingEnduser" scroll="keep" has-modal-card>
        <div>
          <div class="modal-card" style="min-height: 600px">
            <header class="modal-card-head">
              <p
                class="modal-card-title"
              >
                {{ $t(`customer.shipping_management.set_enduser_modal.title`) }}
              </p>
            </header>
            <section class="modal-card-body">
              <div>
                <b-field :label="$t(`${localeRoot}` + '.edit_modal.consignee')">
                  <multiselect
                    v-model="settedEnduser"
                    :options="endusers"
                    class="z-9"
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :allow-empty="true"
                    :preserve-search="true"
                    placeholder="Pick consignee..."
                    :custom-label="enduser => {return enduser.partnerReference + ' - ' + enduser.name + ' | ' + enduser.city + ' - ' + enduser.country}"
                    track-by="name"
                  />
                </b-field>
              </div>
            </section>
            <footer class="modal-card-foot">
              <button
                class="button is-fullwidth"
                @click="settingEnduser = false"
              >
                {{ $t(`global.close`) }}
              </button>
              <button
                :class=" {'is-loading' : submittingSetEnduser}"
                type="submit"
                class="button is-info is-fullwidth"
                @click="submitSetEnduser"
              >
                {{ $t(`global.submit`) }}
              </button>
            </footer>
          </div>
        </div>
      </b-modal>
    </div>
    <div v-else>
      <enduserList :endusers="endusers" :hide-enduser-list="hideEnduserList"/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ShippingManagement from '@/services/v2/shippingManagement'
import Customer from '@/services/v2/customer'
import ShippingForm from './shipping-form'
import autoAssign from './autoAssign'
import EnduserList from './enduserList'
import XLSX from 'xlsx'
import { AllModules } from '@ag-grid-enterprise/all-modules'
// import sortAndFilterMixins from '@/mixins/agGridSortAndFilter'
import gql from 'graphql-tag'

export default {
  components: {
    ShippingForm,
    EnduserList,
    autoAssign
  },
  // mixins: [sortAndFilterMixins],
  data () {
    return {
      shippings: [],
      crateMovements: [],
      newShippingCrates: [],
      isLoading: false,
      isEditing: false,
      isSubmitting: false,
      editableShipping: {},
      endusers: {},
      shippingsWithoutConsignee: 0,
      localeRoot: 'customer.shipping_management',
      newShipping: false,
      isCreating: false,
      showEndusers: false,
      importingShipping: false,
      file: null,
      modules: AllModules,
      customerCrateMovements: null,
      settingEnduser: false,
      settedEnduser: null,
      selectedShippings: [],
      submittingSetEnduser: false,
      autoAssignObject: [],
      autoAssigning: false,
      dynamicFields: [],
      timeZone: null,

      gridOptions: {
        rowModelType: 'serverSide',
        getContextMenuItems: this.gridActions,

        defaultColDef: {

          enableValue: true,
          enableRowGroup: true,
          enablePivot: true,
          resizable: true
        },

        columnDefs: [
          {
            headerName: this.$t('customer.shipping_management.date_table.status'),
            colId: 'status',

            aggFunc: this.statusAgg,

            filterParams: { suppressAndOrCondition: true },

            valueGetter: function (params) {
              if (params.data) {
                return { hasEnduser: !!params.data.enduserId, isLocked: !!params.data.process }
              }
              return ''
            },

            cellRenderer: (params) => {
              let render = ''
              if (params.value.hasEnduser) {
                render += '<i class="fas fa-check has-text-primary"> </i>'
              } else {
                render += '<i class="fas fa-times has-text-danger"> </i>'
              }
              if (params.value.isLocked) {
                render += ' <i class="fas fa-lock has-text-warning"> </i>'
              }
              return render
            }
          },
          {
            headerName: this.$t('customer.shipping_management.date_table.date') + ' (DD/MM/YYYY)',
            colId: 'creationDate',
            field: 'creationDate',
            sort: 'desc',

            filterParams: { suppressAndOrCondition: true },

            sortable: true,
            comparator: this.sortDate,

            valueGetter: (params) => {
              if (params.data) {
                return this.$dayjs(params.data.creationDate).format('DD/MM/YYYY')
              }
              return ''
            }
          },
          {
            headerName: this.$t('customer.shipping_management.hours_table.time'),
            colId: 'date',
            width: 120,
            filterParams: { suppressAndOrCondition: true },

            valueGetter: (params) => {
              if (params.data) {
                return this.$dayjs(params.data.creationDate).tz(this.timeZone).format('HH:mm')
              }
              return ''
            }
          },
          {
            headerName: this.$t('customer.shipping_management.hours_table.reference'),
            colId: 'reference',
            field: 'reference',

            filter: 'agTextColumnFilter',
            sortable: true,

            filterParams: { suppressAndOrCondition: true },

            valueGetter: (params) => {
              if (params.data) {
                return params.data.reference || this.$t('customer.shipping_management.hours_table.unspecified')
              }
              return ''
            },
            cellClass: function (params) {
              if (params.data) {
                return params.data.reference ? '' : 'rag-yellow'
              }
              return ''
            }
          },
          {
            headerName: this.$t('customer.shipping_management.hours_table.reference_complement'),
            field: 'referenceComplement',
            colId: 'referenceComplement',

            filter: 'agTextColumnFilter',
            sortable: true,

            filterParams: { suppressAndOrCondition: true },

            valueGetter: (params) => {
              if (params.data) {
                return params.data.referenceComplement || this.$t('customer.shipping_management.hours_table.unspecified')
              }
              return ''
            },
            cellClass: function (params) {
              if (params.data) {
                return params.data.referenceComplement ? '' : 'rag-yellow'
              }
              return ''
            }
          },
          {
            headerName: this.$t('customer.shipping_management.hours_table.consignee'),
            colId: 'enduser',
            field: 'enduser',
            filter: 'agTextColumnFilter',
            sortable: true,

            filterParams: { suppressAndOrCondition: true },

            valueGetter: (params) => {
              if (params.data) {
                if (params.data.enduserId && params.data.enduser) {
                  return params.data.enduser.partnerReference + ' - ' + params.data.enduser.name + ' | ' + params.data.enduser.city + ' - ' + params.data.enduser.country
                } else {
                  return this.$t('customer.shipping_management.hours_table.unspecified')
                }
              }
              return ''
            },
            cellClass: function (params) {
              if (params.data) {
                return params.data.enduserId ? '' : 'rag-red'
              }
              return ''
            }
          },
          {
            headerName: this.$t('customer.shipping_management.hours_table.barcode_qty'),
            colId: 'crateMovements',
            field: 'crateMovements',
            aggFunc: 'sum',
            filterParams: { suppressAndOrCondition: true },

            valueGetter: (params) => {
              if (params.data && params.data.crateMovements) {
                return params.data.crateMovements.length
              }
              return ''
            },
            cellClass: function (params) {
              return 'rag-text-blue rag-text-medium'
            }
          }
          // {
          //   headerName: this.$t('customer.shipping_management.hours_table.scan_user'),
          //   colId: 'user',
          //   width: 120,
          //   valueGetter: (params) => {
          //     if (params.data) {
          //       return params.data.scanUserName
          //     }
          //     return ''
          //   }
          // }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      selectedCustomer: state => state.selectedCustomer
    }),
    computedShippings: function () {
      let shippings = this.shippings
      shippings.map(shipping => {
        shipping.formatedDate = this.$dayjs(shipping.creation_date).format('DD/MM/YYYY')
        shipping.formatedhour = this.$dayjs(shipping.creation_date).format('HH:mm')

        if (this.customerCrateMovements) {
          shipping.crateMovements = this.customerCrateMovements.filter(mv => {
            return mv.shipping_id === shipping.id
          })
        }
      })
      shippings.sort((a, b) => {
        return this.$dayjs(a.creation_date).isBefore(this.$dayjs(b.creation_date)) ? 1 : -1
      })
      return shippings
    }
  },
  watch: {
    selectedCustomer (newValue, oldValue) {
      this.getData()
    }
  },
  async mounted () {
    await this.getData()
    this.timeZone = this.$dayjs.tz.guess()
  },
  methods: {
    deleteShipping (shipping) {
      if (!shipping.process) {
        this.$buefy.dialog.confirm({
          title: this.$t(`${this.localeRoot}.delete_modal.title`),
          message: this.$t(`${this.localeRoot}.delete_modal.content`),
          confirmText: this.$t(`${this.localeRoot}.delete_modal.confirm`),
          cancelText: this.$t(`${this.localeRoot}.delete_modal.cancel`),
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {
            try {
              await ShippingManagement.deleteShipping(shipping.id)
              await this.getData()
            } catch (error) {
              this.$buefy.toast.open({
                message: error.message || error,
                type: 'is-danger'
              })
            }
          }
        })
      }
    },
    async getCrateMovements (id) {
      this.crateMovements[id] = await ShippingManagement.getCrateMovements(id)
      const shipping = this.shippings.find(shipping => {
        return shipping.id === id
      })
      shipping['barcodeNumber'] = this.crateMovements[id].length
    },
    async getCustomerCrateMovements () {
      this.customerCrateMovements = await ShippingManagement.getCrateMovementsByCustomerId(this.selectedCustomer)
    },
    getData: async function () {
      // Not used
      this.isEditing = false
      this.isLoading = true
      try {
        this.endusers = await Customer.getEndusers({
          customerId: this.selectedCustomer
        })
        // this.bindEndusers()
        const datasource = this.getDataSource()
        this.gridOptions.api.setServerSideDatasource(datasource)
        // this.gridOptions.api.refreshCells()
        this.autoAssign()
        this.getDynamicFields()
      } catch (error) {
        this.$buefy.toast.open({
          type: 'is-danger',
          message: error.message,
          duration: 3000
        })
      }

      this.shippingsWithoutConsignee = this.shippings.filter(shipping => {
        return !shipping.enduser
      }).length

      this.isLoading = false
    },
    bindEndusers: async function () {
      for (let i = 0; i < this.shippings.length; i++) {
        const shipping = this.shippings[i]
        if (shipping['enduserId']) {
          this.shippings[i]['enduser'] = this.endusers.find(enduser => {
            return enduser.id === shipping['enduserId']
          })
        }
      }
    },
    showEditForm (shipping) {
      if (shipping) {
        if (!shipping.process) {
          this.isEditing = true
          this.editableShipping = shipping
        }
      } else {
        this.isCreating = true
      }
    },
    submitUpdateShipping: async function (data) {
      try {
        let shipping = data[0]
        let crateMovements = data[1]
        this.isSubmitting = true
        await ShippingManagement.updateShipping(shipping, crateMovements)
        this.isEditing = false
        await this.getData()
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message || error,
          type: 'is-danger'
        })
      }
      this.isSubmitting = false
    },
    submitCreateShipping: async function (data) {
      try {
        let shipping = data[0]
        let crateMovements = data[1]
        this.isSubmitting = true
        const newShipping = {
          enduserId: shipping.enduser ? shipping.enduser.id : null,
          consigneeCode: shipping.enduser ? shipping.enduser.partnerReference : null,
          crateMovements: crateMovements,
          reference: shipping.reference,
          referenceComplement: shipping.referenceComplement,
          date: shipping.creation_date,
          scanUser: shipping.scanUser
        }
        await ShippingManagement.createShipping(this.selectedCustomer, newShipping)
        this.isCreating = false
        await this.getData()
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message || error,
          type: 'is-danger'
        })
      }
      this.isSubmitting = false
    },
    getTime (dateString) {
      let date = new Date(dateString)
      let hours = date.getUTCHours()
      let minutes = date.getUTCMinutes()
      if (hours.toString().length === 1) hours = '0' + hours
      if (minutes.toString().length === 1) minutes = '0' + minutes
      return hours + ':' + minutes
    },
    async moveToNewShipping (crateMovements, shipping, oldShipping, oldCrates) {
      await ShippingManagement.updateShipping(oldShipping, oldCrates)
      await ShippingManagement.createShipping(this.selectedCustomer, {
        crateMovements: crateMovements,
        date: shipping.creation_date,
        reference: shipping.reference,
        enduserId: shipping.enduser ? shipping.enduser.id : null
      })
      this.isEditing = false
      await this.getData()
    },
    hideEnduserList () {
      this.showEndusers = false
    },
    hideImportShippingModal () {
      this.importingShipping = false
      this.file = null
    },
    submitImportShipping: async function () {
      try {
        if (this.file) {
          this.isSubmitting = true
          // 1 => category id of shipping
          await ShippingManagement.uploadEndusers(this.selectedCustomer, 1, this.file)
          this.isSubmitting = false
          this.hideImportShippingModal()
          this.$buefy.toast.open({
            message: this.$t(`${this.localeRoot}.import_modal.upload_done`),
            type: 'is-primary'
          })
        }
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message || error,
          type: 'is-danger'
        })
      }
    },
    sortDate (a, b, dataA, dataB) {
      return this.$dayjs(dataA.data.creationDate).isBefore(this.$dayjs(dataB.data.creationDate)) ? -1 : 1
    },
    workbook: async function (data) {
      const csvData = [data]
      const worksheet = XLSX.utils.aoa_to_sheet(csvData)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'SheetJS')
      return workbook
    },
    csvDownload: async function (data) {
      const crateMovements = await ShippingManagement.getCrateMovements(data.id)
      const barcodes = crateMovements.map(b => {
        return b.barcode
      })
      XLSX.writeFile(await this.workbook(barcodes), 'export.csv')
    },
    statusAgg: function (values) {
      let hasEnduser = !values.find(v => !v.hasEnduser)
      let isLocked = !values.find(v => !v.isLocked)
      return { hasEnduser: hasEnduser, isLocked: isLocked }
    },
    setEnduser: function (selectedRows) {
      this.selectedShippings = selectedRows
      if (selectedRows.find(s => s.process)) {
        this.$buefy.toast.open({
          message: this.$t(`customer.shipping_management.hours_table.set_enduser_locked`),
          type: 'is-danger',
          duration: 5000
        })
      } else {
        this.settingEnduser = true
      }
    },
    submitSetEnduser: async function () {
      try {
        this.submittingSetEnduser = true
        for (let i = 0; i < this.selectedShippings.length; i++) {
          const shipping = this.selectedShippings[i]
          shipping.enduser = this.settedEnduser
          await ShippingManagement.updateShipping(shipping, shipping.crateMovements)
        }
        this.settingEnduser = false
        this.settedEnduser = null
        this.submittingSetEnduser = false
        this.getData()
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message || error,
          type: 'is-danger'
        })
      }
    },
    gridActions: function (params) {
      let customMenu = []
      const genericMenu = [
        'copy',
        'copyWithHeaders',
        'separator',
        'export'
      ]
      if (params.node && params.node.data) {
        if (!params.node.data.process) {
          customMenu.push({
            name: this.$t(`customer.shipping_management.hours_table.edit`),
            action: () => { this.showEditForm(params.node.data) },
            icon: '<i class="fas fa-fw fa-edit" ></i>'
          })
          customMenu.push({
            name: this.$t(`customer.shipping_management.hours_table.delete`),
            action: () => { this.deleteShipping(params.node.data) },
            icon: '<i class="fas fa-fw fa-trash" ></i>'
          })
          customMenu.push({
            name: this.$t(`customer.shipping_management.hours_table.export_barcodes`),
            action: () => { this.csvDownload(params.node.data) },
            icon: '<i class="fas fa-fw fa-download" ></i>'
          })

          if ((this.user.group?.name.includes('GPS') || this.user.role?.name.includes('GPS')) && (params.node.data.enduserId || params.node.data.consigneeCode)) {
            customMenu.push({
              name: 'Manual validatation',
              icon: '<i class="fas fa-fw fa-check"></i>',
              action: () => { this.manualValidatie(params.node.data) }
            })
          }
        }
      }
      const selectedRows = this.gridOptions.api.getSelectedRows()

      if (selectedRows.length > 0) {
        customMenu.push({
          name: this.$t(`customer.shipping_management.hours_table.set_enduser`),
          action: () => { this.setEnduser(selectedRows) },
          icon: '<i class="fas fa-fw fa-users-cog" ></i>'
        })
      }
      const result = [...customMenu, ...genericMenu]
      return result
    },
    async manualValidatie (data) {
      this.isSubmitting = true
      try {
        await ShippingManagement.manualValidate(data)
        this.$buefy.toast.open({ type: 'is-primary', message: 'Done!', duration: 3000 })
        await this.getData()
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message || error,
          type: 'is-danger'
        })
      }
      this.isSubmitting = false
    },
    autoAssign: function () {
      this.autoAssignObject = []
      const referencedShippings = this.computedShippings.filter(s => {
        return s.referenceComplement && s.enduserId
      }).slice()
      referencedShippings.map(r => {
        // const copy = [...this.computedShippings.filter(s => s.referenceComplement === r.referenceComplement)].slice()
        const similar = JSON.parse(JSON.stringify(this.computedShippings.filter(s => {
          return s.referenceComplement === r.referenceComplement && !s.enduserId && !this.autoAssignObject.find(a => a.id === s.id)
        })))
        similar.map(c => {
          c.enduserId = r.enduserId
          c.consigneeCode = r.consigneeCode
          c.enduser = this.endusers.find(e => e.id === r.enduserId)
        })
        this.autoAssignObject.push(...similar)
      })
    },
    getDataSource () {
      return {
        getRows: async (params) => {
          const sorts = params.request.sortModel.map(sortModel => ({
            column: sortModel.colId === 'enduser' ? 'enduserId' : sortModel.colId,
            order: sortModel.sort }))

          const builder = this.$gql.query({
            operation: 'pendingShipping',
            fields: ['totalCount', {
              edges: [{
                node: [
                  'id',
                  'enduserId',
                  'consigneeCode',
                  'customerId',
                  'reference',
                  'referenceComplement',
                  'creationDate',
                  'uuid',
                  'process',
                  'processDate',
                  'crateMovements { id barcode }'
                ]
              }]
            }],
            variables: {
              skip: { type: 'Int', value: params.request.startRow },
              limit: { type: 'Int', value: params.request.endRow },
              sort: { type: '[sortInputPendingShipping]', value: sorts },
              // filter: { type: 'filterInputPendingShipping', value: this.getFilters(params.request) },
              // group: { type: 'Boolean', value: !!groupSummary.find(e => e.isHere === true) },
              customerId: this.selectedCustomer
            }
          })

          const result = await this.$apollo.query({
            fetchPolicy: 'no-cache',
            query: gql`${builder.query}`,
            variables: builder.variables })

          const pendingShipping = result.data.pendingShipping
          this.totalCount = pendingShipping.totalCount
          this.shippings = pendingShipping.edges.map(e => e.node)

          this.bindEndusers()

          params.successCallback(this.shippings, this.totalCount)
        }
      }
      // params.api.setServerSideDatasource(dataSource)
    },

    /**
     * To get Dynamic fields for this customer
    **/
    async getDynamicFields () {
      const builder = this.$gql.query({
        operation: 'dynamicFields',
        fields: [{
          dynamicFields: [
            'fieldId',
            'customerCode',
            'name',
            'type',
            'displayName',
            'translateRef',
            'active',
            'selectFields { fieldId label value }'
          ]
        }],
        variables: {
          customerId: this.selectedCustomer
        }
      })

      const result = await this.$apollo.query({
        fetchPolicy: 'no-cache',
        query: gql`${builder.query}`,
        variables: builder.variables
      })

      this.dynamicFields = result.data.dynamicFields.dynamicFields
    }
  }
}
</script>

<style scoped>
.custom-card {
  padding: 10px;
  margin: 10px;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.right-align {
  display: flex;
  flex-direction: row-reverse
}

.notif {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 200px;
  background-color: red;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0px;
  top: -10px;
  color: white;
}

</style>
