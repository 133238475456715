import gql from 'graphql-tag'
import Vue from 'vue'

export default {
  data: function () {
    return {
      gridProfiles: []
    }
  },
  mounted: async function () {
    await this.getGridProfiles()
  },
  methods: {
    /// //////// Get formated aggrid data //////////////////
    getFilters: function (request) {
      const groups = this.getGroups(request).filter(e => e.key !== null)

      const filterModel = request.filterModel
      let filter = {}

      groups.forEach(e => {
        filter[e.column] = {}
        filter[e.column]['equals'] = e.key
      })
      // Creating graphql filters from aggrid params
      Object.keys(filterModel).forEach(key => {
        const val = filterModel[key]
        filter[key] = {}
        if (val.filterType === 'date') {
          filter[key][val.type] = val.type === 'inRange' ? [val.dateFrom, val.dateTo] : val.dateFrom
        } else if (val.filterType === 'text' || val.filterType === 'number') {
          filter[key][val.type] = val.type === 'inRange' ? [val.filter, val.filterTo] : val.filter
        }
      })
      return filter
    },
    getSorts: function (request) {
      const currentRowGroup = this.getGroups(request).filter(e => e.isHere === true)[0]

      return request.sortModel.filter(srt => {
        if (currentRowGroup) {
          return srt.colId === currentRowGroup.column
        } else {
          return true
        }
      }).map(srt => {
        return { column: srt.colId, order: srt.sort }
      })
    },
    getGroups: function (request) {
      return request.rowGroupCols.map((grp, index) => {
        const groupKeys = request.groupKeys[index] || null
        return { column: grp.field, key: groupKeys, isHere: index === request.groupKeys.length }
      })
    },
    getAggregate: function (request) {
      let aggregate = []

      request.valueCols.forEach(e => {
        const found = aggregate.find(e => e.hasOwnProperty(e.aggFun))

        if (found) {
          found[e.aggFunc].push(e.id)
        } else {
          const newAgg = {}
          newAgg[e.aggFunc] = [e.id]
          aggregate.push(newAgg)
        }
      })
      return aggregate
    },
    getGridProfiles: async function () {
      const builder = this.$gql.query({
        operation: 'userGridProfiles',
        fields: ['gridId', 'profileName', 'columnsState'],
        variables: {
          userId: { type: 'ID!', value: this.$store.state.auth.user.id },
          gridId: { type: 'ID!', value: this.gridId }
        }
      })
      const result = await this.$apollo.query({
        fetchPolicy: 'no-cache',
        query: gql`${builder.query}`,
        variables: builder.variables })

      const selectedProfileName = this.$cookies.get(this.gridId)
      let selectedProfile = result.data.userGridProfiles.find(e => e.profileName === selectedProfileName)

      if (!selectedProfile && result.data.userGridProfiles.length) {
        selectedProfile = result.data.userGridProfiles[0]
      }

      if (selectedProfile) {
        selectedProfile.isSelected = true
        selectedProfile.isActive = true

        this.gridOptions.columnApi.setColumnState(JSON.parse(window.atob(selectedProfile.columnsState)))
      }

      this.gridProfiles = result.data.userGridProfiles
    },
    /// ///////////////// Process profile action methods //////////////////////////
    deleteProfile: async function (editingProfile) {
      const builder = this.$gql.mutation({
        operation: 'userGridProfileDelete',
        variables: {
          userId: { type: 'ID!', value: this.$store.state.auth.user.id },
          gridId: { type: 'ID!', value: this.gridId },
          profileName: { required: true, value: editingProfile.profileName }
        }
      })
      await this.$apollo.mutate({
        mutation: gql`${builder.query}`,
        variables: builder.variables })

      this.gridProfiles = this.gridProfiles.filter(profile => {
        return profile.profileName !== editingProfile.profileName
      })
    },
    updateProfile: async function (editingProfile) {
      const builder = this.$gql.mutation({
        operation: 'userGridProfileUpdate',
        variables: {
          userId: { type: 'ID!', value: this.$store.state.auth.user.id },
          gridId: { type: 'ID!', value: this.gridId },
          profileName: { required: true, value: editingProfile.oldProfileName },
          newProfileName: { required: true, value: editingProfile.profileName }
        }
      })
      await this.$apollo.mutate({
        mutation: gql`${builder.query}`,
        variables: builder.variables })

      const foundIndex = this.gridProfiles.findIndex(e => e.profileName === editingProfile.oldProfileName)
      Vue.set(this.gridProfiles, foundIndex, editingProfile)
    },
    selectProfile: async function (selectedProfile) {
      this.gridProfiles = this.gridProfiles.map(e => {
        if (selectedProfile.profileName === e.profileName) {
          e.isSelected = true
        } else {
          delete e.isSelected
        }
        return e
      })
      this.$cookies.set(this.gridId, selectedProfile.profileName)
      this.gridOptions.columnApi.setColumnState(JSON.parse(window.atob(selectedProfile.columnsState)))
    },
    saveProfile: async function (savedProfile) {
      const columnsState = this.gridOptions.columnApi.getColumnState()
      const objJsonStr = JSON.stringify(columnsState)
      const objJsonB64 = window.btoa(objJsonStr)
      const builder = this.$gql.mutation({
        operation: 'userGridProfileCreate',
        fields: ['userId', 'gridId', 'profileName', 'columnsState'],
        variables: {
          userId: { type: 'ID!', value: this.$store.state.auth.user.id },
          gridId: { type: 'ID!', value: this.gridId },
          profileName: { required: true, value: savedProfile.profileName },
          columnsState: { required: true, value: objJsonB64 }
        }
      })
      await this.$apollo.mutate({
        mutation: gql`${builder.query}`,
        variables: builder.variables })

      const foundIndex = this.gridProfiles.findIndex(e => e.profileName === savedProfile.profileName)
      savedProfile.columnsState = objJsonB64
      Vue.set(this.gridProfiles, foundIndex, savedProfile)

      this.$buefy.snackbar.open(`Saved grid state.`)
    },
    createProfile: async function (newProfile) {
      const columnsState = this.gridOptions.columnApi.getColumnState()
      const objJsonStr = JSON.stringify(columnsState)
      const objJsonB64 = window.btoa(objJsonStr)
      const builder = this.$gql.mutation({
        operation: 'userGridProfileCreate',
        fields: ['userId', 'gridId', 'profileName', 'columnsState'],
        variables: {
          userId: { type: 'ID!', value: this.$store.state.auth.user.id },
          gridId: { type: 'ID!', value: this.gridId },
          profileName: { required: true, value: newProfile.profileName },
          columnsState: { required: true, value: objJsonB64 }
        }
      })
      await this.$apollo.mutate({
        mutation: gql`${builder.query}`,
        variables: builder.variables })

      this.gridProfiles = [...this.gridProfiles, newProfile]
    }
  }
}
