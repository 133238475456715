<template>
  <header class="page-header">
    <nav v-if="breadcrumb.length" class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <template v-for="(item, index) in breadcrumbRoutes">
          <li
            :key="index"
            :class="{ 'is-active': index === breadcrumb.length - 1 }"
          >
            <router-link :to="item.path">
              {{ item.meta.title }}
            </router-link>
          </li>
        </template>
      </ul>
    </nav>
    <h1 class="title">
      {{ title }}
    </h1>
    <h2 class="subtitle">
      {{ subtitle }}
    </h2>
  </header>
</template>

<script>
import { routes } from '@/router/routes'
export default {
  props: {
    breadcrumb: { type: Array, default: Array },
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' }
  },
  data () {
    return {
      routes,
      breadcrumbRoutes: []
    }
  },
  mounted: function () {
    this.breadcrumbRoutes = this.breadcrumb.map(e => this.$router.resolve({ name: e }).route)
  }
}
</script>

<style>

</style>
