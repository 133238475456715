<template>
  <section style="height: 90vh;">
    <b-loading :active.sync="isLoading" :is-full-page="true"/>
    <div class="columns">
      <div class="column is-one-fifth">
        <div class="mt-5 ml-5 is-flex is-flex-wrap-wrap">
          <b-switch
            v-for="(t, i) in consigneeTypes"
            :key="i"
            v-model="switchStates[t]"
            class="mb-2"
            @input="toggleType(t)"
          >
            {{ t[0].toUpperCase() + t.slice(1).toLowerCase() }}
          </b-switch>
        </div>
        <multiselect
          v-if="listOnMap.length"
          v-model="selectedEnduser"
          class="my-3 ml-3"
          placeholder="Find an enduser"
          :options="listOnMap"
          :custom-label="nameWithId"
          label="name"
          track-by="name"
        />
        <ul v-if="selectedEnduser" class="endusers-list">
          <li
            @mouseover="setHoveredEnduser(selectedEnduser)"
            @mouseleave="clearHoveredEnduser()"
            @click="centerMapOnEnduser(selectedEnduser)"
          >
            <p class="has-text-weight-bold">
              {{ selectedEnduser.name }}
            </p>
            {{ selectedEnduser.id }} <br>
            {{ selectedEnduser.city }}, {{ selectedEnduser.country }}
          </li>
        </ul>
        <ul v-if="!selectedEnduser" class="endusers-list">
          <li
            v-for="(enduser, index) in listOnMap"
            :key="index"
            @mouseover="setHoveredEnduser(enduser)"
            @mouseleave="clearHoveredEnduser()"
            @click="centerMapOnEnduser(enduser)"
          >
            <p class="has-text-weight-bold">
              {{ enduser.id }} - {{ enduser.name }}
            </p>
            {{ enduser.city }}, {{ enduser.country }}
          </li>
        </ul>
      </div>
      <div v-if="showDetailPanel && polylineData.length" class="column is-one-fifth">
        <transition name="slide">
          <CrateDetailPanel
            :trace-points="polylineData"
            @onClose="()=>showDetailPanel=false"
            @onFocusPoint="focusPoint"
          />
        </transition>
      </div>
      <div class="column">
        <div class="map-container">
          <div class="control-wrapper">
            <div class="map-control">
              <b-collapse :open="true" class="card" animation="slide" aria-id="openContentId1">
                <template #trigger="props">
                  <div
                    class="card-header"
                    role="button"
                    aria-controls="openContentId1"
                    :aria-expanded="props.open"
                    expanded
                  >
                    <p class="card-header-title">
                      Map Control
                    </p>
                    <a class="card-header-icon">
                      <b-icon :icon="props.open ? 'caret-down' : 'caret-up'"/>
                    </a>
                  </div>
                </template>

                <div class="card-content">
                  <div class="content">
                    <b-radio v-model="type" native-value="si">
                      SI number
                    </b-radio>
                    <b-radio v-model="type" native-value="barcode">
                      Barcode
                    </b-radio>
                    <div class="map-select">
                      <div v-if="type === 'si'">
                        <b-select
                          v-model="selectedSi"
                          placeholder="Select a Si..."
                          expanded
                          @input="changeSelectedSi"
                        >
                          <option v-for="option in siData" :key="option.si" :value="option.si">
                            {{ option.si }}
                          </option>
                        </b-select>
                        <div class="is-flex is-flex-direction-row is-align-content-center is-justify-content-space-between mt-5">
                          <p class="mr-3">
                            Crates in progress: {{ totalActiveCrates }} / {{ totalPoints }}
                          </p>
                        </div>
                      </div>
                      <div v-if="type === 'barcode'">
                        <multiselect
                          v-model="selectedDevice"
                          placeholder="Select a barcode..."
                          :options="availableBarcodes"
                          track-by="barcode"
                          label="barcode"
                          :show-labels="false"
                          @select="onMarkerClick"
                        />
                        <div class="is-flex is-flex-direction-row is-align-content-center is-justify-content-space-between mt-5">
                          <p>In Si: {{ selectedDevice ? selectedDevice.si : '' }}</p>
                        </div>
                      </div>
                      <div v-if="type === 'barcode'" class="mt-5">
                        <b-field label="Select date range">
                          <b-datepicker
                            v-model="dates"
                            :min-date="minDate"
                            :max-date="maxDate"
                            placeholder="Click to select..."
                            range
                            trap-focus
                          >
                            <div class="mr-auto">
                              <b-button
                                label="View all"
                                @click="viewAll"
                              />
                            </div>
                          </b-datepicker>
                        </b-field>
                      </div>
                      <div class="mt-5 buttons is-flex is-justify-content-space-between">
                        <b-tooltip
                          label="Please select one crate "
                          position="is-bottom"
                          multilined
                        >
                          <b-button
                            v-if="type === 'barcode'"
                            type="is-primary"
                            :disabled="!selectedDevice || !dates.length"
                            @click="onApplyDate"
                          >
                            Apply
                          </b-button>
                        </b-tooltip>
                        <b-button @click="clearAndCenterMap">
                          Clear map
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <b-button class="map-button card-header-title" @click="()=>showTable=true">
              Table
            </b-button>
          </div>
          <div style="height: 90vh;">
            <l-map ref="trackMapRef" style="height: 90vh" :zoom="zoomLevel">
              <!-- <l-tile-layer :url="url"/> -->
              <l-control-layers position="topright"/>
              <l-tile-layer
                v-for="layer in baseLayers"
                :key="layer.name"
                :name="layer.name"
                :visible="layer.visible"
                :url="layer.url"
                layer-type="base"
              />

              <l-polyline :lat-lngs="polylineData.map(p => p.coords)" color="#15261f"/>
              <l-marker
                v-for="(point, index) in polylineData"
                :key="index"
                :lat-lng="point.coords"
                :icon="createCustomIcon(point, index)"
                :z-index-offset="2999"
                @mouseover="openPopup"
                @mouseout="closePopup"
              >
                <l-popup ref="popups" :key="index" :options="popupOptions">
                  <p>
                    <strong>Crate: {{ point.barcode }}</strong> <br>
                    <strong>SI: {{ selectedSi }}</strong> <br>
                    Device: {{ selectedDevice.shortQrTopCasing }}<br>
                    Time: {{ point.timestamp ? new Date(point.timestamp).toLocaleString() : 'unknown' }}<br>
                    Battery: {{ point.battery ? point.battery + '%' : 'unknown' }} {{ point.battery && point.battery === '0' ? ' (no real-time positioning)' : '' }}<br>
                    Position type: {{ point.locationMethod }}<br>
                    Temperature: {{ point.temperature || 'unknown' }}
                  </p>
                </l-popup>
              </l-marker>
              <l-marker-cluster v-if="showCrateMarkers" :z-index-offset="9999">
                <l-marker
                  v-for="(p, index) in points"
                  :key="index"
                  :lat-lng="p.coords"
                  :icon="generateIcon({
                    backgroundColor: p.lastSeenBattery ? (p.lastSeenBattery > 10 ? '55987e' : 'd0312d') : '55987e',
                    iconSize: [52, 50]
                  })"
                  :z-index-offset="3000"
                  @click="onMarkerClick(p)"
                  @mouseover="openPopup"
                  @mouseout="closePopup"
                >
                  <l-popup>
                    <p>
                      <strong>Crate:</strong> {{ p.barcode }}<br>
                      <strong>SI: {{ p.si }}</strong> <br>
                      Device: {{ p.shortQrTopCasing }}<br>
                      Last update: {{ p.lastUpdated ? new Date(p.lastUpdated).toLocaleString() : 'unknown' }}<br>
                      Battery: {{ p.lastSeenBattery ? p.lastSeenBattery + '%' : 'unknown' }}{{ p.lastSeenBattery && p.lastSeenBattery === '0'? ' (no real-time positioning)' : '' }}<br>
                      Temperature: {{ p.temperature || 'unknown' }}<br>
                      Position type: {{ p.locationMethod }}<br>
                    </p>
                  </l-popup>
                </l-marker>
              </l-marker-cluster>

              <!-- display endusers of selectedCustomer -->
              <div v-if="Object.values(switchStates).some(s=>s===true)">
                <l-marker
                  v-for="(enduser, index) in listOnMap"
                  :key="index"
                  :lat-lng="[...enduser.coords]"
                  :icon="generateIcon({backgroundColor:getIconColor(enduser, {hover: hoveredEnduser === enduser }), icon: getIcon(enduser), iconSize: [40, 38]})"
                  :z-index-offset="2000"
                  @mouseover="openPopup"
                  @mouseout="closePopup"
                  @click="centerMapOnEnduser(enduser)"
                >
                  <l-popup ref="popups" :key="index" :options="popupOptions">
                    <p>
                      {{ enduser.name }}
                    </p>
                  </l-popup>
                </l-marker>
              </div>
            </l-map>
          </div>
        </div>
      </div>
    </div>
    <b-modal :active.sync="showTable" scroll="keep" style="z-index: 1001;">
      <TabelView :si-data="siData" :customer="selectedCustomer" @onSelectRow="selectBarcodeFromTable"/>
    </b-modal>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { LMap, LTileLayer, LMarker, LPopup, LPolyline, LControlLayers } from 'vue2-leaflet'
import L from 'leaflet'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'

import IotMap from '@/services/v2/iotMap'
import Enduser from '@/services/v2/enduser'

import CrateDetailPanel from './crateDetailPanel.vue'
import TabelView from './table-view'

export default {
  name: 'track-crates',
  components: {
    'l-marker-cluster': Vue2LeafletMarkerCluster,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LPolyline,
    LControlLayers,
    CrateDetailPanel,
    TabelView
  },
  data () {
    return {
      zoomLevel: 3,
      mapCenter: [0, 0],
      showDetailPanel: true,
      showTable: false,
      isLoading: false,
      showEndusers: false,
      showProducers: false,
      endusers: [],
      validEndUsers: [],
      hoveredEnduser: null,
      selectedEnduser: null,
      showCrateMarkers: true,
      type: 'si',
      siData: [],
      selectedSi: null,
      dates: [],
      maxDate: null,
      minDate: null,
      polylineData: [],
      selectedDevice: null,
      availableBarcodes: [],
      points: [],
      producers: [],
      totalPoints: 0,
      activeCrates: [],
      totalActiveCrates: 0,
      consigneeTypes: [],
      consignees: [],
      listOnMap: [],
      switchStates: {},
      // url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoidGhvbWFzLW1vbGluYS1ncHMiLCJhIjoiY2puMWlhY2NqMGJydzNrbDhjejBnMjhkNyJ9.V2wNLvFQw1OnOqTrWN7xjA',
      url: 'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoidGhvbWFzLW1vbGluYS1ncHMiLCJhIjoiY2puMWlhY2NqMGJydzNrbDhjejBnMjhkNyJ9.V2wNLvFQw1OnOqTrWN7xjA',
      baseLayers: [
        {
          name: 'StreetMap',
          url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoidGhvbWFzLW1vbGluYS1ncHMiLCJhIjoiY2puMWlhY2NqMGJydzNrbDhjejBnMjhkNyJ9.V2wNLvFQw1OnOqTrWN7xjA',
          visible: true // Set as default layer
        },
        {
          name: 'SatelliteView',
          url: 'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoidGhvbWFzLW1vbGluYS1ncHMiLCJhIjoiY2puMWlhY2NqMGJydzNrbDhjejBnMjhkNyJ9.V2wNLvFQw1OnOqTrWN7xjA',
          visible: false
        }
      ],
      clusterOptions: {
        animate: false,
        removeOutsideVisibleBounds: false,
        chunkedLoading: true,
        disableClusteringAtZoom: 8,
        spiderfyOnMaxZoom: false
        // iconCreateFunction: (cluster) => divIcon({ html: '<div><span>' + cluster.getChildCount() + '</span></div>', className: 'marker-cluster', iconSize: new Point(40, 40) })
      },
      popupOptions: { closeButton: false }
    }
  },
  computed: {
    ...mapState({
      selectedCustomer: state => state.selectedCustomer
    })
  },
  watch: {
    selectedCustomer: async function () {
      await this.getEndusers()
      this.clearMap()
      await this.getCustomerSi()
    },
    async type () {
      if (this.type === 'si' && this.selectedSi) {
        this.polylineData = []
        this.showCrateMarkers = true
        await this.getDevicesBySi()
      }

      if (this.type === 'barcode' && this.selectedDevice) {
        await this.onMarkerClick(this.selectedDevice)
      }
    }
  },
  async mounted () {
    this.hideSideBar()
    await this.getCustomerSi()
    this.mapCenter = this.calMapCenter(this.points)
    this.centerMapOnClick(this.mapCenter)
    await this.getEndusers()
  },
  methods: {
    ...mapActions(['switchSideBar', 'hideSideBar', 'archiveAction']),
    toggleType (type) {
      if (this.switchStates[type]) {
        this.addConsigneeType(type)
      } else {
        this.removeConsigneeType(type)
      }
    },
    addConsigneeType (type) {
      this.listOnMap.push(...this.consignees[type])
    },
    removeConsigneeType (type) {
      this.listOnMap = this.listOnMap.filter(item => item.type !== type)
    },
    calMapCenter (data) {
      if (!data.length) return
      const first = data[0]
      const last = data.slice(-1)[0]
      return [
        (Number(first.lat) + Number(last.lat)) / 2,
        (Number(first.lon) + Number(last.lon)) / 2
      ]
    },
    async changeSelectedSi () {
      if (!this.selectedSi) return
      this.clearMap()
      await this.getDevicesBySi()
      this.centerMapOnClick(this.calMapCenter(this.points), 5)
    },
    async getCustomerSi () {
      this.isLoading = true
      this.points = []
      this.totalPoints = 0
      this.siData = await IotMap.getSiByCustomer({ customerId: this.selectedCustomer })
      this.flatSiData(this.siData)
      this.calActiveCrates(this.siData)
      this.isLoading = false
    },
    flatSiData (siData) {
      this.points = siData.flatMap(data => data.barcodes)
      this.totalPoints = this.points.length
    },
    calActiveCrates (siData) {
      this.activeCrates = siData.map(si => {
        return {
          ...si,
          barcodes: si.barcodes.filter(b => b.locationMethod !== 'sc')
        }
      })
      this.points = this.activeCrates.flatMap(d => d.barcodes)
      this.availableBarcodes = this.points
      this.totalActiveCrates = this.points.length
    },
    async getEndusers () {
      const result = await Enduser.byConsigneeType(this.selectedCustomer)
      this.consignees = this.filterData(result)
      this.consigneeTypes = Object.keys(this.consignees)
    },

    filterData (data) {
      const result = {}
      Object.keys(data).forEach(section => {
        const filtered = data[section].filter(entry => {
          return entry.lat && entry.lon
        })
        if (filtered.length) {
          result[section] = filtered
        }
      })
      return result
    },
    async getDevicesBySi () {
      const si = this.siData.filter(e => e.si === this.selectedSi)
      this.flatSiData(si)
      this.calActiveCrates(si)
    },
    setHoveredEnduser (enduser) {
      this.hoveredEnduser = enduser
    },
    clearHoveredEnduser () {
      this.hoveredEnduser = null
    },
    centerMapOnClick (coords, zoom = this.zoomLevel) {
      if (!coords?.length) return
      this.$refs.trackMapRef.mapObject.setView(L.latLng(coords[0], coords[1]), zoom)
    },
    centerMapOnEnduser (enduser) {
      this.centerMapOnClick([+enduser.lat, +enduser.lon], 10)
      this.selectedEnduser = enduser
    },
    openPopup (event) {
      let marker = event.target
      if (marker && marker.getPopup()) {
        marker.openPopup()
      }
    },

    closePopup (event) {
      let marker = event.target
      if (marker && marker.getPopup()) {
        marker.closePopup()
      }
    },
    createCustomIcon (point, index) {
      const color = this.getIconColor(point, {})
      return this.generateIcon({
        backgroundColor: color,
        text: index + 1,
        iconSize: [42, 40]
      })
    },
    async viewAll () {
      this.dates = []
      await this.getPolylineData(this.selectedDevice)
    },
    async onMarkerClick (device) {
      this.clearTrace()
      this.showCrateMarkers = false
      const si = this.siData.find(si => si.si === device.si)
      this.selectedSi = si.si
      this.totalPoints = si.barcodes.length
      this.totalActiveCrates = si.barcodes.filter(b => b.locationMethod !== 'sc').length
      this.selectedDevice = device
      if (!this.polylineData.length) await this.getPolylineData(device)
    },
    async getPolylineData (device) {
      try {
        this.isLoading = true
        const startDate = this.dates[0] ? this.$dayjs(this.dates[0]).format('YYYY-MM-DD') : ''
        const endDate = this.dates[1] ? this.$dayjs(this.dates[1]).format('YYYY-MM-DD') : ''
        const result = await IotMap.getOne(device.barcode, { customerId: device.customerId, si: device.si, startDate, endDate })
        this.polylineData = result
        if (!result.length) {
          this.$buefy.toast.open({ message: 'No data found', type: 'is-info' })
        } else {
          this.dates = [new Date(result[0].timestamp), new Date(this.$dayjs(result.slice(-1)[0].timestamp).add(1, 'd'))]
          this.centerMapOnClick(this.calMapCenter(this.polylineData), this.zoomLevel)
          this.showDetailPanel = true
        }
        this.isLoading = false
        return result
      } catch (e) {
        this.$buefy.toast.open({ message: e.message || e, type: 'is-danger' })
      }
    },
    async onApplyDate () {
      await this.onMarkerClick(this.selectedDevice)
    },
    clearTrace () {
      this.polylineData = []
      this.showCrateMarkers = true
      this.selectedDevice = null
      this.selectedEnduser = null
    },
    clearMap () {
      this.clearTrace()
      this.dates = []
      this.flatSiData(this.siData)
      this.type = 'si'
    },
    clearAndCenterMap () {
      this.clearMap()
      this.calActiveCrates(this.siData)
      this.selectedSi = null
      this.centerMapOnClick([0, 0], this.zoomLevel)
    },
    getIconColor (point, { hover = false }) {
      let color
      switch (point.type) {
        case 'PLANT':
          color = hover ? 'd9eddf' : '6abf9e'
          break
        case 'NATURAL PRODUCER':
          color = hover ? 'f7ebff' : '9d87de'
          break
        case 'ENDUSER':
          color = hover ? 'ffe6d6' : 'd57553'
          break
        case 'DISTRIBUTOR':
          color = hover ? 'f2f4cc' : 'babc96'
          break
        case 'CUSTOMER':
          color = hover ? 'd1f5ff' : '1ccdf0'
          break
        case 'CUSTOMER WAREHOUSE':
          color = hover ? 'e1f1ff' : '677798'
          break
        default:
          color = hover ? 'E1E1E1' : '9D9D9D'
          break
      }

      return color
    },
    getIcon (point) {
      let icon
      switch (point.type) {
        case 'PLANT':
          icon = 'warehouse'
          break
        case 'NATURAL PRODUCER':
          icon = 'tree'
          break
        case 'ENDUSER':
          icon = 'star'
          break
        case 'DISTRIBUTOR':
          icon = 'expand'
          break
        case 'CUSTOMER':
          icon = 'circle-user'
          break
        case 'CUSTOMER WAREHOUSE':
          icon = 'house-chimney-user'
          break
        default:
          icon = 'pin'
          break
      }

      return icon
    },
    generateIcon ({ iconSize, backgroundColor = '55987e', icon = 'cube', frontColor = 'FFF', text = null }) {
      return L.icon({
        iconUrl: text ? `https://mapmarker.io/api/v3/font-awesome/v6/pin?text=${text}&size=75&color=${frontColor}&background=${backgroundColor}&hoffset=0&voffset=0`
          : `https://mapmarker.io/api/v3/font-awesome/v6/pin?icon=fa-solid%20fa-${icon}&size=120&color=${frontColor}&background=${backgroundColor}&hoffset=0&voffset=0`,
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize, // Size of the icon
        iconAnchor: [19, 40], // Point of the icon which will correspond to marker's location
        popupAnchor: [1, -34], // Point from which the popup should open relative to the iconAnchor
        shadowSize: [35, 35],
        shadowAnchor: [5, 43] // Optional: Size of the shadow image
      })
    },
    nameWithId ({ name, id }) {
      return `${id} - ${name}`
    },
    selectEarliest () {
      if (!this.selectedDevice || !this.dates.length) return
      this.dates = [new Date(this.selectedDevice.delStartsOn), this.dates[1]]
    },
    selectTillToday () {
      if (!this.selectedDevice || !this.dates.length) return
      this.dates = [this.dates[0], new Date()]
    },
    selectBarcodeFromTable (barcode) {
      this.clearAndCenterMap()
      this.showTable = false
      this.onMarkerClick(barcode)
    },
    focusPoint (p) {
      const coords = [p.lat, p.lon]
      this.centerMapOnClick(coords, 8)
    }
  }
}
</script>

<style scoped>
.map-container {
  position: relative
}

.control-wrapper {
  position: absolute;
  top: 2%;
  left: 5%;
  display: flex;
  align-items: flex-start; /* Align button with the control vertically */
  z-index: 1000;
}

.map-control {
  /* position: absolute;
  top: 2%;
  left: 5%; */
  z-index: 1000;
}

.map-button {
  height: 48px;
  margin-left: 10px; /* Add some spacing */
  z-index: 1000;
}

.map-select {
  display: block;
  margin-top: 1.5rem;
}

.endusers-list {
    height: 75vh;
    list-style-type: none; /* Removes default list styling */
    padding: 10px;
    margin: 0;
    max-height: 90vh; /* Adjust height as needed */
    overflow-y: auto; /* Makes the list scrollable */
    background-color: #f9f9f9; /* Light background */
    border-radius: 8px; /* Rounded corners */
}

.endusers-list li {
    padding: 10px 20px;
    margin: 5px 0;
    background-color: #fff; /* White background for list items */
    border-radius: 4px; /* Rounded corners for list items */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transition for hover effect */
}

.endusers-list li:hover {
    background-color: #e9ecef; /* Slightly darker background on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Slightly larger shadow on hover */
    cursor: pointer; /* Changes the mouse cursor on hover */
}

/* Transition for sliding the panel */
.slide-enter-active, .slide-leave-active {
  transition: transform 0.3s ease; /* Adjust timing as needed */
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
  position: absolute;
  opacity: 0;
}

.slide-leave,
.slide-enter-to {
  opacity: 0;
}
</style>
