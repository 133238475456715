import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/resources'

const resources = axios.create({
  baseURL
})

interceptorsSetup(resources)

export default class Resource {
  static async downloadPdf (id) {
    try {
      const result = await resources.get(`/deliveries/${id}/`, {
        responseType: 'blob',
        headers: {
          'Accept': 'application/pdf'
        }
      })
      return result.data
    } catch {
      throw Error('Could not download requested file')
    }
  }
}
