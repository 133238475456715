import PickupManagementPage from '@/views/EndUserViews/pickupManagement'

import StockPage from '@/views/EndUserViews/Stock/stock-page'
import StockDetail from '@/views/EndUserViews/Stock/stock-detail'
import StockTotal from '@/views/EndUserViews/Stock/stock-summary'

import CollectionPage from '@/views/EndUserViews/Collections/collection-page'

const basePath = '/enduser'

export default [
  {
    path: basePath + '/stock',
    component: StockPage,
    children: [
      {
        path: 'detail',
        component: StockDetail,
        meta: { title: 'Stock Detail', authorize: ['EU Stock access'] }
      },
      {
        path: '',
        name: 'EU stock',
        meta: { title: 'Stock', authorize: ['EU Stock access'] },
        component: StockTotal
      }
    ]
  },
  {
    path: basePath + '/pickup-management',
    component: PickupManagementPage,
    name: 'EU pickup-management',
    meta: { title: 'Pickup Management', authorize: ['Pickup Access'] }
  },
  {
    path: basePath + '/collections',
    component: CollectionPage,
    name: 'EU collections',
    meta: { title: 'Collections', authorize: ['EU Collections access'] }
  }
]
