<template>
  <div id="gps-map" style="height: 93vh; width: 100%">
    <l-map
      ref="mapRef"
      style="z-index: 10"
      :world-copy-jump="true"
      :zoom="zoom"
      :min-zoom="3"
      :center="center"
      :bounds="computedBounds"
      @update:zoom="zoomUpdated"
      @update:center="centerUpdated"
      @update:bounds="boundsUpdated"
    >
      <l-tile-layer :url="url"/>

      <l-control position="bottomright">
        <map-legend id="legend" class="box is-hidden-touch"/>
      </l-control>
      <l-marker-cluster :options="clusterOptions">
        <l-marker
          v-for="marker in markers"
          :key="marker.mapMarker.id"
          :lat-lng="marker.mapMarker.getLatLng()"
          :icon="marker.mapMarker.getIcon()"
          @mouseover="marker.hoverEvent"
        >
          <l-tooltip :options="{ sticky: true }">
            <b>{{ marker.name }}</b>
            <br>{{ marker.city }}
            <br>{{ marker.country }}
          </l-tooltip>
        </l-marker>
      </l-marker-cluster>
    </l-map>
  </div>
</template>

<script>

import { GoogleProvider, GeoSearchControl } from 'leaflet-geosearch'
import { latLngBounds } from 'leaflet'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import MapLegend from '@/components/map/map-legend'
// import ItemInfo from '@/components/map/item-info'
import { LMap, LTileLayer, LMarker, LTooltip, LControl } from 'vue2-leaflet'

import { markerTypes } from '@/components/map/mTypes'

export default {
  name: 'leaflet-map',
  components: {
    'l-marker-cluster': Vue2LeafletMarkerCluster,
    MapLegend,
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LControl
    // ItemInfo
  },
  props: ['name', 'setHoverItem', 'setDestination', 'markers'],
  data () {
    return {
      url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoidGhvbWFzLW1vbGluYS1ncHMiLCJhIjoiY2puMWlhY2NqMGJydzNrbDhjejBnMjhkNyJ9.V2wNLvFQw1OnOqTrWN7xjA',
      zoom: 3,
      center: [47.413220, -1.219482],
      hoveredMarker: null,
      markerTypes: Array.from(markerTypes),
      clusterOptions: {
        animate: false,
        removeOutsideVisibleBounds: true,
        chunkedLoading: true,
        disableClusteringAtZoom: 8,
        spiderfyOnMaxZoom: false
        // iconCreateFunction: (cluster) => divIcon({ html: '<div><span>' + cluster.getChildCount() + '</span></div>', className: 'marker-cluster', iconSize: new Point(40, 40) })
      },
      geosearchOptions: { // Important part Here
        provider: new GoogleProvider({
          params: {
            key: 'AIzaSyCabL-Er3SaPVcNCld1orTRSJuyyYecOso'
          }
        })
      }
    }
  },
  computed: {
    computedBounds () {
      return latLngBounds(this.markers.map(e => e.mapMarker.getLatLng()))
    }
  },
  mounted: function () {
    this.$nextTick(() => {
      const searchControl = new GeoSearchControl({
        provider: new GoogleProvider({
          params: {
            key: 'AIzaSyCabL-Er3SaPVcNCld1orTRSJuyyYecOso'
          }
        })
      })
      if (this.$refs.mapRef) {
        this.$refs.mapRef.mapObject.addControl(searchControl)
      }
    })
  },
  methods: {
    zoomUpdated (zoom) {
      this.zoom = zoom
    },
    centerUpdated (center) {
      this.center = center
    },
    boundsUpdated (bounds) {
      this.bounds = bounds
    }
  }

}
</script>
<style lang="scss">
  .marker-cluster-gps {
    background-color: rgba(241, 90, 90, 0.3);
    div {
      background-color: rgba(241, 90, 90, 0.6);
    }
  }
</style>
