import axios from 'axios'
import decode from 'jwt-decode'
import interceptorsSetup from '@/helpers/interceptors'
import apolloClient from '@/plugins/apolloClient'
import gql from 'graphql-tag'

const ACCESS_TOKEN_KEY = 'token'
const USER_KEY = 'user'

const baseURL = process.env.VUE_APP_API_URL + '/auth'

const pass = axios.create({
  baseURL
})

interceptorsSetup(pass)
export default class Auth {
  static async login (username, password) {
    const response = await apolloClient.mutate({
      mutation: gql`mutation ($username: String!, $password: String!) {
        localLogin(username: $username, password: $password) {
          token
          user {
            id
            username
            displayName
            email
            lastConnection
            provider
            type
            role {
              name
              rights {
                id
                name
                description
              }
            }
            group {
              id
              name
              type
              customers {
                id
                name
              }
              endusers {
                id
                name
              }
              plants {
                id
                name
              }
              powerBis {
                id
                roles
              }
            }
          }
        }
      }`,
      variables: {
        username: username,
        password: password
      }
    })

    return response.data.localLogin
  }

  static async changePassword (currentPassword, newPassword) {
    const result = await pass.post('/change-password', {
      currentPassword: currentPassword,
      newPassword: newPassword
    })
    return result.data
  }

  static setUser (user) {
    localStorage.setItem(USER_KEY, JSON.stringify(user))
  }
  static getUser () {
    return JSON.parse(localStorage.getItem(USER_KEY))
  }
  static removeUser () {
    return localStorage.removeItem(USER_KEY)
  }

  static setToken (token) {
    localStorage.setItem(ACCESS_TOKEN_KEY, token)
  }

  static removeToken () {
    localStorage.removeItem(ACCESS_TOKEN_KEY)
  }

  static getAccessToken () {
    return localStorage.getItem(ACCESS_TOKEN_KEY)
  }

  static isLoggedIn () {
    const token = this.getAccessToken()
    return !!token && !this.isTokenExpired(token)
  }

  static getTokenIdentifier (encodedToken) {
    const token = decode(encodedToken)
    return token['sub']
  }

  static getTokenUser () {
    const user = decode(this.getAccessToken())
    return user
  }

  static getTokenExpirationDate (encodedToken) {
    const token = decode(encodedToken)
    if (!token.exp) {
      return null
    }

    const date = new Date(0)
    date.setUTCSeconds(token.exp)
    return date
  }

  static isTokenExpired (token) {
    const expirationDate = this.getTokenExpirationDate(token)
    return expirationDate < new Date()
  }
}
