<template>
  <div id="gps-map">
    <b-loading :active.sync="isLoading" :is-full-page="false"/>
    <div class="columns is-gapless">
      <div class="column is-2">
        <map-filter :markers="markers" @filter-changed="(markers) => filteredMarkers = markers"/>
        <div class="container is-fluid">
          <b-loading :is-full-page="false" :active.sync="isFetchingMarkerData"/>
          <div class="">
            {{ $t('global.name') }}: <b>{{ markerData ? markerData.name : '' }}</b> <br>
            {{ $t('global.stock') }}: <b>{{ markerData ? markerData.stockCount : 0 }}</b>
          </div>
        </div>
      </div>
      <div class="column">
        <VueLeafletMap v-if="!isLoading" :markers="filteredMarkers" name="leaflet"/>
      </div>
    </div>
  </div>
</template>

<script>
/* global L */
import VueLeafletMap from '@/components/map/vue-leafletmap'
// import Customer from '@/services/v2/customer'
import mapFilter from '@/components/map/map-filter'
import gql from 'graphql-tag'

// import * as markersTypes from '@/components/map/markersTypes'
import { mapState } from 'vuex'

export default {
  components: {
    VueLeafletMap,
    mapFilter
  },
  data: function () {
    return {
      isLoading: false,
      isFetchingMarkerData: false,
      markerData: null,
      markers: [],
      filteredMarkers: []
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      selectedCustomer: state => state.selectedCustomer
    })
  },
  watch: {
    selectedCustomer (newValue, oldValue) {
      this.generateMarkers()
    }
  },
  mounted: async function () {
    this.generateMarkers()
  },
  methods: {
    generateMarkers: async function () {
      this.isLoading = true
      this.markers = []

      const query = gql`
        query endusersByCustomerId ($customerId: ID!) {
          endusersByCustomerId (customerId: $customerId) {
            totalCount
            edges {
              node{
                id
                name
                country
                countryCode
                city
                postalCode
                address
                regionCategory
                partnerReference
                contractualRentalDays
                maxDaysLose
                lat
                lng
              }
            }
          }
        }
        `
      const result = await this.$apollo.query({
        query: query,
        variables: {
          customerId: this.$store.state.selectedCustomer
        }
      })

      let endusers = result.data.endusersByCustomerId.edges.map(e => e.node)

      endusers.forEach(e => {
        if (!(e.lng && e.lat)) return
        let marker = {
          ...e,
          type: 'enduser',
          hoverEvent: async () => {
            this.isFetchingMarkerData = true
            const query = gql`
                query partnerStock(
                  $customerId: ID!,
                  $enduserId: ID
                  ) {
                  partnerStock(
                    customerId: $customerId,
                    enduserId: $enduserId
                    )  {
                      totalCount
                    }
                  }`
            const result = await this.$apollo.query({
              query: query,
              variables: {
                customerId: this.$store.state.selectedCustomer,
                enduserId: e.id,
                limit: 0
              }
            })
            this.markerData =
            {
              name: e.name,
              stockCount: result.data.partnerStock.totalCount
            }
            this.isFetchingMarkerData = false
          },
          mapMarker: L.marker(
            [e.lat, e.lng],
            {
              icon: new L.Icon({
                iconUrl: `https://cdn.mapmarker.io/api/v1/pin?size=50&icon=fa-star&background=90A4AE`,
                shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                iconSize: [42, 40],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                shadowSize: [41, 41],
                shadowAnchor: [5, 43] // the same for the shadow
              })
            })
        }
        this.markers.push(marker)
      })

      this.isLoading = false
    }
  }

}
</script>
