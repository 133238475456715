<template>
  <div class="section">
    <div class="container is-fluid">
      <div class="columns is-multiline">
        <div class="column is-3 is-2-fullhd">
          <div class="button is-outlined" @click="passwordModalVisible = true">
            <b-icon icon="lock"/>
            <span>Change password</span>
          </div>
        </div>
      </div>
    </div>
    <b-modal :active.sync="passwordModalVisible" scroll="keep">
      <PasswordForm :is-submitting="isSubmitting" @submit="submitChangePassword"/>
    </b-modal>
  </div>
</template>

<script>
import PasswordForm from './password-form'
import Auth from '@/services/v1/auth'
import { mapActions, mapState } from 'vuex'
export default {
  components: {
    PasswordForm
  },
  data () {
    return {
      passwordModalVisible: false,
      isSubmitting: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    })
  },
  methods: {
    ...mapActions(['setIntroStep']),
    async restartTour () {
      await this.setIntroStep({ component: 'home', step: 0 })
      this.$router.push({ name: 'home' })
    },
    async submitChangePassword (creds) {
      this.isSubmitting = true
      await Auth.changePassword(creds.currentPassword, creds.newPassword)
      this.passwordModalVisible = false
      this.$buefy.toast.open({ type: 'is-primary', message: 'Your password has been changed successfully.' })

      this.isSubmitting = false
    }
  }
}
</script>

<style>

</style>
