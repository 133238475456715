var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"modal-card",staticStyle:{"width":"auto"}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(" Change password ")])]),_c('section',{staticClass:"modal-card-body"},[_c('ValidationProvider',{attrs:{"slim":"","rules":"required","name":"Current password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"message":errors,"type":{ 'is-danger': errors[0], 'is-success': valid },"label":"Current password"}},[_c('b-input',{attrs:{"type":"password","icon":"lock","password-reveal":""},model:{value:(_vm.currentPassword),callback:function ($$v) {_vm.currentPassword=$$v},expression:"currentPassword"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"slim":"","rules":"required","vid":"password","name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"message":errors,"type":{ 'is-danger': errors[0], 'is-success': valid },"label":"Password"}},[_c('b-input',{attrs:{"type":"password","icon":"lock","password-reveal":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"slim":"","rules":"required|confirmed:password","name":"Password Confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"message":errors,"type":{ 'is-danger': errors[0], 'is-success': valid },"label":"Confirm Password"}},[_c('b-input',{attrs:{"type":"password","icon":"lock","password-reveal":""},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1)]}}],null,true)})],1),_c('footer',{staticClass:"modal-card-foot"},[_c('button',{staticClass:"button",class:{ 'is-loading' : _vm.isSubmitting },attrs:{"disabled":invalid,"type":"submit"}},[_c('b-icon',{attrs:{"icon":"paper-plane"}}),_c('span',[_vm._v("Submit")])],1)])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }