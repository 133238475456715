<template>
  <div class="container is-fluid">
    <b-button
      icon-left="arrow-left"
      @click="hideEnduserList"
    >
      {{ $t(`${localeRoot}.back`) }}
    </b-button>
    <div class="card custom-card">
      <header>
        <p class="card-header-title has-text-grey">
          {{ $t(`${localeRoot}.title`) }}
        </p>
      </header>
      <div class="buttons">
        <b-button
          type="is-primary"
          icon-left="plus"
          size="is-small"
          outlined
          @click="isCreatingEnduser = true"
        >
          {{ $t(`${localeRoot}.new`) }}
        </b-button>
        <b-button
          type="is-link"
          icon-left="upload"
          size="is-small"
          outlined
          @click="importing = true"
        >
          {{ $t(`${localeRoot}.import`) }}
        </b-button>
      </div>

      <div class="field switchData">
        <b-switch
          v-model="isPendingEnduser"
        />
        <div>
          {{ $t(`${localeRoot}.pending_consignee`) }}
        </div>
      </div>
      <b-table
        :per-page="10"
        :narrowed="true"
        :striped="true"
        :paginated="true"
        :data="isPendingEnduser? pendingEndusers : endusers"
        :hoverable="true"
        default-sort="date"
        default-sort-direction="desc"
      >
        <b-table-column
          v-slot="props"
          field="partnerReference"
          :label="$t(`${localeRoot}` + '.partner_reference')"
          sortable
        >
          {{ props.row.partnerReference }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="name"
          :label="$t(`${localeRoot}` + '.name')"
          sortable
        >
          {{ props.row.name }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="city"
          :label="$t(`${localeRoot}` + '.city')"
          sortable
        >
          {{ props.row.city }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="country"
          :label="$t(`${localeRoot}` + '.country')"
          sortable
        >
          {{ props.row.country }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="address"
          :label="$t(`${localeRoot}` + '.address')"
          sortable
        >
          {{ isPendingEnduser? (props.row.street? props.row.street + ', ' : '' + props.row.street2 ? props.row.street2 + ', ' : '' + props.row.country) : props.row.address }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="postalCode"
          :label="$t(`${localeRoot}` + '.postal_code')"
          sortable
        >
          {{ props.row.postalCode }}
        </b-table-column>
      </b-table>
    </div>
    <b-modal :active.sync="importing" scroll="keep" has-modal-card :on-cancel="() => {file = null}">
      <div>
        <div class="modal-card" style="min-height: 300px">
          <header class="modal-card-head">
            <p
              class="modal-card-title"
            >
              {{ $t(`${localeRoot}.import_modal.title`) }}
            </p>
          </header>
          <section class="modal-card-body">
            <div class="center">
              <b-field class="file">
                <b-upload
                  v-model="file"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  @input="parceFile"
                >
                  <a class="button is-primary">
                    <b-icon icon="upload"/>
                    <span>{{ $t(`${localeRoot}.import_modal.import`) }}</span>
                  </a>
                </b-upload>
                <span v-if="file" class="file-name">
                  {{ file.name }}
                </span>
              </b-field>
              <i class="text-center mb-5">*accept only cvs and xlsx file</i>
              <b-field class="file">
                <b-button class="button is-info" @click="downloadTemplate">
                  <b-icon icon="download"/>
                  <span>{{ $t(`${localeRoot}.import_modal.example`) }}</span>
                </b-button>
              </b-field>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button
              class="button is-fullwidth"
              @click="importing = false"
            >
              {{ $t(`${localeRoot}.import_modal.close`) }}
            </button>
            <button
              :class=" {'is-loading' : isSubmitting}"
              type="submit"
              class="button is-info is-fullwidth"
              @click="submitEnduserFile"
            >
              {{ $t(`${localeRoot}.import_modal.submit`) }}
            </button>
          </footer>
        </div>
      </div>
    </b-modal>
    <b-modal full-screen :active.sync="isCreatingEnduser" scroll="keep" has-modal-card>
      <EnduserForm
        :is-submitting="isSubmitting"
        :customer-id="selectedCustomer"
        @submit="submitNewEnduser"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ShippingManagement from '@/services/v2/shippingManagement'
import EnduserForm from './enduser-form'
import Papa from 'papaparse'
import { read, utils } from 'xlsx'
import { saveAs } from 'file-saver'

export default {
  components: {
    EnduserForm
  },
  props: {
    endusers: {
      type: Array,
      default: null
    },
    hideEnduserList: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      localeRoot: 'customer.shipping_management.enduserList',
      importing: false,
      file: null,
      isSubmitting: false,
      isCreatingEnduser: false,
      isPendingEnduser: false,
      pendingEndusers: null,
      importEndusers: [],
      importContact: {
        contactName: '',
        contactTel: '',
        contactEmail: ''
      },
      baseUrl: process.env.VUE_APP_API_URL_NEW
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      selectedCustomer: state => state.selectedCustomer
    })
  },
  watch: {
    selectedCustomer (oldValue, newValue) {
      this.getPendingEnduser()
    }
  },
  async mounted () {
    this.getPendingEnduser()
  },
  methods: {
    parceFile (file) {
      if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        const reader = new FileReader()
        reader.onload = (e) => {
          const binaryStr = e.target.result
          const workbook = read(binaryStr, { type: 'binary' })

          const firstSheetName = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[firstSheetName]

          const jsonData = utils.sheet_to_json(worksheet)
          this.importEndusers = jsonData
        }
        reader.readAsBinaryString(file)
      } else if (file.type === 'text/csv') {
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: (result) => {
            const jsonData = result.data
            this.importEndusers = jsonData
          }
        })
      }
    },
    submitEnduserFile: async function () {
      this.isSubmitting = true
      try {
        await ShippingManagement.createPendingEnduser({ customerId: this.selectedCustomer, endusers: this.importEndusers })
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message || error,
          type: 'is-danger'
        })
      }

      // 0 => category id of endusers， 1 for customers
      await ShippingManagement.uploadEndusers(this.selectedCustomer, 1, this.file)
      this.isSubmitting = false
      this.hideEnduserList()
      this.$buefy.toast.open({
        message: this.$t(`${this.localeRoot}.import_modal.upload_done`),
        type: 'is-primary'
      })
    },

    getPendingEnduser: async function () {
      this.pendingEndusers = await ShippingManagement.getPendingEnduser(this.selectedCustomer)
    },
    submitNewEnduser: async function (data) {
      try {
        this.isSubmitting = true
        await ShippingManagement.createPendingEnduser({ customerId: data.customerId, enduser: data.enduser })
        this.isCreatingEnduser = false
        this.getPendingEnduser()
        this.$buefy.toast.open({
          message: 'done!',
          type: 'is-primary'
        })
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message || error,
          type: 'is-danger'
        })
      }
      this.isSubmitting = false
    },
    async downloadTemplate () {
      const file = await ShippingManagement.downloadTemplate()
      saveAs(file, 'template_consignees.xlsx')
    }
  }
}
</script>

<style scoped>
.text-center {
 text-align: center;
}
.custom-card {
  padding: 10px;
  margin: 10px;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.switchData{
  display: flex;
  align-items: center;
  justify-items: center;
  margin-top: 20px;
}
.switchData div {
  margin-right: 10px;
}
</style>
