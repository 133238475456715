import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import authModule from './modules/auth/index'

Vue.use(Vuex)
const state = {
  visibleSidebar: true,
  selectedCustomer: null,
  customers: [],
  endUsers: []
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    auth: authModule
  }
})
