import * as types from './mutation-types'
// import Vue from 'vue'
// MUTATIONS
const mutations = {
  [types.SET_CUSTOMERS] (state, customers) {
    state.customers = customers
  },
  [types.SET_SELECTED_CUSTOMER] (state, customer) {
    state.selectedCustomer = customer
  },
  [types.SET_END_USERS] (state, endUsers) {
    state.endUsers = endUsers
  },
  [types.SWITCH_SIDE_BAR] (state) {
    state.visibleSidebar = !state.visibleSidebar
  },
  [types.HIDE_SIDE_BAR] (state) {
    state.visibleSidebar = false
  }
}
export default mutations
