
export default {
  methods: {
    getLocalFlagString (lang) {
      const providedLang = lang || this.$i18n.locale
      switch (providedLang) {
        case 'en':
          return 'us'
        case 'fr':
          return 'fr'
        case 'ru':
          return 'ru'
        case 'zh':
          return 'cn'
        case 'kr':
          return 'kr'
        case 'pt':
          return 'pt'
        default:
          break
      }
    },
    getLangShortName (lang) {
      switch (lang) {
        case 'cn':
          return 'zh'
        case 'fr':
          return 'fr'
        case 'ru':
          return 'ru'
        case 'zh':
          return 'cn'
        case 'kr':
          return 'kr'
        case 'pt':
          return 'pt'
        default:
          break
      }
    },
    getLangName (lang) {
      switch (lang) {
        case 'en':
          return 'English'
        case 'fr':
          return 'French'
        case 'ru':
          return 'Russian'
        case 'zh':
          return 'Chinese'
        case 'kr':
          return 'Korean'
        default:
          return 'Unknown'
      }
    }
  }
}
