<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-narrow">
        <p class="field">
          <router-link to="/stock" class="button is-grey">
            <b-icon icon="arrow-left" size="is-small"/>
            <span>{{ $t('global.back') }}</span>
          </router-link>
        </p>
      </div>
      <div class="column">
        <nav class="level">
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">
                {{ $t('global.name') }}
              </p>
              <p>{{ partner.name || 'N/A' }}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">
                {{ $t('global.country') }}
              </p>
              <p>{{ partner.country || 'N/A' }}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">
                {{ $t('global.city') }}
              </p>
              <p>{{ partner.city || 'N/A' }}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">
                {{ $t('global.total') }}
              </p>
              <p>{{ total }}</p>
            </div>
          </div>
        </nav>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12">
        <gridManager
          :grid-id="gridId"
          :profiles="gridProfiles"
          @save="saveProfile"
          @create="createProfile"
          @update="updateProfile"
          @select="selectProfile"
          @delete="deleteProfile"
        />
        <ag-grid-vue
          :id="gridId"
          style="width: 100%; height: 750px;"
          class="ag-theme-balham"
          :grid-options="gridOptions"
          :get-child-count="getChildCount"
          :pagination="true"
          :pagination-auto-page-size="true"
          :side-bar="['columns']"
          :cache-overflow-size="0"
          row-selection="multiple"
          :multi-sort-key="'ctrl'"
          :get-context-menu-items="getContextMenuItems"
          :modules="modules"
          @grid-ready="onGridReady"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import sortAndFilterMixins from '@/mixins/agGridSortAndFilter'
import GridManager from '@/components/common/grid-manager'
import gql from 'graphql-tag'
import XLSX from 'xlsx'
import { AllModules } from '@ag-grid-enterprise/all-modules'

export default {
  name: 'stock-detail',
  components: {
    GridManager
  },
  mixins: [sortAndFilterMixins],

  data: function () {
    return {
      gridId: 'stock-detail',
      modules: AllModules,
      gridOptions: {
        rowModelType: 'serverSide',
        enableRangeSelection: true,
        defaultColDef: {
          floatingFilter: true,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: false,
          resizable: true
        },
        columnDefs: [
          { headerName: this.$t('customer.stock.details.grid.barcode'), field: 'barcode', filter: 'agTextColumnFilter', sortable: true, filterParams: { suppressAndOrCondition: true }, width: 100 },
          { headerName: this.$t('customer.stock.details.grid.material_number'), field: 'csRefMaterialNumber', filter: 'agTextColumnFilter', sortable: true, filterParams: { suppressAndOrCondition: true }, width: 160 },
          { headerName: this.$t('customer.stock.details.grid.delivery_number'), field: 'csRefDeliveryNumber', filter: 'agTextColumnFilter', sortable: true, filterParams: { suppressAndOrCondition: true }, width: 160 },
          { headerName: this.$t('customer.stock.details.grid.delivery_date'),
            field: 'deliveryDate',
            filter: 'agDateColumnFilter',
            sortable: true,
            filterParams: { suppressAndOrCondition: true },
            width: 140,
            cellRenderer: (data) => {
              return data.value && new Date(data.value).toLocaleDateString()
            }
          },
          { headerName: this.$t('customer.stock.details.grid.ship_to'), field: 'csRefConsignee', filter: 'agTextColumnFilter', sortable: true, filterParams: { suppressAndOrCondition: true }, width: 160 },
          { headerName: this.$t('customer.stock.details.grid.shipping_date'),
            field: 'lastShipToDate',
            filter: 'agDateColumnFilter',
            sortable: true,
            filterParams: { suppressAndOrCondition: true },
            width: 140,
            cellRenderer: (data) => {
              return data.value && new Date(data.value).toLocaleDateString()
            }
          },
          { headerName: this.$t('customer.stock.details.grid.sales_order_number'), field: 'csRefOrderNumber', filter: 'agTextColumnFilter', sortable: true, filterParams: { suppressAndOrCondition: true }, width: 160 },
          { headerName: this.$t('customer.stock.details.grid.po_number'), field: 'csRefPurchaseOrderNumber', filter: 'agTextColumnFilter', sortable: true, filterParams: { suppressAndOrCondition: true }, width: 160 },
          { headerName: this.$t('customer.stock.details.grid.info'), field: 'csRefInfo', filter: 'agTextColumnFilter', sortable: true, filterParams: { suppressAndOrCondition: true }, width: 160 },
          { headerName: this.$t('customer.stock.details.grid.sun_number'), field: 'csRefCrateNumber', filter: 'agTextColumnFilter', sortable: true, filterParams: { suppressAndOrCondition: true }, width: 160 },
          { headerName: this.$t('customer.stock.details.grid.batch_number'), field: 'csRefBatchNumber', filter: 'agTextColumnFilter', sortable: true, filterParams: { suppressAndOrCondition: true }, width: 160 },
          { headerName: this.$t('customer.collections.details.transitTime'), field: 'transitTime', filter: 'agTextColumnFilter', sortable: true, filterParams: { suppressAndOrCondition: true }, width: 160 },
          { headerName: this.$t('customer.stock.details.grid.rentalDays'), field: 'rentalDays', filter: 'agNumberColumnFilter', sortable: true, filterParams: { suppressAndOrCondition: true }, width: 100 },
          { headerName: this.$t('customer.stock.details.grid.hiringPeriod'), field: 'hiringPeriod', filter: 'agNumberColumnFilter', sortable: true, filterParams: { suppressAndOrCondition: true }, width: 100 },
          { headerName: this.$t('customer.stock.details.grid.expectedArrivalDate'),
            field: 'expectedArrivalDate',
            filter: 'agDateColumnFilter',
            sortable: true,
            filterParams: { suppressAndOrCondition: true },
            width: 100,
            cellRenderer: (data) => {
              return data.value && new Date(data.value).toLocaleDateString()
            } },
          { headerName: this.$t('customer.stock.details.grid.csRefcycleTime'), field: 'csRefcycleTime', filter: 'agNumberColumnFilter', sortable: true, filterParams: { suppressAndOrCondition: true }, width: 100 },

          { headerName: this.$t('customer.stock.details.grid.lost_date'),
            field: 'deemedLost',
            filter: 'agDateColumnFilter',
            sortable: true,
            filterParams: { suppressAndOrCondition: true },
            width: 150,
            cellRenderer: (data) => {
              return data.value && new Date(data.value).toLocaleDateString()
            } }
        ]
      },
      isLoading: false,
      isDownloading: false,
      partner: {},
      total: 0,
      stock: [],
      gridProfiles: []
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    })
  },
  mounted: async function () {
    if (this.$route.query.enduserId) {
      const enduserId = this.$route.query.enduserId
      const query = gql`
        query enduser(
          $enduserId: ID!,
        ) {
          enduser(
            enduserId: $enduserId,
            ) {
           name
           country
           city
          }
        }`
      const result = await this.$apollo.provider.defaultClient.query({
        query: query,
        variables: {
          enduserId: enduserId
        }
      })
      this.partner = result.data.enduser
      this.partner.type = 'enduser'
    } else if (this.$route.query.customerId) {
      const customerId = this.$route.query.customerId
      const query = gql`
        query customer(
          $customerId: ID!,
        ) {
          customer(
            customerId: $customerId,
            ) {
           name
           country
           city
          }
        }`
      const result = await this.$apollo.provider.defaultClient.query({
        query: query,
        variables: {
          customerId: customerId
        }
      })
      this.partner = result.data.customer
      this.partner.type = 'customer'
    }
  },
  methods: {
    getContextMenuItems (params) {
      const result = [
        'copy',
        'copyWithHeaders',
        'separator',
        {
          name: 'Export',
          icon: '<span class="ag-icon ag-icon-save" unselectable="on"></span>',
          subMenu: [
            {
              name: 'CSV Export',
              action: () => {
                this.csvDownload()
              }
            },
            {
              name: 'Excel Export (.xlsx)',
              action: () => {
                this.xlsxDownload()
              }
            }
          ]
        }
      ]
      return result
    },

    onGridReady: async function (params) {
      const dataSource = {
        getRows: async (params) => {
          const groupSummary = this.getGroups(params.request)
          let columns = groupSummary.filter(e => e.isHere === true).map(e => e.column.toString())
          if (!columns.length) {
            columns = this.gridOptions.columnDefs.map(colDef => colDef.field)
          }

          const builder = this.$gql.query({
            operation: 'partnerStock',
            fields: ['totalCount', { edges: [{ node: [{ aggregate: [{ count: ['barcode'] }, ...this.getAggregate(params.request)] }, ...columns] }] }],
            variables: {
              skip: { type: 'Int', value: params.request.startRow },
              limit: { type: 'Int', value: params.request.endRow },
              sort: { type: '[sortInputPartnerStock]', value: this.getSorts(params.request) },
              filter: { type: 'filterInputPartnerStock', value: this.getFilters(params.request) },
              group: { type: 'Boolean', value: !!groupSummary.find(e => e.isHere === true) },
              customerId: { type: 'ID!', value: this.$store.state.selectedCustomer },
              enduserId: { type: 'ID', value: this.$route.query.enduserId }
            }
          })

          const result = await this.$apollo.query({
            fetchPolicy: 'no-cache',
            query: gql`${builder.query}`,
            variables: builder.variables })

          const stockResult = result.data.partnerStock
          this.total = stockResult.totalCount
          this.stock = stockResult.edges.map(e => e.node)
          params.successCallback(this.stock, this.total)
        }
      }
      params.api.setServerSideDatasource(dataSource)
    },
    getChildCount: function (data) {
      if (data) {
        return data.aggregate.count.barcode
      }
    },
    workbook: async function () {
      const request = this.gridOptions.api.getModel().cacheParams

      const builder = this.$gql.query({
        operation: 'partnerStock',
        fields: ['totalCount', { edges: [{ node: [...this.gridOptions.columnDefs.map(colDef => colDef.field)] }] }],
        variables: {
          // skip: { type: 'Int', value: params.request.startRow },
          // limit: { type: 'Int', value: params.request.endRow },
          sort: { type: '[sortInputPartnerStock]', value: this.getSorts(request) },
          filter: { type: 'filterInputPartnerStock', value: this.getFilters(request) },
          // group: { type: 'Boolean', value: !!groupSummary.find(e => e.isHere === true) },
          customerId: { type: 'ID!', value: this.$store.state.selectedCustomer },
          enduserId: { type: 'ID', value: this.$route.query.enduserId }
        }
      })

      const result = await this.$apollo.query({
        fetchPolicy: 'no-cache',
        query: gql`${builder.query}`,
        variables: builder.variables })

      const columnsDefs = this.gridOptions.columnApi.getAllDisplayedColumns().map(col => col.colDef)
      const csvFields = columnsDefs.map(e => e.field)
      const csvColumns = columnsDefs.map(e => e.headerName)
      const csvContent = result.data.partnerStock.edges.map(e => {
        return csvFields.map(field => e.node[field])
      }
      )
      const data = [csvColumns, ...csvContent]
      const worksheet = XLSX.utils.aoa_to_sheet(data)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'SheetJS')
      return workbook
    },
    csvDownload: async function () {
      XLSX.writeFile(await this.workbook(), 'export.csv')
    },
    xlsxDownload: async function () {
      XLSX.writeFile(await this.workbook(), 'export.xlsx')
    }
  }
}
</script>
