<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-narrow">
        <div id="stock-total-review-card" class="card">
          <header class="card-header">
            <p class="card-header-title has-text-grey">
              {{ $t('customer.stock.summary.review') }}
            </p>
          </header>
          <div class="card-content">
            <div class="content">
              <dl>
                <dt>
                  {{ $t('customer.stock.summary.crates_consignee_plant') }} : <strong>{{ total }}</strong>
                </dt>
                <dt>
                  {{ $t('customer.stock.summary.crates_customer_plant') }} : <strong>{{ customerStockCount }}</strong>
                  <router-link :to="{ path: 'stock/detail', query: { customerId: selectedCustomer }}">
                    {{ $t('global.detail') }}
                  </router-link>
                </dt>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div id="stock-total-tab-container" class="column is-12">
        <b-field grouped>
          <gridManager
            :grid-id="gridId"
            :profiles="gridProfiles"
            @save="saveProfile"
            @create="createProfile"
            @update="updateProfile"
            @select="selectProfile"
            @delete="deleteProfile"
          />
          <div class="field">
            <b-button icon-left="globe" type="is-primary" :loading="isLoadingGlobalExport" @click="globalExport">
              Global export
            </b-button>
          </div>
        </b-field>

        <ag-grid-vue
          :id="gridId"
          style="width: 100%; height: 700px;"
          class="ag-theme-alpine"
          :row-data="filteredEndusers"
          :grid-options="gridOptions"
          :pagination="true"
          :pagination-auto-page-size="true"
          :side-bar="['columns']"
          :get-context-menu-items="getContextMenuItems"
          :multi-sort-key="'ctrl'"
          :enable-range-selection="true"
          :enable-charts="true"
          :modules="modules"
          @grid-ready="gridReady"
        />
      </div>
    </div>
  </div>
</template>

<script>
import sortAndFilterMixins from '@/mixins/agGridSortAndFilter'
import gql from 'graphql-tag'
import GridManager from '@/components/common/grid-manager'
import { mapState } from 'vuex'
import { AllModules } from '@ag-grid-enterprise/all-modules'
import Customer from '@/services/v2/customer'
import { saveAs } from 'file-saver'

export default {
  name: 'stock',
  components: {
    GridManager
  },
  mixins: [sortAndFilterMixins],
  data () {
    return {
      gridId: 'enduser-stock',
      customerStockCount: 0,
      popupParent: null,
      modules: AllModules,
      gridOptions: {
        enableBrowserTooltips: true,
        defaultColDef: {
          floatingFilter: true,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: true,
          sortable: true,
          filter: true,
          resizable: true
        },
        columnDefs: [
          { headerName: this.$t('global.name'), field: 'name', filter: 'agTextColumnFilter', width: 280, sort: 'asc' },
          { headerName: this.$t('global.country'), field: 'country', cellRenderer: this.flagRender, filter: 'agTextColumnFilter', width: 120 },
          { headerName: this.$t('global.city'), field: 'city', filter: 'agTextColumnFilter', width: 180 },
          { headerName: this.$t('global.category'), field: 'regionCategory', filter: 'agTextColumnFilter', width: 100 },
          { headerTooltip: this.$t('tooltips.shipto'), headerName: this.$t('global.ship_to_code'), field: 'aggregate.concat.partnerReference', filter: 'agTextColumnFilter', width: 170 },
          { headerTooltip: this.$t('tooltips.expected_transit_time'), headerName: this.$t('global.expectedTransitTime'), field: 'expectedTransitTime', filter: 'agNumberColumnFilter', sortable: true, width: 100 },
          { headerTooltip: this.$t('tooltips.hiring_period'), headerName: this.$t('customer.stock.summary.grid.hiring_period'), field: 'hiringPeriod', filter: 'agNumberColumnFilter', width: 100 },
          { headerTooltip: this.$t('tooltips.usage_days'), headerName: this.$t('global.usageDays'), field: 'usageDays', filter: 'agNumberColumnFilter', sortable: true, width: 100 },
          { headerTooltip: this.$t('tooltips.enduser_stock_quantity'), headerName: this.$t('customer.stock.summary.grid.stock'), field: 'stock.quantity', filter: 'agNumberColumnFilter', width: 130 },
          { headerTooltip: this.$t('tooltips.contractual_cycle_time'), headerName: this.$t('customer.stock.summary.grid.agreed_cycle_time'), field: 'contractualRentalDays', filter: 'agNumberColumnFilter', width: 100 },
          { headerTooltip: this.$t('tooltips.average_cycle_time'),
            headerName: this.$t('customer.stock.summary.grid.today_cycle_time'),
            field: 'stock.averageRentalDays',
            filter: 'agNumberColumnFilter',
            width: 100,
            cellClassRules: {
            // apply green to 2008
              'rag-green': function (params) { if (params.data) return params.value <= params.data.contractualRentalDays },
              // apply amber 2004
              'rag-orange': function (params) { if (params.data) return params.value > params.data.contractualRentalDays && params.value <= params.data.contractualRentalDays + 250 },
              // apply red to 2000
              'rag-red': function (params) { if (params.data) return params.value > params.data.contractualRentalDays + 250 }
            } },
          { headerTooltip: this.$t('tooltips.over_ct'), headerName: this.$t('customer.stock.summary.grid.over_agreed_cycle_time'), field: 'stock.exceededContractualDays', filter: 'agNumberColumnFilter', width: 100 },
          { headerTooltip: this.$t('tooltips.storage_plant'), headerName: this.$t('customer.stock.summary.grid.days_at_plant'), field: 'stock.averageDaysAtCustomerPlant', filter: 'agNumberColumnFilter', width: 100 },
          { headerTooltip: this.$t('tooltips.transit_time'), headerName: this.$t('customer.stock.summary.grid.transit_time'), field: 'stock.averageTransitDays', filter: 'agNumberColumnFilter', width: 100 },
          { headerTooltip: this.$t('tooltips.storage_enduser'), headerName: this.$t('customer.stock.summary.grid.days_at_enduser'), field: 'stock.averageDaysAtEnduser', filter: 'agNumberColumnFilter', width: 100 },
          { headerTooltip: this.$t('tooltips.deemed_lost'), headerName: this.$t('customer.stock.summary.grid.deemed_as_lost'), field: 'stock.deemedLost', filter: 'agNumberColumnFilter', width: 100 }
        ]
      },
      isLoading: false,
      isLoadingGlobalExport: false,
      endusers: null,
      customer: {}
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      selectedCustomer: state => state.selectedCustomer
    }),
    total: function () {
      return this.endusers ? this.endusers.reduce((acc, item) => {
        return acc + (item.stock?.quantity || 0)
      }, 0) : 0
    },
    filteredEndusers: function () {
      let { endusers } = this
      endusers = endusers ? endusers.filter(row => row.stock?.quantity) : null
      return endusers
    }
  },
  watch: {
    selectedCustomer (newValue, oldValue) {
      this.getStock()
    }
  },

  methods: {
    async gridReady () {
      await this.getStock()
    },
    getContextMenuItems (params) {
      let selectedEnduser = params.node.data

      let customMenu = []
      const genericMenu = [
        'copy',
        'copyWithHeaders',
        'separator',
        'export'
      ]
      if (selectedEnduser) {
        customMenu.push(
          {
            name: 'Detail',
            action: () => {
              this.$router.push({
                path: 'stock/detail',
                query: {
                  enduserId: selectedEnduser.id
                }
              })
            },
            icon: '<span class="ag-icon ag-icon-data" unselectable="on"></span>'
          }
        )
      }

      return [...customMenu, ...genericMenu]
    },
    flagRender: function (params) {
      if (params.data) {
        const flag = `<img src="https://flagcdn.com/w20/${params.data.countryCode.toLowerCase()}.png" style="margin-left: 2px;margin-bottom: -4px;margin-right: 4px;">`
        return flag + ' ' + params.value
      } else {
        return params.value
      }
    },
    globalExport: async function () {
      this.isLoadingGlobalExport = true
      const result = await Customer.getGlobalStock({ customerId: this.$store.state.selectedCustomer })
      const formatedResult = result.map(e => {
        const enduser = this.endusers.find(enduser => enduser.id === e.lastShipTo)
        return {
          enduserName: enduser.name.split('.,').join(' '),
          enduserCountry: enduser.country,
          enduserCity: enduser.city.split(',').join(' '),
          enduserConsignee: e.csRefConsignee,
          barcode: e.barcode,
          enduserStockQuantity: enduser.stock.quantity,
          deliveryDate: e.deliveryDate,
          shippingDate: e.lastShipToDate,
          expectedArrivalDate: e.expectedArrivalDate,
          enduserDeliveryNumber: e.csRefDeliveryNumber,
          enduserCycleTime: e.csRefcycleTime,
          enduserUsageDays: e.csRefUsageDays,
          expectedTransitTime: e.expectedTransitTime,
          hiringPeriod: e.hiringPeriod,
          rentalDays: e.rentalDays,
          deemedLost: e.deemedLost,
          enduserStockAverageDaysAtPlant: enduser.stock.averageDaysAtCustomerPlant,
          enduserStockAverageDaysAtEnduser: enduser.stock.averageDaysAtEnduser,
          enduserStockAverageRentalDays: enduser.stock.averageRentalDays,
          enduserStockExceedContractualDays: enduser.stock.exceededContractualDays,
          enduserStockDeemedLost: enduser.stock.deemedLost
        }
      })
      if (formatedResult.length) {
        const columns = Object.keys(formatedResult[0]).join(';')
        const values = formatedResult.map(e => Object.values(e).join(';'))
        const final = [columns, ...values]

        let blob = new Blob([final.join('\n')], { type: 'text/csv' })
        saveAs(blob, 'stockGE.csv')
      }
      this.isLoadingGlobalExport = false
    },
    getStock: async function () {
      this.isLoading = true
      const columns = [
        'id',
        'name',
        'country',
        'countryCode',
        'city',
        'regionCategory',
        'fromCustomerId',
        'contractualRentalDays',
        'maxDaysLose',
        'usageDays',
        'expectedTransitTime',
        'hiringPeriod',
        {
          stock: ['quantity', 'averageRentalDays', 'exceededContractualDays', 'averageDaysAtCustomerPlant', 'averageTransitDays', 'averageDaysAtEnduser', 'deemedLost']
        }
      ]

      const stockBuilder = this.$gql.query({
        operation: 'partnerStock',
        fields: ['totalCount'],
        variables: {
          customerId: { type: 'ID!', value: this.$store.state.selectedCustomer },
          limit: 0
        }
      })

      const enduserBuilder = await this.$gql.query({
        operation: 'endusersWWHByCustomerId',
        fields: ['totalCount', { edges: [{ node: [{ aggregate: [{ concat: ['partnerReference'] }] }, ...columns] }] }],
        variables: {
          customerId: { type: 'ID!', value: this.$store.state.selectedCustomer },
          group: true
        }
      })

      const [stockResult, enduserResult] = await Promise.all([
        this.$apollo.query({
          fetchPolicy: 'no-cache',
          query: gql`${stockBuilder.query}`,
          variables: stockBuilder.variables }),
        this.$apollo.query({
          fetchPolicy: 'no-cache',
          query: gql`${enduserBuilder.query}`,
          variables: enduserBuilder.variables })
      ])
      this.customerStockCount = stockResult.data.partnerStock.totalCount
      this.endusers = enduserResult.data.endusersWWHByCustomerId.edges.map(e => e.node)

      this.isLoading = false
    }
  }
}
</script>
