<template>
  <section class="section">
    <div class="container is-fluid">
      <router-view/>
    </div>
  </section>
</template>

<script>
export default {

  data () {
    return {
    }
  }
}
</script>

<style>

</style>
