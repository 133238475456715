<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p
        class="modal-card-title"
      >
        {{ $t(`customer.shipping_management.auto_assign.title`) }}
      </p>
    </header>
    <section class="modal-card-body">
      <i>{{ $t(`customer.shipping_management.auto_assign.description`) }}</i>
      <div v-for="obj in autoAssignObject" :key="obj.id">
        <div class="custom-container row has-background-white-ter" :class="obj.checked ? 'checked' : 'not-checked'" @click="checkRow(obj)">
          <div class="space-around">
            <div class="d-row">
              <div class="d-col">
                <span class="row-title">
                  {{ $t(`customer.shipping_management.auto_assign.reference`) }}
                </span>
                <span>
                  {{ obj.reference }}
                </span>
              </div>
              <div class="d-col">
                <span class="row-title">
                  {{ $t(`customer.shipping_management.auto_assign.reference_complement`) }}
                </span>
                <span>
                  {{ obj.referenceComplement }}
                </span>
              </div>
              <div class="d-col">
                <span class="row-title">
                  {{ $t(`customer.shipping_management.auto_assign.date`) }}
                </span>
                <span>
                  {{ obj.formatedDate }} - {{ obj.formatedhour }}
                </span>
              </div>
            </div>
          </div>

          <div class="d-col-center">
            <span>
              {{ $t(`customer.shipping_management.auto_assign.enduser`) }}
            </span>
            <span class="has-text-success">
              {{ obj.enduser.name || "ERROR" }}
            </span>
          </div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button
        class="button is-fullwidth"
        @click="$parent.close()"
      >
        {{ $t(`customer.shipping_management.auto_assign.cancel`) }}
      </button>
      <button
        :class=" {'is-loading' : isSubmitting}"
        type="submit"
        class="button is-info is-fullwidth"
        @click="submitData"
      >
        {{ $t(`customer.shipping_management.auto_assign.submit`) }}
      </button>
    </footer>
  </div>
</template>

<script>
import ShippingManagement from '@/services/v2/shippingManagement'

export default {
  props: {
    autoAssignObject: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      isSubmitting: false
    }
  },
  methods: {
    submitData: async function () {
      const checked = this.autoAssignObject.filter(s => s.checked)
      await ShippingManagement.updateShippingsEnduser(checked)
      this.$buefy.toast.open({
        type: 'is-primary',
        message: this.$t(`customer.shipping_management.auto_assign.done`),
        duration: 3000
      })
      this.$parent.close()
    },
    checkRow (row) {
      row.checked = row.checked === null ? true : !row.checked
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped>
.d-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 2rem;
}

.d-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.space-around {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.custom-container {
  margin-bottom: 1rem;
  /* background-color: #6abf9e; */
  border-radius: 10px;
  padding: 10px;
  border: 3px solid #f5f5f5;
}

.row-title {
  font-size: 18px;
  font-weight: 500;
}

.d-col-center{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 2;
}

.row {
  display: flex;
  /* justify-content: center; */
}

.checked {
  border-color: rgba(69, 170, 242, 1);
}

</style>
