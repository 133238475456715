<template>
  <div class="section">
    <span class="buttons">
      <button
        class="button is-warning is-outlined is-small"
        @click="$emit('close')"
      >
        <b-icon icon="chevron-left"/>
        <span>{{ $t('global.close') }}</span>
      </button>
    </span>
    <div class="card-content">
      <div class="has-text-centered">
        <h2 class="title">
          {{ selectedMailDeclaration.enduserName }}
        </h2>
        <i class="title">
          {{ $dayjs(selectedMailDeclaration.date).local().format('DD-MM-YYYY HH:mm') }}
        </i>
        <br>
        <i>
          (DD-MM-YYYY HH:mm)
        </i>
      </div>
      <div class="mb">
        <h2 class="title">
          {{ $t('consignee.pickup_management.identity') }}
        </h2>
        <div class="card">
          <span v-if="selectedMailDeclaration.identity" class="commentsSection">
            {{ selectedMailDeclaration.identity }}
          </span>
          <span v-else class="commentsSection">
            <i>{{ $t('global.no_data') }}</i>
          </span>
        </div>
      </div>
      <div class="mb">
        <h2 class="title">
          {{ $t('consignee.pickup_management.comments') }}
        </h2>
        <div class="card">
          <span v-if="selectedMailDeclaration.comments" class="commentsSection">
            {{ selectedMailDeclaration.comments }}
          </span>
          <span v-else class="commentsSection">
            <i>{{ $t('consignee.pickup_management.no_comments') }}</i>
          </span>
        </div>
      </div>
    </div>
    <div class="columns is-multiline">
      <div v-for="dc of selectedMailDeclaration.declarationQuantities" :key="dc.material" class="column is-one-third">
        <div class="card has-text-centered">
          <div class="card-header">
            <span class="card-header-title">
              {{ dc.material }}
            </span>
          </div>
          <div class="card-content">
            <h2 class="title" :class="dc.quantity > 0 ? 'has-text-primary' : null">
              {{ dc.quantity }}
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedMailDeclaration: {
      type: Object,
      required: true
    }
  },
  data () {
    return {

    }
  }
}
</script>
