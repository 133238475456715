import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import Auth from '@/services/v1/auth'

const state = {
  user: Auth.isLoggedIn() ? Auth.getUser() : null,
  token: Auth.getAccessToken() || '',
  introStep: (() => {
    try {
      const data = JSON.parse(window.localStorage.getItem('intro'))
      if (data && data.component && data.component.split('.')[1]) {
        data.component = `${data.component.split('.')[0]}.0`
        data.step = 0
      }
      return data || {}
    } catch (e) {
      return {}
    }
  })()
}

export default {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
