<template>
  <div class="modal-card" style="">
    <ValidationObserver ref="observer" v-slot="{ valid }" tag="form" @submit.prevent="submit">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ user ? $t('customer.scan_users.edit_user') : $t('customer.scan_users.create_user') }}
        </p>
      </header>
      <section class="modal-card-body">
        <b-field :label="$t('global.name')">
          <ValidationProvider v-slot="{ errors }">
            <b-input
              v-model="userToEdit.name"
              name="name"
              type="text"
              pattern="[a-zA-Z0-9 ]+"
              required
            />
          </ValidationProvider>
        </b-field>
        <b-field :label="$t('customer.scan_users.code')">
          <ValidationProvider v-slot="{ errors }">
            <b-input
              v-model="userToEdit.code"
              name="code"
              type="text"
              pattern="[a-zA-Z0-9 ]+"
              required
            />
          </ValidationProvider>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <button
          class="is-primary button is-fullwidth"
          :disabled="!valid"
          :class=" {'is-loading' : isSubmitting || loading}"
          type="submit"
        >
          {{ $t('global.submit') }}
        </button>
      </footer>
    </ValidationObserver>
  </div>
</template>

<script>

export default {
  props: {
    isSubmitting: {
      type: Boolean,
      required: true
    },
    user: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      userToEdit: {
        name: ''
      }
    }
  },
  computed: {
  },
  mounted: async function () {
    this.loading = true
    if (this.user) {
      this.userToEdit = {
        id: this.user.id,
        name: this.user.name,
        code: this.user.code,
        customerId: this.user.customerId
      }
    }
    this.loading = false
  },
  methods: {
    async submit () {
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        if (this.user) {
          await this.$emit('submitUpdate', this.userToEdit)
        } else {
          await this.$emit('submitCreate', this.userToEdit)
        }
      }
    }
  }
}
</script>
