<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns">
          <div class="column is-one-third is-offset-one-third">
            <div id="login-card" class="card">
              <div class="card-content">
                <img src="@/assets/gps_logo.png" alt="Gps logo" width="400px" class="text-center">
                <ValidationObserver v-slot="{ invalid }">
                  <form id="login" @submit.prevent="onLogin()">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      slim
                      rules="required"
                      name="Username"
                    >
                      <b-field :type="{ 'is-danger': errors[0], 'is-success': valid }">
                        <b-input
                          v-model="username"
                          rounded
                          name="username"
                          placeholder="Username"
                          type="text"
                          icon="user"
                          autofocus
                        />
                      </b-field>
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ errors, valid }" slim rules="required" name="Password">
                      <b-field :type="{ 'is-danger': errors[0], 'is-success': valid }">
                        <b-input
                          v-model="password"
                          rounded
                          name="password"
                          password-reveal
                          placeholder="Password"
                          type="password"
                          icon="lock"
                        />
                      </b-field>
                    </ValidationProvider>
                    <b-button
                      type="is-info"
                      rounded
                      :loading="isLoading"
                      :disabled="invalid"
                      expanded
                      native-type="submit"
                    >
                      Login
                    </b-button>
                    <div>
                      <router-link class="is-size-7" to="forget-password">
                        Forgot your password ?
                      </router-link>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return {
      username: '',
      password: '',
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    })
  },

  methods: {
    ...mapActions(['login']),
    onLogin: async function () {
      this.isLoading = true

      try {
        await this.login({
          username: this.username,
          password: this.password
        })

        if (process.env.NODE_ENV === 'production') {
          this.$gtag.set('userId', this.user.displayName)
        }

        this.$http.defaults.headers.common.User = this.user.displayName

        this.$router.push(this.$route.query.redirect || '/')
      } catch (err) {
        this.$buefy.notification.open({ type: 'is-danger', message: err.message, duration: 5000, hasIcon: true })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style>
#login-card {
  margin-top: -50px;
}
</style>
